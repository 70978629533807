import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import HomeCollateralSchema from "../Schema/HomeCollateralSchema";
import axios from "axios";
import { FaHome, FaEye, FaFileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import TextFormikInput from "../ReusableFormiks/TextFormikInput";
import { setTargetClient, setTargetLoan } from "../Common/redux/systemLookups";
import { Accordion, Alert, Badge, InputGroup } from "react-bootstrap";
import TextInputTw from "../ReusableFormiks/TextInputTw";
import { Link } from "react-router-dom";

function CollateralHomeComp({ successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const { targetLoan } = useSelector((store) => store.systemLookups);
  const dispatch = useDispatch();
  const [homeTypes, setHomeTypes] = useState([]);
  const [subcities, setsubcities] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [gpModal, setGpModal] = useState(false);
  const [gpid, setGpid] = useState(null);

  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState({});

  const [targetObj, setTargetObj] = useState({});

  // for editing
  const [hometype, sethometype] = useState("");
  const [locationtxt, setlocationtxt] = useState("");
  const [bldgno, setbldgno] = useState("");
  const [floorno, setfloorno] = useState("");
  const [subcity, setsubcity] = useState("");
  const [woreda, setworeda] = useState("");
  const [houseno, sethouseno] = useState("");
  const [uniquenum, setuniquenum] = useState("");
  const [amName, setamName] = useState("");
  const [homearea, sethomearea] = useState("");
  const [serialnum, setserialnum] = useState("");
  const [sunctionedAt, setsunctionedAt] = useState("");
  const [ddlHouseList, setDdlHouseList] = useState([]);

  useEffect(() => {
    getHomeTypes();
    getsubcities();
  }, []);

  const getHomeTypes = () => {
    axios
      .get(`${serverIP}/home_type/hometypes`)
      .then((res) => {
        console.log("Home Types");
        console.log(res.data);
        setHomeTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getsubcities = () => [
    axios
      .get(`${serverIP}/subcity/subcitys`)
      .then((res) => {
        setsubcities(res.data);
      })
      .catch((err) => {
        console.log(err);
      }),
  ];

  const saveCollateralHome = (values) => {
    console.log(values);
    axios
      .post(`${serverIP}/collateral_home/collateralhomes`, {
        hometype: values.homeType,
        locationtxt: values.locationtxt,
        bldgno: values.bldgno,
        floorno: values.floorno,
        subcity: values.subcity,
        woreda: values.woreda,
        houseno: values.houseno,
        uniquenum: values.uniquenum,

        amName: values.amName,
        homearea: values.homeArea,
        loan: targetLoan.id,
        sunctionedAt: values.sunctionedAt,
        serialnum: values.serialnum,
      })
      .then((res) => {
        successnotify();
        setShowAddModal(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
        errornotify();
      });
  };

  const editHome = () => {
    axios
      .patch(`${serverIP}/collateral_home/collateralhomes/${targetObj.id}`, {
        hometype: hometype,
        locationtxt: locationtxt,
        bldgno: bldgno,
        floorno: floorno,
        subcity: subcity,
        woreda: woreda,
        houseno: houseno,
        uniquenum: uniquenum,
        amName: amName,
        homearea: homearea,
        sunctionedAt: sunctionedAt,
        serialnum: serialnum,
      })
      .then((res) => {
        console.log(res.data);
        setShowEditModal(false);
        getLoan();
        successnotify();
      })
      .catch((err) => {
        errornotify();
      });
  };

  const deleteHome = () => {
    axios
      .delete(`${serverIP}/collateral_home/collateralhomes/${viewObj.id}`)
      .then((res) => {
        console.log(res.data);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeGP = () => {
    axios
      .patch(`${serverIP}/collateral_home/collateralhomes/${targetObj.id}`, {
        homegp: gpid,
      })
      .then((res) => {
        successnotify();
        setGpModal(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoan = async () => {
    try {
      const res = await axios.get(`${serverIP}/loan/loans/${targetLoan.id}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  function handleUpload(fileid) {
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append("file", file);
    fd.append("fileType", file.type);
    fd.append("fileUrl", file);
    fd.append("isUploaded", true);

    axios
      .patch(`${serverIP}/home_commonfiles/homefiles/${fileid}`, fd, {
        headers: {
          "Custom-Header": "value",
        },
      })
      .then((res) => {
        successnotify();
        getLoan();
      })
      .catch((err) => {
        errornotify();
      });
  }

  const handleDeleteFile = (fileid) => {
    axios
      .patch(`${serverIP}/home_commonfiles/homefiles/${fileid}`, {
        isUploaded: false,
      })
      .then((res) => {
        successnotify();
        getLoan();
      })
      .catch((err) => {
        errornotify();
        console.log(err);
      });
  };

  const getDdlHouse = (homeId) => {
    axios
      .get(`${serverIP}/ddlhouse/ddlhouses`)
      .then((res) => {
        const transformedArray = res?.data.map((item) => ({
          parent: homeId,
          user: data.id,
          amDesc: item.amName,
          checkListId: item.id,
          isMandatory: item.isMandatory,
          markValue: item.markValue,
        }));

        setDdlHouseList(transformedArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadData = async (homeId) => {
    try {
      const ddlCarChecklist = await axios.get(`${serverIP}/ddlhouse/ddlhouses`);
      const transformedArray = ddlCarChecklist?.data.map((item) => ({
        parent: homeId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue,
      }));

      // First POST request
      const insert_CheckLists = await axios.post(
        `${serverIP}/home_commonfiles/homefiles/bulkinsert`,
        transformedArray
      );

      getLoan();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  const reLoadData = (homeId) => {
    axios
      .delete(
        `${serverIP}/home_commonfiles/homefiles/deletebyparent/${homeId}`
      )
      .then((res) => {
        console.log(res.data);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modal Start  */}
      {/* Modal Show Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + viewObj.fileUrl}
            style={{ width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      {/* Modal Add Start  */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> አዲስ የቤት መያዣ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            homeType: "",
            locationtxt: "",
            bldgno: "",
            floorno: "",
            subcity: "",
            woreda: "",
            houseno: "",
            uniquenum: "",
            amName: "",
            homeArea: 0,
            sunctionedAt: "",
            serialnum: "",
          }}
          validationSchema={HomeCollateralSchema}
          onSubmit={saveCollateralHome}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <label>የቤት አይነት</label>
                  <Field
                    as="select"
                    name="homeType"
                    className="form-control"
                    style={{
                      border: errors.homeType ? "1px solid red" : "",
                    }}
                  >
                    <option value="0"> የቤት አይነት ይምረጡ </option>
                    {homeTypes.map((htype) => {
                      return (
                        <option key={htype.id} value={htype.id}>
                          {htype.amName}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="homeType"
                    component="div"
                  />
                  <div className="py-2">
                    <Field
                      as="select"
                      name="subcity"
                      className="form-control"
                      style={{
                        border: errors.subcity ? "1px solid red" : "",
                      }}
                    >
                      <option value="0"> ክ/ከተማ ይምረጡ </option>
                      {subcities.map((subc) => {
                        return (
                          <option key={subc.id} value={subc.id}>
                            {subc.amName}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="subcity"
                      component="div"
                    />
                  </div>

                  <TextFormikInput name="locationtxt" placeholder="የሚገኝበት ቦታ" />
                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="bldgno" placeholder="የህንጻ ቁ." />
                    <TextFormikInput name="floorno" placeholder="የወለል ቁ." />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="woreda" placeholder="ወረዳ" />
                    <TextFormikInput name="houseno" placeholder="የቤት ቁ." />
                  </div>
                  <TextFormikInput name="uniquenum" placeholder="የካርታ መ.ቁ." />

                  <ErrorMessage
                    style={{ color: "red" }}
                    name="homeType"
                    component="div"
                  />
                  <TextFormikInput name="amName" placeholder="ልዩ ስም" />
                  <TextFormikInput name="homeArea" placeholder="የቤቱ ስፋት" />
                  <TextFormikInput name="serialnum" placeholder="ሴሪ ቁጥር" />

                  <label> እግድ የተሰጠበት ቀን </label>
                  <Field
                    className="form-control"
                    placeholder="sunctionedAt"
                    type="date"
                    name="sunctionedAt"
                    style={{
                      border: errors.sunctionedAt ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="sunctionedAt"
                    component="div"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>የቤት መያዣ ያስተካክሉ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-2">
            <select
              onChange={(e) => sethometype(e.target.value)}
              className="form-control"
            >
              <option value="0"> የቤት አይነት ይምረጡ </option>
              {homeTypes.map((htype) => {
                return (
                  <option
                    selected={htype.id === targetObj?.hometype?.id}
                    key={htype.id}
                    value={htype.id}
                  >
                    {htype.amName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="py-2">
            <select
              onChange={(e) => setsubcity(e.target.value)}
              className="form-control"
            >
              <option value="0"> ቤቱ የሚገኝበት ክ/ከተማ </option>
              {subcities.map((subc) => {
                return (
                  <option
                    selected={subc.id === targetObj?.subcity?.id}
                    key={subc.id}
                    value={subc.id}
                  >
                    {subc.amName}
                  </option>
                );
              })}
            </select>
          </div>
          <TextInputTw
            placeholder="የሚገኝበት ቦታ"
            onChange={(e) => setlocationtxt(e.target.value)}
            value={locationtxt}
          />
          <div className="flex flex-row gap-2">
            <TextInputTw
              placeholder="የህንጻው ቁጥር"
              onChange={(e) => setbldgno(e.target.value)}
              value={bldgno}
            />
            <TextInputTw
              placeholder="የወለል ቁጥር"
              onChange={(e) => setfloorno(e.target.value)}
              value={floorno}
            />
          </div>
          <div className="flex flex-row gap-2">
            <TextInputTw
              placeholder="ወረዳ"
              onChange={(e) => setworeda(e.target.value)}
              value={woreda}
            />
            <TextInputTw
              placeholder="የቤት ቁጥር"
              onChange={(e) => sethouseno(e.target.value)}
              value={houseno}
            />
          </div>
          <TextInputTw
            placeholder="የካርታ መለያ ቁጥር"
            onChange={(e) => setuniquenum(e.target.value)}
            value={uniquenum}
          />
          <TextInputTw
            placeholder="ልዩ ስም"
            onChange={(e) => setamName(e.target.value)}
            value={amName}
          />
          <TextInputTw
            placeholder="የቤቱ ስፋት"
            onChange={(e) => sethomearea(e.target.value)}
            value={homearea}
          />

          <TextInputTw
            placeholder="ሴርያል ቁጥር"
            onChange={(e) => setserialnum(e.target.value)}
            value={serialnum}
          />

          <label className="w-full py-1">
            <div className="label">
              <span className="label-text"> እግድ የተሰጠበት ቀን </span>
            </div>
            <input
              type="date"
              value={sunctionedAt}
              onChange={(e) => setsunctionedAt(e.target.value)}
              className="input input-bordered w-full form-control"
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" onClick={() => editHome()}>
            አስተካከል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Change Guarantee Start */}
      <Modal show={gpModal} onHide={() => setGpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ዋስ ግለሰብ ቀይር </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className="form-select"
            onChange={(e) => {
              setGpid(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option value="null" selected>
              ተበዳሪ
            </option>
            {targetLoan.gp?.length > 0 &&
              targetLoan.gp.map((gp) => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.fullname}
                  </option>
                );
              })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" onClick={() => changeGP()}>
            Change
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Change Guarantee Start  */}

      {/* Modal End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: "bold", color: "GrayText" }}>
          የቤት መያዣ
        </div>
        {data.groups[0] === "ROLE_OFFICER" && (
          <Button
            onClick={() => setShowAddModal(true)}
            className="flex flex-row items-center gap-2"
            disabled={targetLoan.teamleaderchecking}
          >
            <FaHome /> ጨምር
          </Button>
        )}
      </Alert>

      <div className="row">
        <div className="col-sm-12" style={{ paddingTop: 5 }}>
          {Object.keys(targetLoan)?.length > 0 ? (
            <Accordion defaultActiveKey="1">
              {targetLoan.collateralhome.map((home) => {
                return (
                  <Accordion.Item eventKey={home.id}>
                    <Accordion.Header>
                      <div className="flex flex-row items-center gap-2">
                        <FaFileAlt />{" "}
                        <Badge>
                          {
                            home.homefiles.filter((x) => x.isUploaded === true)
                              ?.length
                          }
                          / {home.homefiles?.length}
                        </Badge>
                        {`${home.amName} - ስፋት ${home.homearea} ካሬ ${
                          home.hometype?.amName
                        }, በካሬ ${home.hometype?.pricepercaremeter.toLocaleString()} ብር ግምት`}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                        <span>
                          {home.homegp !== null
                            ? home.homegp?.fullname
                            : "የተበዳሪ"}
                        </span>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            onClick={() => {
                              setShowEditModal(true);
                              setTargetObj(home);
                              sethometype(home.hometype?.id);
                              setlocationtxt(home.locationtxt);
                              setbldgno(home.bldgno);
                              setfloorno(home.floorno);
                              setsubcity(home.subcity?.id);
                              setworeda(home.woreda);
                              sethouseno(home.houseno);
                              setuniquenum(home.uniquenum);
                              setamName(home.amName);
                              sethomearea(home.homearea);
                              setsunctionedAt(home.sunctionedAt);
                              setserialnum(home.serialnum);
                            }}
                            className="btn btn-sm btn-warning"
                          >
                            አስተካክል
                          </button>
                          <button
                            onClick={() => {
                              setGpModal(true);
                              setTargetObj(home);
                            }}
                            type="button"
                            className="btn btn-sm btn-primary"
                          >
                            ተያዥ ቀይር
                          </button>
                          <button className="btn btn-sm btn-danger">አጥፋ</button>
                        </div>
                      </div>

                      {home.homefiles?.length > 0 ? (
                        <>
                          <Alert
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2 py-2 items-center"
                          >
                            <div>በቤት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር</div>
                            <Button
                              className="btn btn-warning btn-sm"
                              onClick={() => {
                                reLoadData(home.id);
                              }}
                            >
                              የፋይል ዝርዝር አጥፋ
                            </Button>
                          </Alert>

                          <Table striped bordered hover style={{ margin: 1 }}>
                            <thead>
                              <tr>
                                <th> የፋይል ስም </th>
                                <th> እይ </th>
                                <th> አስፈላጊነት </th>
                                <th> ድርጊት </th>
                                <th> አጥፋ </th>
                              </tr>
                            </thead>
                            <tbody>
                              {home.homefiles.map((upload) => {
                                return (
                                  <tr
                                    className={
                                      upload.isUploaded ? `` : `table-warning`
                                    }
                                  >
                                    <td>{upload.amDesc}</td>
                                    <td>
                                      {upload.isUploaded ? (
                                        <>
                                          {upload.fileType ===
                                          "application/pdf" ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + upload.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setViewObj(upload);
                                                setShow(true);
                                                console.log("set show clicked");
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td>
                                      {upload.isMandatory ? "ግዴታ" : "አማራጭ"}
                                    </td>
                                    <td style={{ width: "30%", padding: 0 }}>
                                      {data.groups[0] === "ROLE_OFFICER" ? (
                                        <>
                                          <InputGroup style={{ paddingTop: 5 }}>
                                            <input
                                              onChange={(e) => {
                                                setFile(e.target.files[0]);
                                                console.log(e.target.files[0]);
                                              }}
                                              type="file"
                                              className="form-control"
                                            />
                                            <Button
                                              variant="outline-primary"
                                              onClick={() =>
                                                handleUpload(upload.id)
                                              }
                                            >
                                              {!upload.isUploaded ? (
                                                <> ጫን </>
                                              ) : (
                                                <>አዘምን</>
                                              )}
                                            </Button>
                                          </InputGroup>
                                        </>
                                      ) : (
                                        <>{upload.isUploaded ? "ተሰቅሏል" : " "}</>
                                      )}
                                    </td>
                                    <td style={{ width: "10%", padding: 0 }}>
                                      {upload.isUploaded && (
                                        <Button
                                          variant="outline-danger"
                                          onClick={() =>
                                            handleDeleteFile(upload.id)
                                          }
                                        >
                                          አጥፋ
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <Alert className="flex justify-between p-2">
                          <div> በቤት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር </div>
                          <Button
                            className="btn-sm"
                            onClick={() => loadData(home.id)}
                          >
                            ሳብ
                          </Button>
                        </Alert>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <>Bring Files</>
          )}
        </div>
      </div>
    </div>
  );
}

export default CollateralHomeComp;
