import React, { useState, useEffect }  from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import GuaranteePerson from "../Schema/GuaranteePerson";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Modal, Alert, Table, Accordion, InputGroup } from "react-bootstrap";
import { FaEye, FaFileAlt, FaRing } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import TextFormikInput from "../ReusableFormiks/TextFormikInput";
import { setTargetLoan } from "../Common/redux/systemLookups";

function GuaranteePersonComp({ targetLoan, successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const dispatch = useDispatch();
  const { loanId } = useParams();
  const [gps, setGps] = useState([]);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDeleteGp, setModalDeleteGp] = useState(false);

  const [targetObj, setTargetObj] = useState({});

  const [pfullname, setpfullname] = useState("");
  const [subcities, setsubcities] = useState([]);

  // for updates / edit
  const [fullname, setfullname] = useState("");
  const [subcity, setsubcity] = useState("");
  const [woreda, setworeda] = useState("");
  const [housenum, sethousenum] = useState("");
  const [idnum, setidnum] = useState("");
  const [phonenum, setphonenum] = useState("");
  const [isMarried, setisMarried] = useState(false);
  const [isCollateralgp, setIsCollateralgp] = useState(false);

  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getsubcities();
  }, []);

  const getsubcities = () => [
    axios
      .get(`${serverIP}/subcity/subcitys`)
      .then((res) => {
        setsubcities(res.data);
      })
      .catch((err) => {
        console.log(err);
      }),
  ];

  const saveGuaranteePerson = (values) => {
    console.log(values);
    axios
      .post(`${serverIP}/loan_guarantee_person/loan-guarantee-persons`, {
        fullname: values.fullname,
        subcity: values.subcity,
        woreda: values.woreda,
        housenum: values.housenum,
        idnum: values.idnum,
        phonenum: values.phonenum,
        isMarried: values.isMarried,
        isCollateralgp: values.isCollateralgp,
        loan: loanId,
      })
      .then((res) => {
        successnotify();
        getLoan();
        setModalAdd(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateGuaranteePerson = () => {
    console.log(targetObj);

    axios
      .patch(
        `${serverIP}/loan_guarantee_person/loan-guarantee-persons/${targetObj.id}`,
        {
          fullname: fullname,
          isMarried: isMarried,
          subcity: subcity,
          woreda: woreda,
          housenum: housenum,
          idnum: idnum,
          phonenum: phonenum,
          isCollateralgp: isCollateralgp,
        }
      )
      .then((res) => {
        console.log(res.data);
        getLoan();
        setModalEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteGuaranteePerson = () => {
    console.log(targetObj);
    axios
      .delete(
        `${serverIP}/loan_guarantee_person/loan-guarantee-persons/${targetObj.id}`
      )
      .then((res) => {
        console.log(res.data);
        setModalDeleteGp(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoan = () => {
    axios
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then((res) => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reLoadData = async () => {
    axios
      .delete(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/deletebyparent/${targetObj.id}`
      )
      .then((res) => {
        console.log("successfull");
        console.log(res.data);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDataSingleGp = async (gpId) => {
    setIsLoading(true);
    try {
      const ddlSinglegp = await axios.get(
        `${serverIP}/ddlsinglegp/ddlsinglegps`
      );

      const transformedArray = ddlSinglegp?.data.map((item) => ({
        parent: gpId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue,
      }));

      const insert_CheckLists = await axios.post(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/bulkinsert`,
        transformedArray
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
      getLoan();
    }
  };

  const loadDataMarriedGp = async (gpId) => {
    setIsLoading(true);
    try {
      const ddlMarriedgp = await axios.get(
        `${serverIP}/ddlmarriedgp/ddlmarriedgps`
      );

      const transformedArray = ddlMarriedgp?.data.map((item) => ({
        parent: gpId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue,
      }));

      const insert_CheckLists = await axios.post(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/bulkinsert`,
        transformedArray
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
      getLoan();
    }
  };

  function handleUpload(fileid) {
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append("file", file);
    fd.append("fileType", file.type);
    fd.append("fileUrl", file);
    fd.append("isUploaded", true);

    axios
      .patch(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/${fileid}`,
        fd,
        {
          headers: {
            "Custom-Header": "value",
          },
        }
      )
      .then((res) => {
        successnotify();
        getLoan();
      })
      .catch((err) => {
        errornotify();
      });
  }

  const handleDeleteFile = (fileid) => {
    axios
      .patch(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/${fileid}`,
        {
          isUploaded: false,
        }
      )
      .then((res) => {
        successnotify();
        getLoan();
      })
      .catch((err) => {
        errornotify();
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}

      {/* Modal Show Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + viewObj.fileUrl}
            style={{ width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title> የዋስ ገለሰብ መረጃ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            fullname: "",
            subcity: "",
            woreda: "",
            housenum: "",
            idnum: "",
            phonenum: "",
            isMarried: false,
            isCollateralgp: false,
          }}
          validationSchema={GuaranteePerson}
          onSubmit={saveGuaranteePerson}
        >
          {({ errors }) => (
            <Form>
              <Modal.Body>
                <TextFormikInput name="fullname" placeholder="ሙሉ ስም" />
                <div>
                  <label> ክ/ከተማ ይምረጡ </label>
                  <Field
                    as="select"
                    name="subcity"
                    className="form-control"
                    style={{
                      border: errors.subcity ? "1px solid red" : "",
                    }}
                  >
                    <option value="0"> ክ/ከተማ ይምረጡ </option>
                    {subcities.map((subc) => {
                      return (
                        <option key={subc.id} value={subc.id}>
                          {subc.amName}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="subcity"
                    component="div"
                  />
                </div>
                <TextFormikInput name="woreda" placeholder="ወረዳ" />
                <TextFormikInput name="housenum" placeholder="የቤት ቁ." />
                <TextFormikInput name="idnum" placeholder="የመታወቅያ ቁ." />
                <TextFormikInput name="phonenum" placeholder="ስልክ ቁ." />
                <div>
                  <Field
                    name="isCollateralgp"
                    className="mr-2 leading-tight mt-4"
                    type="checkbox"
                  />
                  &nbsp; የንብረት መያዣ
                </div>
              </Modal.Body>
              <Modal.Footer
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <Field
                    name="isMarried"
                    className="mr-2 leading-tight"
                    type="checkbox"
                  />
                  &nbsp; Is Married
                </div>
                <Button type="submit" variant="primary btn-sm">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}> ማስተካክያ አድርግ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="form-label">ሙሉ ስም</label>
            <input
              type="text"
              className="form-control"
              value={fullname}
              onChange={(e) => setfullname(e.target.value)}
            />
          </div>
          <div>
            <label className="form-label">ክ/ከተማ ይምረጡ</label>
            <select
              onChange={(e) => setsubcity(e.target.value)}
              className="form-control"
            >
              {subcities.map((subc) => {
                return (
                  <option
                    selected={subc.id == targetObj?.subcity}
                    key={subc.id}
                    value={subc.id}
                  >
                    {subc.amName}
                  </option>
                );
              })}
            </select>
          </div>

          <div>
            <label className="form-label">ወረዳ</label>
            <input
              type="text"
              className="form-control"
              value={woreda}
              onChange={(e) => setworeda(e.target.value)}
            />
          </div>
          <div>
            <label className="form-label">የቤት ቁ.</label>
            <input
              type="text"
              className="form-control"
              value={housenum}
              onChange={(e) => sethousenum(e.target.value)}
            />
          </div>
          <div>
            <label className="form-label">የመታወቅያ ቁ.</label>
            <input
              type="text"
              className="form-control"
              value={idnum}
              onChange={(e) => setidnum(e.target.value)}
            />
          </div>

          <div>
            <label className="form-label">ስልክ ቁ.</label>
            <input
              type="text"
              className="form-control"
              value={phonenum}
              onChange={(e) => setphonenum(e.target.value)}
            />
          </div>

          <div className="pt-2">
            <input
              type="checkbox"
              value={isCollateralgp}
              onChange={(e) => setIsCollateralgp(e.target.value)}
            />
            &nbsp; &nbsp;
            <label className="form-label">የንብረት መያዣ</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-around">
          <div>
            <input
              type="checkbox"
              checked={isMarried}
              onChange={(e) => setisMarried(e.target.value)}
            />
            &nbsp;
            <label className="form-label">የትዳር ሁኔታ</label>
          </div>

          <Button variant="warning btn-sm" onClick={updateGuaranteePerson}>
            አስተካከል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Guarantee Person Start  */}
      <Modal show={modalDeleteGp} onHide={() => setModalDeleteGp(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete
          <strong> {targetObj.fullname}? </strong>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteGuaranteePerson} variant="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete Guarantee Person End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDeleteFile} variant="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modals End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: "bold", color: "GrayText" }}>
          የተያዥ ሰው መረጃ
        </div>

        {data.groups[0] === "ROLE_OFFICER" && (
          <Button
            variant="primary"
            className="btn-sm"
            onClick={() => setModalAdd(true)}
            disabled={targetLoan.teamleaderchecking}
          >
            ጨምር
          </Button>
        )}
      </Alert>

      <div className="row">
        <div className="col-sm-12">
          {Object.keys(targetLoan)?.length > 0 ? (
            <Accordion defaultActiveKey="1">
              {targetLoan.gp.map((guaranteep) => {
                return (
                  <Accordion.Item eventKey={guaranteep.id}>
                    <Accordion.Header className="bg-emerald-500 ">
                      <div className="font-bold">{guaranteep.fullname}</div>
                      <div className="ml-7">
                        {!guaranteep.isMarried ? (
                          "ያላገባ"
                        ) : (
                          <FaRing color="orange" />
                        )}
                      </div>

                      {guaranteep.isCollateralgp && (
                        <div className="ml-7">
                          {guaranteep.cargp?.length +
                            guaranteep.homegp?.length +
                            guaranteep.stockgp?.length}
                          -Files
                        </div>
                      )}
                      <div className="ml-7">
                        {guaranteep.isCollateralgp
                          ? "   የንብረት መያዣ ዋስትና"
                          : "   የመታወቅያ ዋስትና"}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                        <div> የመታወቅያ / የንብረት ተያዥ </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            onClick={() => {
                              setModalEdit(true);
                              setTargetObj(guaranteep);
                              setfullname(guaranteep.fullname);

                              setsubcity(guaranteep.subcity);
                              setworeda(guaranteep.woreda);

                              sethousenum(guaranteep.housenum);
                              setidnum(guaranteep.idnum);
                              setphonenum(guaranteep.phonenum);
                              setisMarried(guaranteep.isMarried);
                              setIsCollateralgp(guaranteep.isCollateralgp);
                            }}
                            className="btn btn-sm btn-warning"
                          >
                            አስተካክል
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              setModalDeleteGp(true);
                              setTargetObj(guaranteep);
                            }}
                          >
                            አጥፋ
                          </button>
                        </div>
                      </div>

                      {guaranteep.gp_files?.length === 0 ? (
                        <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                          <div> የተያዥ ሰው መረጃዎች ሳብ </div>

                          {guaranteep.isMarried ? (
                            <Button
                              className="flex gap-2 btn-sm items-center"
                              onClick={() => loadDataMarriedGp(guaranteep.id)}
                            >
                              <FaRing color="orange" /> ሳብ
                            </Button>
                          ) : (
                            <Button
                              className="btn-sm"
                              onClick={() => loadDataSingleGp(guaranteep.id)}
                            >
                              ሳብ
                            </Button>
                          )}
                        </Alert>
                      ) : (
                        <>
                          {guaranteep.gp_files?.length > 0 ? (
                            <>
                              <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                                <div> የተያዥ ሰው መረጃዎች ሳብ</div>
                                <Button
                                  className="btn btn-warning btn-sm"
                                  onClick={() => {
                                    setTargetObj(guaranteep);
                                    reLoadData();
                                  }}
                                >
                                  የፋይል ዝርዝር አጥፋ
                                </Button>
                              </Alert>
                              <Table
                                striped
                                bordered
                                hover
                                style={{ margin: 1 }}
                              >
                                <thead>
                                  <tr>
                                    <th> የፋይል ስም </th>
                                    <th> እይ </th>
                                    <th> አስፈላጊነት </th>
                                    <th> ድርጊት </th>
                                    <th> አጥፋ </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {guaranteep.gp_files.map((file) => {
                                    return (
                                      <tr
                                        className={
                                          file.isUploaded ? `` : `table-warning`
                                        }
                                      >
                                        <td>{file.amDesc}</td>
                                        <td>
                                          {file.isUploaded ? (
                                            <>
                                              {file.fileType ===
                                              "application/pdf" ? (
                                                <a
                                                  target="_blank"
                                                  href={serverIP + file.fileUrl}
                                                  rel="noopener noreferrer"
                                                >
                                                  <FaFileAlt />
                                                </a>
                                              ) : (
                                                <FaEye
                                                  onClick={() => {
                                                    setViewObj(file);
                                                    setShow(true);
                                                    console.log(
                                                      "set show clicked"
                                                    );
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td>
                                          {file.isMandatory ? "ግዴታ" : "አማራጭ"}
                                        </td>
                                        <td
                                          style={{ width: "30%", padding: 0 }}
                                        >
                                          {data.groups[0] === "ROLE_OFFICER" ? (
                                            <>
                                              <InputGroup
                                                style={{ paddingTop: 5 }}
                                              >
                                                <input
                                                  onChange={(e) => {
                                                    setFile(e.target.files[0]);
                                                    console.log(
                                                      e.target.files[0]
                                                    );
                                                  }}
                                                  type="file"
                                                  className="form-control"
                                                />
                                                <Button
                                                  variant="outline-primary"
                                                  onClick={() =>
                                                    handleUpload(file.id)
                                                  }
                                                >
                                                  {!file.isUploaded ? (
                                                    <> ጫን </>
                                                  ) : (
                                                    <>አዘምን</>
                                                  )}
                                                </Button>
                                              </InputGroup>
                                            </>
                                          ) : (
                                            <>
                                              {file.isUploaded ? "ተሰቅሏል" : " "}
                                            </>
                                          )}
                                        </td>
                                        <td
                                          style={{ width: "10%", padding: 0 }}
                                        >
                                          {file.isUploaded && (
                                            <Button
                                              variant="outline-danger"
                                              onClick={() =>
                                                handleDeleteFile(file.id)
                                              }
                                            >
                                              አጥፋ
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default GuaranteePersonComp;
