import { data } from "autoprefixer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, ListGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import WithdrawForm from "./Reusables/WithdrawForm";

function WithdrawRowDetail() {
  const { withdrawId } = useParams();
  const { data } = useSelector((store) => store.customer);
  const { serverIP } = useSelector((store) => store.allsettings);

  const [theWithdraw, settheWithdraw] = useState({});
  const [show, setShow] = useState(false);
  const [withdrawAmount, setwithdrawAmount] = useState(0);
  const [withdrawDate, setwithdrawDate] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    getWithdrawing(withdrawId);
  }, []);

  const getWithdrawing = () => {
    console.log(withdrawId);
    axios
      .get(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`)
      .then((res) => {
        settheWithdraw(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveWithdrawAmount = () => {
    if (withdrawAmount !== 0) {
      axios
        .patch(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`, {
          withdraw_amount: withdrawAmount,
          plannedby: data.id,
          appointmentDate: withdrawDate,
        })
        .then((res) => {
          getWithdrawing(withdrawId);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("ሁሉንም መረጃ በመስጠት ፣ እንደገና ይሞክሩ");
      // toast.error("ሲስተሙ ማስቀመጥ አልቻለም ፣ ስህተቱን አርመው እንደገና ይሞክሩ")
    }
  };

  const sendToAudit = () => {
    axios
      .patch(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`, {
        toEvaluator: true,
        plannedby: data.id,
      })
      .then((res) => {
        console.log(res.data);
        getWithdrawing(withdrawId);
        setShow(false);
      })
      .catch((err) => {
        toast.error("error");
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  return (
    <div className="container">
      <ToastContainer />

      {/* Modal Start  */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ወደ ኦዲት ክፍል ሃላፊ ላክ </Modal.Title>
        </Modal.Header>
        <Modal.Body>ወደ ኦዲት ክፍል ሃላፊ መላክ ይፈልጋሉ? </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendToAudit}>
            ላክ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal End  */}

      <div className="row">
        {/* {JSON.stringify(theWithdraw)} */}
        <div className="col-sm-8">
          <WithdrawForm theWithdraw={theWithdraw} />
        </div>
        <div className="col-sm-4">
          <div class="flex flex-col gap-2">
            <input
              type="number"
              class="form-control"
              onChange={(e) => setwithdrawAmount(e.target.value)}
              placeholder="ወጪ መጠን "
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <input
              type="date"
              class="form-control"
              onChange={(e) => setwithdrawDate(e.target.value)}
              placeholder="ወጪ ቀን  "
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <span
              class="btn btn-primary"
              id="basic-addon2"
              onClick={() => saveWithdrawAmount()}
            >
              Save
            </span>
          </div>

          <Card className="mt-4">
            <Card.Header>Withdrawal Request Detail</Card.Header>
            <Card.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item>
                  የተፈቀደው የብር መጠን፡
                  <b>{theWithdraw.withdraw_amount?.toLocaleString() + " ብር"}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የቀረው ቀን፡
                  {/* <b>
                    {theWithdraw.date_difference
                      ? theWithdraw.date_difference + " ቀናቶች"
                      : ""}
                  </b> */}
                  <b>
                    {getDateDifferenceInDays(theWithdraw.appointmentDate) +
                      "  ቀን ቀረው"}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የስራክፍሉ ኦፊሰር :
                  <b>
                    {theWithdraw?.plannedby
                      ? theWithdraw?.plannedby?.first_name +
                        " " +
                        theWithdraw?.plannedby?.last_name
                      : ""}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የአባላት አስተዳደር ሀላፊ :
                  <b>
                    {theWithdraw?.evaluatedby !== null &&
                      theWithdraw?.evaluatedby?.first_name +
                        " " +
                        theWithdraw?.evaluatedby?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የኦዲት ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.auditorid !== null &&
                      theWithdraw?.auditorid?.first_name +
                        " " +
                        theWithdraw?.auditorid?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የፋይናንስ ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.financePerson !== null &&
                      theWithdraw?.financePerson?.first_name +
                        " " +
                        theWithdraw?.financePerson?.last_name}
                  </b>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          <div className="pt-2">
            {theWithdraw.toEvaluator ? (
              <Alert>ወደ አባላት አስተዳደር ሀላፊ ተልኳል </Alert>
            ) : (
              <Button onClick={() => setShow(true)}>
                ወደ አባላት አስተዳደር ሀላፊ ላክ
              </Button>
            )}
          </div>
          <div>
            <Link
              to={`/rptwithdraw/${withdrawId}`}
              className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
            >
              ውል መረጃ አሳይ
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawRowDetail;
