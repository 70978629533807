import { data } from "autoprefixer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, ListGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function ManagerChecking() {
  const { withdrawId } = useParams();
  const { data } = useSelector((store) => store.customer);
  const { serverIP } = useSelector((store) => store.allsettings);

  const [theWithdraw, settheWithdraw] = useState({});
  const [show, setShow] = useState(false);
  const [withdrawAmount, setwithdrawAmount] = useState(0);

  useEffect(() => {
    getWithdrawing(withdrawId);
  }, []);

  const getWithdrawing = (withdrawId) => {
    console.log(withdrawId);
    axios
      .get(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`)
      .then((res) => {
        settheWithdraw(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
  const sendToFinance = () => {
    axios
      .patch(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`, {
        toFinance: true,
        financePerson: data.id,
      })
      .then((res) => {
        console.log(res.data);
        getWithdrawing(withdrawId);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }


  return (
    <div className="container">
      {/* Modal Start  */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ወደ ኦዲት ክፍል ሃላፊ ላክ </Modal.Title>
        </Modal.Header>
        <Modal.Body>ወደ ኦዲት ክፍል ሃላፊ መላክ ይፈልጋሉ? </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendToFinance}>
            ላክ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal End  */}

      <div className="row">
        {/* {JSON.stringify(theWithdraw)} */}
        <div className="col-sm-8">
          <div className="text-center underline text-4xl">
            የመደበኛ አባላት ወጪ መጠየቅያ ፎርም
          </div>
          <div className="mt-3">ይህ ፎርም በአሚጎስ ሰራተኛ የሚሞላ የወጪ ማዘዣ ፎርም ነው፡፡</div>

          <div className="m-5">
            <div className="flex justify-between">
              <div className="flex">
                የአባሉ ሙሉ ስም
                <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
                  {theWithdraw?.parent?.amDisplayName}
                </div>
              </div>
              <div className="flex">
                ኮድ
                <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
                  {theWithdraw?.parent?.entityExternalId}
                </div>
              </div>
            </div>
            <div className="flex justify-between pt-1">
              <div className="flex">
                ስልክ ቁጥር
                <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
                  {theWithdraw?.parent?.mobileNo}
                </div>
              </div>
              <div className="flex">
                የማህበሩ ስም
                <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
                  {theWithdraw?.parent?.groupName === null
                    ? "Personal "
                    : theWithdraw?.parent?.groupName}
                </div>
              </div>
            </div>
          </div>

          <ul className="list-disc list-inside text-gray-700">
            <li className="text-lg font-medium">ወጪ የተጠየቀበት ቀን፡ 1</li>
            <li className="text-lg font-medium">የአሚጎስ አባል የሆኑበት ቀን፡ 2</li>
            <li className="text-lg font-medium">የመጨረሻ ቁጠባ ገቢ የተደረገበት ቀን፡ 3</li>
          </ul>
          <div>
            <div className="px-6 py-2">
              <ol className="list-none list-inside">
                <li className="text-lg font-medium">
                  1. በዛሬው ቀን ያላቸው የአክስዮን መጠን/ Total Share፡
                  {theWithdraw.amount_share?.toLocaleString()}
                </li>
                <li className="text-lg font-medium">
                  2. በዛሬው ቀን ያላቸው የብድር ቁጠባ መጠን/ Loan Saving፡
                  {theWithdraw.amount_loansaving?.toLocaleString()}
                </li>
                <li className="text-lg font-medium">
                  3. በዛሬው ቀን ያላቸው መደበኛ ቁጠባ መጠን/ Compulsary፡
                  {theWithdraw.amount_compulsary?.toLocaleString()}
                </li>
                <li className="text-lg font-medium">
                  አጠቃላይ ያላቸው ተቀማጭ አክስዮንም ጭምሮ፡
                  {(
                    theWithdraw.amount_share +
                    theWithdraw.amount_loansaving +
                    theWithdraw.amount_compulsary
                  ).toLocaleString()}
                </li>
                <li className="text-lg font-medium"></li>
              </ol>
            </div>

            <div className="py-2 gap-1">
              ስለሆነም ከላይ ስማቸው የተጠቀሱ የድርጅታችን አባል ካላቸው አጠቃላይ ተቀማጭ የ
              <span className="underline">
                {theWithdraw.withdraw_amount?.toLocaleString()}
              </span>
              ብር ወጪ ማድረግ የሚችሉ ስለሆነ ወጪ እንዲደረግላቸው እጠይቃለው፡፡
            </div>

            <div className="py-2 text-xl">
              እኔ ስሜ የተጠቀሰው የማህበሩ አባል ስሆን በድርጅቱ ብድር ለመውሰድ ያስቀመጥኩት ተቀማጭ ብድር የመውሰድ
              ሃሳቤን ስለቀየርኩኝ ከላይ የተጠቀሰው ቁጠባዬ እንዲወጣልኝ እጠይቃለው፡፡
            </div>
          </div>
        </div>
        <div className="col-sm-4">
         
       
          <Card className="mt-4">
            <Card.Header>Withdrawal Request Detail</Card.Header>
            <Card.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item>
                  የተፈቀደው የብር መጠን፡
                  <b>{theWithdraw.withdraw_amount?.toLocaleString() + " ብር"}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የቀረው ቀን፡
                  {/* <b>
                    {theWithdraw.date_difference
                      ? theWithdraw.date_difference + " ቀናቶች"
                      : ""}
                  </b> */}
                  <b>
                    {getDateDifferenceInDays(theWithdraw.appointmentDate) + "  ቀን ቀረው"}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የስራክፍሉ ኦፊሰር :
                  <b>
                    {theWithdraw?.plannedby
                      ? theWithdraw?.plannedby?.first_name +
                        " " +
                        theWithdraw?.plannedby?.last_name
                      : ""}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የአባላት አስተዳደር ሀላፊ :
                  <b>
                    {theWithdraw?.evaluatedby !== null &&
                      theWithdraw?.evaluatedby?.first_name +
                        " " +
                        theWithdraw?.evaluatedby?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የኦዲት ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.auditorid !== null &&
                      theWithdraw?.auditorid?.first_name +
                        " " +
                        theWithdraw?.auditorid?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የፋይናንስ ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.financePerson !== null &&
                      theWithdraw?.financePerson?.first_name +
                        " " +
                        theWithdraw?.financePerson?.last_name}
                  </b>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          {/* <div className="pt-2">
            {theWithdraw.toFinance ? (
              <Alert>ወደ ፋይናንስ ክፍል ሃላፊ ተልኳል </Alert>
            ) : (
              <Button onClick={() => setShow(true)}> ወደ ፋይናንስ ክፍል ሃላፊ ላክ </Button>
            )}
          </div> */}

          <div className="pt-2">
            {theWithdraw.toAuditor ? (
              <Alert>ወደ ኦዲት ክፍል ሃላፊ ተልኳል </Alert>
            ) : (
              <Button onClick={() => setShow(true)}> ወደ ኦዲት ክፍል ሃላፊ ላክ </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


export default ManagerChecking