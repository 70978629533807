import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import ebrima from "./font/ebrima.ttf";
import ebrimabold from "./font/ebrimabd.ttf"; // bold
import ReportStyle from "./Styles/ReportStyle";

import GuaranteeHeader from "./Reusables/GuaranteeHeader";

import GuaranteeStyles from "./Styles/ReportStyle";
import TextBoldUnderlined from "./Reusables/TextBoldUnderlined";
import NumberToWordDisplay from "./Reusables/NumberToWordDisplay";

// import Trcb from "./Reusables/Trcb";
import Trc from "./Reusables/Trc";
import Tr from "./Reusables/Tr";

import getEthiopicDate from "../Common/modules/GregorianToEthiopian";
import { numberToWords } from "../Common/modules/EnNumToWord";
import ArticleOne from "./Articles/ArticleOne";

const Styles = StyleSheet.create({
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: "center",
    fontFamily: "ebrimabold",
    fontWeight: "bold",
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: "ebrima",
    fontWeight: "bold",
  },
  text_normal: {
    fontSize: 9,
    marginBottom: 3,
    fontFamily: "ebrima",
  },

  rightView: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    fontFamily: "ebrima",
    justifyContent: "flex-end",
  },
});

Font.register({
  family: "ebrima",
  src: ebrima,
});

Font.register({
  family: "ebrimabold",
  src: ebrimabold,
});

function RptCarGuarantee({ loan, car }) {
  const curdate = new Date();
  const etcurrdate =
    getEthiopicDate(
      curdate.getDay(),
      +" " + curdate.getMonth() + 1,
      +" " + curdate.getFullYear()
    ) + " ዓ.ም";


    const toEthiopic = (date) => {
      const [year, month, day] = date.split("-");
      return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
    };
  

  return (
    <PDFViewer style={{ width: "100%", height: 700 }}>
      <Document title="የመኪና መያዣ">
        <Page style={GuaranteeStyles.body} wrap>
          <GuaranteeHeader type="የመኪና"  />
          {Object.keys(loan).length > 0 ? (
            <>
              <View style={{ paddingBottom: 5 }}></View>
              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ START */}
              <View>
                <Text style={Styles.heading_one}>የውል ሰጪ እና ውል ተቀባይ ኣድራሻ</Text>
              </View>
              <View>
                <View>
                  {car?.cargp === null ? (
                    <>
                      <Text style={Styles.text_normal}>
                        ከዚህ ቀጥሎ "አስያዥ" እየተባሉ የሚጠቀሱት
                        {
                          <TextBoldUnderlined
                            text={loan.customer.amDisplayName}
                          />
                        }
                      </Text>
                      <Text style={Styles.text_normal}>
                        ኣድራሻ ክ/ከተማ &nbsp;
                        {<TextBoldUnderlined text={loan.customer.amSubcity} />}
                        ወረዳ
                        {<TextBoldUnderlined text={loan.customer.woreda} />}
                        የቤት/ቁጥር
                        {<TextBoldUnderlined text={loan.customer.houseNum} />}
                        የመታወቅያ/ቁጥር {<TextBoldUnderlined text="የመታወቅያ/ቁጥር" />}
                        ስልክ ቁጥር
                        {<TextBoldUnderlined text={loan.customer.mobileNo} />}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={Styles.text_normal}>
                        ከዚህ ቀጥሎ "አስያዥ" እየተባሉ የሚጠቀሱት
                        {<TextBoldUnderlined text={car?.cargp.fullname} />}
                      </Text>
                      <Text style={Styles.text_normal}>
                        ኣድራሻ ክ/ከተማ
                        {<TextBoldUnderlined text={car?.cargp.subcity} />}
                        ወረዳ
                        {<TextBoldUnderlined text={car?.cargp.woreda} />}
                        የቤት/ቁጥር
                        {<TextBoldUnderlined text={car?.cargp.housenum} />}
                        የመታወቅያ/ቁጥር {<TextBoldUnderlined text="የመታወቅያ/ቁጥር" />}
                        ስልክ ቁጥር
                        {<TextBoldUnderlined text={car?.cargp.phonenum} />}
                      </Text>
                    </>
                  )}

                  <Text style={Styles.text_normal}>
                    ከዚህ ቀጥሎ "ተቋም" እየተባለ የሚጠራው አሚጎስ የቁጠባና ብድር ህብረት ስራ ማህበር(ህስማ)
                  </Text>
                  <Text style={Styles.text_normal}>
                    አድራሻ፦ ፖስታሳ.ቁ፦
                    <TextBoldUnderlined text="20732/1000፤አዲስ አበባ" />
                    ስልክ ቁጥር፦ <TextBoldUnderlined text="+251-111-2676-57" />
                  </Text>
                  <Text style={Styles.text_normal}>
                    ይህንን የመያዣ ውል ዛሬ {<TextBoldUnderlined text={etcurrdate} />}
                    ቀጥሎ በተዘረዘረው ዓይነት ተዋውለዋል፡፡
                  </Text>
                </View>
              </View>
              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ END */}


              <View>
                <Text style={Styles.heading_one}>አንቀጽ 1 ፡ የብድር መጠን ከነ ውሉ </Text>
              </View>
           
              <ArticleOne loan={loan} />
              
              {/* bottom all same start  */}

              <View>
                <Text style={Styles.heading_one}>አንቀጽ 2 ፡ የብድር ዋስትና መጠን </Text>
              </View>
              <View>
                <Text style={Styles.text_normal}>
                  አስያዥ እስከ
                  <TextBoldUnderlined
                    text={(
                      loan.r_totalInterestPayment + loan.r_approvedPrincipal
                    )?.toLocaleString()}
                  />
                  <NumberToWordDisplay
                    text={numberToWords(
                      loan.r_totalInterestPayment + loan.r_approvedPrincipal
                    )}
                  />
                  ለሚደርስ ተበዳሪ ላልከፈሉት ብድር ለወለድና ለማንኛውም ወጪና ኪሳራ ማስከፈያ ከዚህ ቀጥሎ ባለው
                  ሰንጠረዥ ውስጥ የተጠቀሱት ንብረቶች በመያዣነት ለተቋሙ ሰጥተዋል፡፡
                </Text>
              </View>

              <View>
                <Text style={{ ...Styles.heading_two }}>
                  2.2. በአስያዥ የቀረቡት ዋስትናዎች
                </Text>
              </View>
              <View style={{ backgroundColor: "orange" }}>
                <Text style={{ ...Styles.heading_two, textAlign: "center" }}>
                  አጠቃላይ ለብድሩ የተያዙ ዋስትናዎች
                </Text>
              </View>

              <View>
                <Text style={Styles.heading_two}>
                  2.2.1. የተበዳሪው አክስዮን ፣ በተቋሙ ውስጥ ያለ ቁጠባና ወለድ
                </Text>

                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Trc borders="t1r1b1l1" val="ስም ከነ አያት" width="28%" />
                  <Trc borders="t1r1b1l1" val="መደባኛ ቁጠባ" width="19%" />
                  <Trc borders="t1r1b1l1" val="የአክስዮን ቁጠባ" width="19%" />
                  <Trc borders="t1r1b1l1" val="የፍቃደኝነት ቁጠባ" width="15%" />
                  <Trc borders="t1r1b1l1" val="ድምር መጠን" width="19%" />
                </View>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Trc
                    borders="t1r1b1l1"
                    val={loan.customer.amDisplayName}
                    width="28%"
                  />
                  <Trc borders="t1r1b1l1" val={loan.totalSaving} width="19%" />
                  <Trc borders="t1r1b1l1" val={loan.totalShares} width="19%" />
                  <Trc borders="t1r1b1l1" val={loan.willSaving} width="15%" />
                  <Trc
                    borders="t1r1b1l1"
                    val={loan.totalSaving + loan.totalShares}
                    width="19%"
                  />
                </View>
              </View>
              <View>
                <Text style={Styles.heading_two}>2.2.2. ተጨማሪ ዋስትናዎች</Text>
              </View>

              {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Tr
                  style={ReportStyle.h5b}
                  thekey=" ለብድር ዋስትና የቀረቡ ዋስትናዎች  "
                  width="22%"
                  borders="t1r1b1l1"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey=" የዋስትና አቅራቢ ስም "
                  width="30%"
                  borders="t1r1b1l0"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey=" የብር መጠን "
                  width="13%"
                  borders="t1r1b1l0"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey=" ክ/ከተማ "
                  width="13%"
                  borders="t1r1b1l0"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey=" ወረዳ "
                  width="13%"
                  borders="t1r1b1l0"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey=" የመ/ቁጥር "
                  width="9%"
                  borders="t1r1b1l0"
                />
              </View>

              {/* car */}
              {loan.collateralcar?.length > 0 &&
                loan.collateralcar.map((car) => {
                  return (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Tr thekey=" መኪና " width="22%" borders="t0r1b1l1" />
                      <Tr
                        thekey={
                          " " + loan.customer.amDisplayName ||
                          car.cargp?.fullname
                        }
                        width="30%"
                        borders="t0r1b1l0"
                      />
                      <Tr
                        thekey={(
                          (car.insuranceValue +
                            car?.marketvalue[0]?.marketValue +
                            car?.garageReport[0]?.garageValue) /
                          3
                        ).toFixed(2)}
                        width="13%"
                        borders="t0r1b1l0"
                      />
                      <Tr
                        thekey={
                          " " + loan.customer.subcity || car.cargp?.subcity
                        }
                        width="13%"
                        borders="t0r1b1l0"
                      />
                      <Tr
                        thekey={" " + loan.customer.woreda || car.cargp?.woreda}
                        width="13%"
                        borders="t0r1b1l0"
                      />
                      <Tr
                        thekey={" " + loan.customer.idNum || car.cargp?.idnum}
                        width="9%"
                        borders="t0r1b1l0"
                      />
                    </View>
                  );
                })}

              {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}

              {loan.customer?.isMarried &&
                loan.customer?.spausedetail?.length > 0 && (
                  <>
                    <View>
                      <Text style={Styles.heading_two}>
                        2.2.3. የዋስ ባለቤት ፈቃድ በተመለከተ
                      </Text>
                    </View>
                    <View>
                      <Text style={Styles.text_normal}>
                        የተበዳሪ ባለቤት (spause) የሆኑት
                        {
                          <TextBoldUnderlined
                            text={loan.customer?.spausedetail[0]?.amFullName}
                          />
                        }
                        &nbsp; ስልክ ቁጥር
                        {
                          <TextBoldUnderlined
                            text={loan.customer?.spausedetail[0]?.mobileNo}
                          />
                        }
                      </Text>
                      <Text style={Styles.text_normal}>
                        ኣድራሻ ክ/ከተማ
                        {
                          <TextBoldUnderlined
                            text={loan.customer?.spausedetail[0]?.amSubcity}
                          />
                        }
                        &nbsp; ወረዳ
                        {
                          <TextBoldUnderlined
                            text={loan.customer?.spausedetail[0]?.woreda}
                          />
                        }
                        &nbsp; የቤት/ቁጥር
                        {
                          <TextBoldUnderlined
                            text={loan.customer?.spausedetail[0]?.houseNum}
                          />
                        }
                        &nbsp; የመታወቅያ/ቁጥር {<TextBoldUnderlined text="የመቁ" />}
                      </Text>
                    </View>
                  </>
                )}

              <View style={{ paddingTop: 10 }}>
                <Text style={Styles.text_normal}>
                  በዚህ ውል የተጠቀሰዉን የተበዳሪ ግዴታ ሁሉ ወደውና ተስማምተው መቀበላቸውን በፊርማቸው
                  አረጋግጠዋል፡፡ ይህ ውል ከላይ በተጠቀሰው ቀንና ዓ.ም ስማቸው ከታች የተጠቀሰው ምስክሮች ባሉበት
                  ተፈርሟል፡፡
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text style={Styles.text_normal}> ________________ </Text>
                  <Text style={Styles.text_normal}> የተቋሙ ውል ሰጪ ስምና ፊርማ </Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> ________________ </Text>
                  <Text style={Styles.text_normal}> የዋስ ስምና ፊርማ </Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> ________________ </Text>
                  <Text style={Styles.text_normal}> የዋስ ባለቤት ስምና ፊርማ </Text>
                </View>
              </View>

              <View style={{ padding: 10 }}>
                <Text style={Styles.text_normal}>
                  አንድ ቅጂ ውስጃልው &nbsp;
                  <TextBoldUnderlined text={loan.customer.amDisplayName} />
                  &nbsp; ፊርማ፡ ___________________
                </Text>
              </View>

              <View>
                <Text style={Styles.text_normal}>ምስክሮች</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text style={Styles.text_normal}> ቃለወንጌል አድማሱ </Text>
                  <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> አላዛር ካሳ ሀይሌ </Text>
                  <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> ሰላማዊት ጻዲቅ </Text>
                  <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
                </View>
              </View>
            </>
          ) : (
            <></>
          )}
          <View
            style={{
              textAlign: "right",
              position: "absolute",
              bottom: 30,
              right: 35,
              fontSize: 10,
            }}
          >
            <Text style={Styles.text_normal}>
              ውሉ ያዘጋጀው፦
              <TextBoldUnderlined text="ቃልኪዳን ደረጄ" />
            </Text>
            <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptCarGuarantee;
