import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import GuaranteeStock from "../Schema/GuaranteeStock";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import {
  Modal,
  Alert,
  Table,
  Badge,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import { FaEye, FaFileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import TextFormikInput from "../ReusableFormiks/TextFormikInput";
import { setTargetLoan } from "../Common/redux/systemLookups";
import TextInputTw from "../ReusableFormiks/TextInputTw";
import { PulseLoader } from "react-spinners";

function CollateralStockComp({ targetLoan, successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { data } = useSelector((store) => store.customer);

  const { loanId } = useParams();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [gpersns, setGpersons] = useState([]);
  const [banks, setBanks] = useState([]);
  const [gpModal, setGpModal] = useState(false);
  const [gpid, setGpid] = useState(null);

  const [targetObj, setTargetObj] = useState({});

  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState([]);
  const [file, setFile] = useState(null);

  // for edit
  const [priceperstock, setpriceperstock] = useState("");
  const [stockqty, setstockqty] = useState("");
  const [letternum, setletternum] = useState("");
  const [letterdate, setletterdate] = useState("");
  const [bankId, setbankId] = useState("");

  const [serialnum, setserialnum] = useState("");

  useEffect(() => {
    getBanks();
  }, []);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: "#FFA500" }}>Loading ...</div>
        </div>
      </div>
    );
  }

  const getBanks = () => {
    axios
      .get(`${serverIP}/ddlbanks/ddlbanks`)
      .then((res) => {
        console.log(res.data);
        setBanks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveStock = (values) => {
    console.log(values);
    axios
      .post(`${serverIP}/collateral_stock/collateralstock`, {
        priceperstock: values.priceperstock,
        stockqty: values.stockqty,
        letternum: values.letternum,
        letterdate: values.letterdate,
        bankId: values.bankId,
        loan: loanId,
        serialnum: values.serialnum,
      })
      .then((res) => {
        successnotify();
        setModalAdd(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteGuaranteeStock = () => {
    axios
      .delete(`${serverIP}/collateral_stock/collateralstock/${targetObj.id}`)
      .then((res) => {
        getLoan();
        setModalDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeGP = () => {
    axios
      .patch(`${serverIP}/collateral_stock/collateralstock/${targetObj.id}`, {
        stockgp: gpid,
      })
      .then((res) => {
        successnotify();
        setGpModal(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoan = () => {
    axios
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then((res) => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleUpload(fileid) {
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append("file", file);
    fd.append("fileType", file.type);
    fd.append("fileUrl", file);
    fd.append("isUploaded", true);

    axios
      .patch(`${serverIP}/collateral_stock_files/stockfiles/${fileid}`, fd, {
        headers: {
          "Custom-Header": "value",
        },
      })
      .then((res) => {
        getLoan();
        successnotify();
      })
      .catch((err) => {
        errornotify();
      });
  }

  const handleDeleteFile = (fileid) => {
    axios
      .patch(`${serverIP}/collateral_stock_files/stockfiles/${fileid}`, {
        isUploaded: false,
      })
      .then((res) => {
        successnotify();
        getLoan();
      })
      .catch((err) => {
        errornotify();
      });
  };

  const reLoadData = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${serverIP}/collateral_stock_files/stockfiles/deletebyparent/${targetObj.id}`
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
      getLoan();
    }
  };

  const loadData = async (stockId) => {
    setIsLoading(true);
    try {
      const ddlShare = await axios.get(`${serverIP}/ddlshare/ddlshares`);

      const transformedArray = ddlShare?.data.map((item) => ({
        parent: stockId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue,
      }));

      const insert_CheckLists = await axios.post(
        `${serverIP}/collateral_stock_files/stockfiles/bulkinsert`,
        transformedArray
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
      getLoan();
    }
  };

  const editStock = () => {
    axios
      .patch(`${serverIP}/collateral_stock/collateralstock/${targetObj.id}`, {
        priceperstock: priceperstock,
        stockqty: stockqty,
        letternum: letternum,
        letterdate: letterdate,
        bankId: bankId,
        serialnum: serialnum,
      })
      .then((res) => {
        successnotify();
        setModalEdit(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + viewObj.fileUrl}
            style={{ width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>
      {/* change guarantee person modal Start  */}
      <Modal show={gpModal} onHide={() => setGpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ዋስ ግለሰብ ቀይር</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className="form-select"
            onChange={(e) => {
              setGpid(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option value="null" selected>
              ተበዳሪ
            </option>
            {gpersns?.length > 0 &&
              gpersns.map((gp) => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.fullname}
                  </option>
                );
              })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" onClick={() => changeGP()}>
            ቀይር
          </Button>
        </Modal.Footer>
      </Modal>
      {/* change guarantee person modal End  */}

      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title> የአክስዮን መያዣ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            priceperstock: "",
            stockqty: "",
            letternum: "",
            letterdate: "",
            bankId: "0",
            serialnum: "",
          }}
          validationSchema={GuaranteeStock}
          onSubmit={saveStock}
        >
          {({ errors }) => (
            <Form>
              <Modal.Body>
                <TextFormikInput
                  name="priceperstock"
                  placeholder="የአንዱ አክስዮን ዋጋ"
                />
                <TextFormikInput name="stockqty" placeholder="የአክስዮን ብዛት" />
                <TextFormikInput name="letternum" placeholder="የደብዳቤ ቁጥር" />
                <TextFormikInput name="serialnum" placeholder="ሴሪ ቁጥር " />
                <div style={{ paddingTop: 5, paddingLeft: 5 }}>
                  <label> ባንክ </label>
                  <Field
                    as="select"
                    name="bankId"
                    className="form-control"
                    style={{
                      border: errors.bankId ? "1px solid red" : "",
                    }}
                  >
                    <option value="0">ባንክ ይምረጡ</option>
                    {banks?.length > 0 &&
                      banks.map((bank) => {
                        return (
                          <option key={bank.id} value={bank.id}>
                            {bank.amName}
                          </option>
                        );
                      })}
                  </Field>
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="bankId"
                    component="div"
                  />
                </div>
                <div style={{ paddingTop: 5, paddingLeft: 5 }}>
                  <label className="w-full py-1">
                    <div className="label">
                      <span className="label-text"> የደብዳቤ ቁጥር </span>
                    </div>
                    <Field
                      className="form-control"
                      placeholder="የደብዳቤ ቁጥር"
                      type="date"
                      name="letterdate"
                      style={{
                        border: errors.letterdate ? "1px solid red" : "",
                      }}
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="letterdate"
                      component="div"
                    />
                  </label>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" variant="primary btn-sm">
                  አስቀምጥ
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}> አስተካክል </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInputTw
            placeholder="የአንዱ አክስዮን ዋጋ"
            onChange={(e) => setpriceperstock(e.target.value)}
            value={priceperstock}
          />
          <TextInputTw
            placeholder="የአክስዮን ብዛት"
            onChange={(e) => setstockqty(e.target.value)}
            value={stockqty}
          />
          <TextInputTw
            placeholder="የደብዳቤ ቁጥር"
            onChange={(e) => setletternum(e.target.value)}
            value={letternum}
          />

          <TextInputTw
            placeholder="ሴርያል ቁጥር"
            onChange={(e) => setserialnum(e.target.value)}
            value={serialnum}
          />

          <div className="py-2">
            ባንክ ይምረጡ
            <select
              onChange={(e) => setbankId(e.target.value)}
              className="form-control"
            >
              <option value="0"> ባንክ ይምረጡ </option>
              {banks?.length > 0 &&
                banks.map((bank) => {
                  return (
                    <option
                      selected={bank.id === targetObj?.bankId}
                      key={bank.id}
                      value={bank.id}
                    >
                      {bank.amName}
                    </option>
                  );
                })}
            </select>
          </div>

          <label className="w-full py-1">
            <div className="label">
              <span className="label-text"> የደብዳቤ ቁጥር </span>
            </div>
            <input
              type="date"
              value={letterdate}
              onChange={(e) => setletterdate(e.target.value)}
              placeholder="የደብዳቤ ቁጥር"
              className="input input-bordered w-full form-control"
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="warning btn-sm"
            onClick={() => editStock()}
          >
            አስተካክል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteGuaranteeStock} variant="danger">
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: "bold", color: "GrayText" }}>
          የአክስዮን መያዣ
        </div>

        {data.groups[0] === "ROLE_OFFICER" && (
          <Button
            variant="primary"
            className="btn-sm"
            onClick={() => setModalAdd(true)}
            disabled={targetLoan.teamleaderchecking}
          >
            ጨምር
          </Button>
        )}
      </Alert>

      <div className="row">
        <div className="col-sm-12" style={{ paddingTop: 5 }}>
          {Object.keys(targetLoan)?.length > 0 ? (
            <Accordion defaultActiveKey="1">
              {targetLoan.collateralstock.map((stock) => {
                return (
                  <Accordion.Item eventKey={stock.id}>
                    <Accordion.Header>
                      <div className="flex flex-row items-center gap-2">
                        <FaFileAlt />
                        <Badge>
                          {
                            stock.stockfiles.filter(
                              (x) => x.isUploaded === true
                            )?.length
                          }
                          / {stock.stockfiles?.length}
                        </Badge>
                        {`${stock.bankId.amName} --- ${stock.priceperstock}*${
                          stock.stockqty
                        } = ${(
                          stock.priceperstock * stock.stockqty
                        )?.toLocaleString()}`}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                        <div>የአክሲዮን ሰርተፍኬት ዋስትና</div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            onClick={() => {
                              setModalEdit(true);
                              setTargetObj(stock);
                              console.log(stock);
                              setpriceperstock(stock.priceperstock);
                              setstockqty(stock.stockqty);
                              setletternum(stock.letternum);
                              setletterdate(stock.letterdate);
                              setbankId(stock.bankId.id);
                              setserialnum(stock.serialnum);
                            }}
                            className="btn btn-sm btn-warning"
                          >
                            አስተካክል
                          </button>
                          <button className="btn btn-sm btn-danger">አጥፋ</button>
                        </div>
                      </div>
                      {stock.stockfiles?.length > 0 ? (
                        <>
                          <Alert
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2 py-2 items-center"
                          >
                            <div>
                              በአክሲዮን ሰርተፍኬት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር
                            </div>
                            <Button
                              className="btn btn-warning btn-sm"
                              onClick={() => {
                                setTargetObj(stock);
                                reLoadData();
                              }}
                            >
                              የፋይል ዝርዝር አጥፋ
                            </Button>
                          </Alert>

                          <Table striped bordered hover style={{ margin: 1 }}>
                            <thead>
                              <tr>
                                <th> የፋይል ስም </th>
                                <th> እይ </th>
                                <th> አስፈላጊነት </th>
                                <th> ድርጊት </th>
                                <th> አጥፋ </th>
                              </tr>
                            </thead>
                            <tbody>
                              {stock.stockfiles.map((upload) => {
                                return (
                                  <tr
                                    className={
                                      upload.isUploaded ? `` : `table-warning`
                                    }
                                  >
                                    <td>{upload.amDesc}</td>
                                    <td>
                                      {upload.isUploaded ? (
                                        <>
                                          {upload.fileType ===
                                          "application/pdf" ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + upload.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setViewObj(upload);
                                                setShow(true);
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td>
                                      {upload.isMandatory ? "ግዴታ" : "አማራጭ"}
                                    </td>
                                    <td style={{ width: "30%", padding: 0 }}>
                                      {data.groups[0] === "ROLE_OFFICER" ? (
                                        <>
                                          <InputGroup style={{ paddingTop: 5 }}>
                                            <input
                                              onChange={(e) => {
                                                setFile(e.target.files[0]);
                                                console.log(e.target.files[0]);
                                              }}
                                              type="file"
                                              className="form-control"
                                            />
                                            <Button
                                              variant="outline-primary"
                                              onClick={() =>
                                                handleUpload(upload.id)
                                              }
                                            >
                                              {!upload.isUploaded ? (
                                                <> ጫን </>
                                              ) : (
                                                <>አዘምን</>
                                              )}
                                            </Button>
                                          </InputGroup>
                                        </>
                                      ) : (
                                        <>{upload.isUploaded ? "ተሰቅሏል" : " "}</>
                                      )}
                                    </td>
                                    <td style={{ width: "10%", padding: 0 }}>
                                      {upload.isUploaded && (
                                        <Button
                                          variant="outline-danger"
                                          onClick={() =>
                                            handleDeleteFile(upload.id)
                                          }
                                        >
                                          አጥፋ
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                          <div>በአክሲዮን ሰርተፍኬት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር</div>
                          <Button
                            className="btn-sm"
                            onClick={() => loadData(stock.id)}
                          >
                            ሳብ
                          </Button>
                        </Alert>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <>Bring Files</>
          )}
        </div>
      </div>
    </div>
  );
}

export default CollateralStockComp;
