import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Table,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { Formik, Form } from "formik";

// import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextFormikInput from "../../ReusableFormiks/TextFormikInput";
import SubcitySchema from "../../Schema/Lookups/SubcitySchema";

function Subcity() {
  const [t] = useTranslation("global");
  const { serverIP } = useSelector((store) => store.allsettings);
  const [subcity, setSubcity] = useState([]);
  const [name, setName] = useState("");
  const [amName, setAmName] = useState("");

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [targetObj, setTargetObj] = useState({});

  useEffect(() => {
    getSubcities();
  }, []);

  const getSubcities = () => {
    axios
      .get(`${serverIP}/subcity/subcitys`)
      .then(function (response) {
        setSubcity(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveSubcity = (values) => {
    console.log(values);
    axios
      .post(`${serverIP}/subcity/subcitys`, {
        enName: values.enName,
        amName: values.amName,
      })
      .then((res) => {
        console.log(res.data);
        setModalAdd(false);
        getSubcities();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editSubCity = (values) => {
    console.log("editSubCity");
    console.log(values);
    axios
      .patch(`${serverIP}/subcity/subcitys/${targetObj.id}`, {
        enName: name,
        amName,
      })
      .then((res) => {
        console.log(res.data);
        getSubcities();
        setModalEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>ክፍለ ከተማ</div>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            enName: "",
            amName: "",
          }}
          validationSchema={SubcitySchema}
          onSubmit={saveSubcity}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <TextFormikInput name="amName" placeholder="የክ/ከተማ ስም." />
                  <TextFormikInput name="enName" placeholder="Subcity Name" />
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}> አስተካክል </Modal.Title>
        </Modal.Header>

        <Modal.Body className="flex flex-col gap-2">
          <input
            type="text"
            placeholder="የክ/ከተማ ስም."
            className="form-control"
            onChange={(e) => setAmName(e.target.value)}
            value={amName}
          />
          <input
            type="text"
            placeholder="Subcity Name"
            className="form-control"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editSubCity()}>
            {t("officer.edit")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}> አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button>Delete</Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modals End  */}

      <Alert variant="info">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
          }}
        >
          <div>ክፍለ ከተማ ዝርዝር</div>
          <div>
            <Button
              variant="primary"
              className="btn-sm"
              onClick={() => setModalAdd(true)}
            >
              {t("officer.add")}
            </Button>
          </div>
        </div>
      </Alert>
      {subcity.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ስም </th>
              <th> Name </th>
              <th>{t("auditor.action")}</th>
            </tr>
          </thead>
          <tbody>
            {subcity.map((chk) => {
              return (
                <tr>
                  <td>{chk.id}</td>
                  <td>{chk.amName}</td>
                  <td>{chk.enName}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <FaTrashAlt
                        color="red"
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(chk);
                        }}
                      />
                      &nbsp;
                      <FaPencilAlt
                        color="orange"
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(chk);
                          setName(chk.enName);
                          setAmName(chk.amName);
                        }}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Subcity;
