import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, InputGroup, Modal, Table } from "react-bootstrap";

import { FaEye, FaFileAlt, FaPencilAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function CarGarageReport() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const { carId, loanId } = useParams();
  const [garageValue, setGarageValue] = useState(0);
  const [show, setShow] = useState(false);
  const [editModalFile, setEditModalFile] = useState(false);
  const [editModalPrice, setEditModalPrice] = useState(false);

  const [targetGarageValue, setTargetGarageValue] = useState({});
  const [file, setFile] = useState(null);

  const [garageEstimatedValue, setGarageEstimatedValue] = useState(0);

  useEffect(() => {
    getGarageValues();
  }, []);

  const getGarageValues = async () => {
    try {
      const theCarFiles = await axios.get(
        `${serverIP}/car_garagevalue/bycarid/${carId}`
      );
      console.log("theCarFiles.data");
      console.log(theCarFiles.data);
      setGarageEstimatedValue(theCarFiles.data);
    } catch (error) {
    } finally {
    }
  };

  const fileEdit = () => {
    if (!file) {
      //  toast.error("No File Selected");
      return;
    }

    const fd = new FormData();
    fd.append("file", file);
    fd.append("collateralcar", carId);
    fd.append("fileType", file.type);
    fd.append("fileUrl", file);

    axios
      .put(
        `${serverIP}/car_garagevalue/garagereports/${targetGarageValue.id}`,
        fd,
        {
          headers: {
            "Custom-Header": "value",
          },
        }
      )
      .then((res) => {
        setEditModalFile(false);
        getGarageValues();
      })
      .catch((err) => {});
  };

  const priceEdit = () => {
    axios
      .put(
        `${serverIP}/car_garagevalue/garagereports/${targetGarageValue.id}`,
        {
          garageValue: garageValue,
          collateralcar: carId,
        }
      )
      .then((res) => {
        console.log(res.data);
        setEditModalPrice(false);
        getGarageValues();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modal View Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton> {targetGarageValue.amDesc} </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + targetGarageValue.fileUrl}
            style={{ width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal View End  */}

      {/* Modal Price Edit Start  */}
      <Modal show={editModalPrice} onHide={() => setEditModalPrice(false)}>
        <Modal.Header closeButton>
          <Modal.Title variant="warning"> ማስተካከያ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              የጋራጅ ግምት ዋጋ
            </span>
            <input
              type="text"
              className="form-control"
              value={garageValue}
              onChange={(e) => setGarageValue(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-warning"
            onClick={() => {
              priceEdit();
            }}
          >
            አስተካክል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Price Edit End  */}

      {/* Modal File Edit Start  */}
      <Modal show={editModalFile} onHide={() => setEditModalFile(false)}>
        <Modal.Header closeButton>
          <Modal.Title variant="warning"> ማስተካከያ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={{ paddingTop: 5 }}>
            <input
              onChange={(e) => {
                setFile(e.target.files[0]);
                console.log(e.target.files[0]);
              }}
              type="file"
              className="form-control"
            />
            <Button
              variant="outline-warning"
              onClick={() => {
                fileEdit();
              }}
            >
              አስተካክል
            </Button>
          </InputGroup>
        </Modal.Body>
      </Modal>
      {/* Modal File Edit End  */}

      <Table striped bordered hover style={{ margin: 1 }}>
        <thead>
          <tr>
            <th className="text-center">የጋራጅ ግምት ዋጋ</th>
            <th className="text-center">ፋይል</th>
          </tr>
        </thead>
        <tbody>
          {garageEstimatedValue?.length > 0 &&
            garageEstimatedValue.map((upload) => {
              return (
                <tr className={upload.garageValue !== 0 ? "" : "table-warning"}>
                  <td>
                    <div className="flex justify-around">
                      {upload.garageValue?.toLocaleString()}
                      <FaPencilAlt
                        onClick={() => {
                          setTargetGarageValue(upload);
                          setGarageValue(upload.garageValue);
                          setEditModalPrice(true);
                        }}
                        style={{ color: "orange" }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-around">
                      {upload.fileType === "application/pdf" ? (
                        <>
                          <a
                            target="_blank"
                            href={serverIP + upload.fileUrl}
                            rel="noopener noreferrer"
                          >
                            <FaFileAlt />
                          </a>
                        </>
                      ) : (
                        <>
                          <FaEye
                            onClick={() => {
                              setTargetGarageValue(upload);
                              setShow(true);
                            }}
                          />
                        </>
                      )}

                      <FaPencilAlt
                        onClick={() => {
                          setTargetGarageValue(upload);
                          setGarageValue(upload.garageValue);
                          setEditModalFile(true);
                        }}
                        style={{ color: "orange" }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default CarGarageReport;
