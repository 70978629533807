import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Alert, Button, ButtonGroup, Table, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaLock, FaPencilAlt, FaTrashAlt, FaUnlock } from "react-icons/fa";

function LoanCommittee() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [committee, setCommittee] = useState([]);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalActivate, setModalActivate] = useState(false);
  const [modalDeActivate, setModalDeActivate] = useState(false);

  const [targetObj, setTargetObj] = useState({});
  const [jobpositions, setjobpositions] = useState([]);

  const [enFullName, setEnFullName] = useState("");
  const [amFullName, setAmFullName] = useState("");
  const [jp, setJp] = useState(0);

  useEffect(() => {
    getActiveCommittee();
    getJobPositions();
  }, []);

  const getActiveCommittee = () => {
    axios
      .get(`${serverIP}/loancommittee/committee`)
      .then((res) => {
        console.log(res.data);
        setCommittee(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobPositions = () => {
    axios
      .get(`${serverIP}/jobposition/jobposition`)
      .then(function (res) {
        console.log(res);
        setjobpositions(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveCommittee = () => {
    axios
      .post(`${serverIP}/loancommittee/committee`, {
        amFullName,
        enFullName,
        jobposition: jp,
      })
      .then((res) => {
        console.log(res.data);
        setCommittee(res.data);
        setModalAdd(false);
        getActiveCommittee();
        toast.success("Saved Successfuly");
      })
      .catch((err) => {
        console.log(err);
        setModalAdd(false);
        toast.error("Error Saving");
      });
  };

  const updateCommittee = () => {
    axios
      .patch(`${serverIP}/loancommittee/committee/${targetObj.id}`, {
        amFullName,
        enFullName,
        parent: jp,
      })
      .then((res) => {
        setModalEdit(false);
        getActiveCommittee();
        toast.success("Updated Successfuly");
      })
      .catch((err) => {
        console.log(err);
        setModalEdit(false);
        toast.error("Error Saving");
      });
  };

  const deleteCommittee = () => {
    axios
      .delete(`${serverIP}/loancommittee/committee/${targetObj.id}`)
      .then((res) => {
        setModalDelete(false);
        getActiveCommittee();
        toast.success("Deleted Successfuly");
      })
      .catch((err) => {
        console.log(err);
        setModalDelete(false);
        toast.error("Error Saving");
      });
  };

  const activate = () => {
    axios
      .patch(`${serverIP}/loancommittee/committee/${targetObj.id}`, {
        isActive: true,
      })
      .then((res) => {
        setModalActivate(false);
        getActiveCommittee();
        toast.success("Updated Successfuly");
      })
      .catch((err) => {
        console.log(err);
        setModalActivate(false);
        toast.error("Error Saving");
      });
  };

  const deactivate = () => {
    axios
      .patch(`${serverIP}/loancommittee/committee/${targetObj.id}`, {
        isActive: false,
      })
      .then((res) => {
        setModalDeActivate(false);
        getActiveCommittee();
        toast.success("Updated Successfuly");
      })
      .catch((err) => {
        console.log(err);
        setModalDeActivate(false);
        toast.error("Error Saving");
      });
  };

  return (
    <div>
      <ToastContainer  autoClose={1000} />
     

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> የብድር ኮሚቴዎች  </div>
        <Button
          variant="primary"
          className="btn-sm"
          onClick={() => setModalAdd(true)}
        >
          Add
        </Button>
      </Alert>

      {/* Modals Start  */}

      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <div style={{ fontSize: "bold" }}> Loan Committee </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            onChange={(e) => setJp(e.target.value)}
            style={{ marginBottom: 15 }}
          >
            <option> Select Job Position </option>
            {jobpositions.length > 0 &&
              jobpositions.map((jobp) => {
                return <option value={jobp.id}> {jobp.amName} </option>;
              })}
          </Form.Select>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም </InputGroup.Text>
            <Form.Control
              placeholder="ስም"
              aria-describedby="basic-addon1"
              onChange={(e) => setAmFullName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name </InputGroup.Text>
            <Form.Control
              placeholder="name"
              aria-describedby="basic-addon1"
              onChange={(e) => setEnFullName(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveCommittee()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}> Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም </InputGroup.Text>
            <Form.Control
              placeholder="ስም"
              aria-describedby="basic-addon1"
              value={amFullName}
              onChange={(e) => setAmFullName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name </InputGroup.Text>
            <Form.Control
              placeholder="name"
              aria-describedby="basic-addon1"
              value={enFullName}
              onChange={(e) => setEnFullName(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => updateCommittee()}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteCommittee()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modal Activate Start  */}
      <Modal show={modalActivate} onHide={() => setModalActivate(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> ወደስራ መልስ </Modal.Title>
        </Modal.Header>
        <Modal.Body>ወደስራ መልስ</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => activate()}>
            መልስ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Activate End  */}

      {/* Modal De-Activate Start  */}
      <Modal show={modalDeActivate} onHide={() => setModalDeActivate(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> ከስራ አስቁም </Modal.Title>
        </Modal.Header>
        <Modal.Body>ከስራ አስቁም</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deactivate()}>
            አስቁም
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal De-Activate End  */}

      {/* Modals End  */}
      <div className="row">
        <div className="col-sm-12">
          {committee.length > 0 ? (
            <Table striped bordered hover style={{ marginTop: 10 }}>
              <thead>
                <tr>
                  <th>Fullname </th>
                  <th>ሙሉስም </th>
                  <th>የስራ ድርሻ</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {committee.map((cmt) => {
                  return (
                    <tr>
                      <td>{cmt.enFullName}</td>
                      <td>{cmt.amFullName}</td>
                      <td>{cmt.jobposition?.amName}</td>
                      <td>{cmt.isActive ? "ACTIVE" : "NOT-ACTIVE"}</td>
                      <td>
                        <ButtonGroup size="sm">
                          <Button variant="danger">
                            <FaTrashAlt
                              onClick={() => {
                                setModalDelete(true);
                                setTargetObj(cmt);
                              }}
                            />
                          </Button>
                          <Button variant="warning">
                            <FaPencilAlt
                              onClick={() => {
                                setModalEdit(true);
                                setTargetObj(cmt);
                                setJp(cmt.jobposition?.id);
                                setAmFullName(cmt.amFullName);
                                setEnFullName(cmt.enFullName);
                              }}
                            />
                          </Button>
                          {cmt.isActive ? (
                            <Button
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                setModalDeActivate(true);
                                setTargetObj(cmt);
                              }}
                            >
                              <FaLock />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                setModalActivate(true);
                                setTargetObj(cmt);
                              }}
                            >
                              <FaUnlock />
                            </Button>
                          )}
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <Alert variant="danger">
              <b> No Record </b>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoanCommittee;
