import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  Table,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

import TextFormikInput from "../ReusableFormiks/TextFormikInput";
import EmployeeSchema from "../Schema/EmployeeSchema";
import TextInputTw from "../ReusableFormiks/TextInputTw";

function Employees() {
  const [t, i18n] = useTranslation("global");
  const { serverIP } = useSelector((store) => store.allsettings);
  const [emps, setEmps] = useState([]);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalActivate, setModalActivate] = useState(false);
  const [modalDeactivate, setModalDeactivate] = useState(false);

  const [targetObj, setTargetObj] = useState({});

  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [groups, setgroups] = useState("");

  useEffect(() => {
    getAllEmployees();
  }, []);

  const getAllEmployees = () => {
    axios
      .get(`${serverIP}/lpsauth/allusers`)
      .then((res) => {
        console.log(res.data);
        setEmps(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveEmployee = (values) => {
    axios
      .post(`${serverIP}/lpsauth/register`, {
        username: values.username,
        email: values.email,
        password: "P@55w0rd",
        first_name: values.first_name,
        last_name: values.last_name,
        groups: values.groups,
        is_active: "true",
      })
      .then((res) => {
        console.log(res.data);
        setModalAdd(false);
        getAllEmployees();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const employeeUpdate = () => {
    axios
      .patch(`${serverIP}/lpsauth/user/${targetObj.id}`, {
        username: username,
        email: email,
        first_name: first_name,
        last_name: last_name,
        // groups: groups,
      })
      .then((res) => {
        console.log(res.data);
        setModalEdit(false);
        getAllEmployees();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activateAccount = () => {
    axios
      .patch(`${serverIP}/lpsauth/user/${targetObj.id}`, {
        is_active: true,
      })
      .then((res) => {
        console.log(res.data);
        setModalActivate(false);
        getAllEmployees();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deactivateAccount = () => {
    axios
      .patch(`${serverIP}/lpsauth/user/${targetObj.id}`, {
        is_active: false,
      })
      .then((res) => {
        console.log(res.data);
        setModalDeactivate(false);
        getAllEmployees();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* {JSON.stringify(emps)} */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>ሰራተኛ መመዝገብያ </div>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            username: "",
            email: "",
            first_name: "",
            last_name: "",
            groups: "",
          }}
          validationSchema={EmployeeSchema}
          onSubmit={saveEmployee}
        >
          {({ errors }) => (
            <Form>
              <Modal.Body>
                <TextFormikInput name="username" placeholder="የተጠቃሚ ስም" />
                <TextFormikInput name="email" placeholder="ኢሜይል" />
                <TextFormikInput name="first_name" placeholder="ስም" />
                <TextFormikInput name="last_name" placeholder="የአባት ስም" />
                <div style={{ paddingTop: 5, paddingLeft: 5 }}>
                  <label>የስራ ድርሻ </label>
                  <Field
                    as="select"
                    name="groups"
                    className="form-control"
                    style={{
                      border: errors.groups ? "1px solid red" : "",
                    }}
                  >
                    <option value="">የስራ ድርሻ ይምረጡ </option>
                    <option value="ROLE_OFFICER">ROLE_OFFICER</option>
                    <option value="ROLE_TEAM_LEADER">ROLE_TEAM_LEADER</option>
                    <option value="ROLE_AUDITOR">ROLE_AUDITOR</option>
                    <option value="ROLE_PLANNER">ROLE_PLANNER</option>
                    <option value="ROLE_FINANCE">ROLE_FINANCE</option>
                  </Field>
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="groups"
                    component="div"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" variant="primary btn-sm">
                  አስቀምጥ
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>ሰራተኛ ማስተካከያ </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInputTw
            placeholder="የተጠቃሚ ስም"
            onChange={(e) => setusername(e.target.value)}
            value={username}
          />
          <TextInputTw
            placeholder="ኢሜይል"
            onChange={(e) => setemail(e.target.value)}
            value={email}
          />
          <TextInputTw
            placeholder="ስም"
            onChange={(e) => setfirst_name(e.target.value)}
            value={first_name}
          />
          <TextInputTw
            placeholder="የአባት ስም"
            onChange={(e) => setlast_name(e.target.value)}
            value={last_name}
          />
          <div className="py-2">
            የስራ ድርሻ
            <select className="form-control" disabled>
              <option selected={groups === "ROLE_OFFICER"} value="ROLE_OFFICER">
                ROLE_OFFICER
              </option>
              <option
                selected={groups === "ROLE_TEAM_LEADER"}
                value="ROLE_TEAM_LEADER"
              >
                ROLE_TEAM_LEADER
              </option>
              <option selected={groups === "ROLE_AUDITOR"} value="ROLE_AUDITOR">
                ROLE_AUDITOR
              </option>
              <option selected={groups === "ROLE_PLANNER"} value="ROLE_PLANNER">
                ROLE_PLANNER
              </option>
              <option selected={groups === "ROLE_FINANCE"} value="ROLE_FINANCE">
                ROLE_FINANCE
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => employeeUpdate()}>
            {t("officer.edit")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal De-activate Start  */}
      <Modal show={modalActivate} onHide={() => setModalActivate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div> ወደስራ መልስ </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>ሙሉ ስም: {targetObj.first_name + " " + targetObj.last_name}</div>
          {/* <div>የስራ ድርሻ፡ {targetObj?.groups[0]}</div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => activateAccount()}>
            ወደስራ መልስ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal De-activate End  */}

      {/* Modal De-activate Start  */}
      <Modal show={modalDeactivate} onHide={() => setModalDeactivate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div> ክስራ አሰናብት </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>ሙሉ ስም: {targetObj.first_name + " " + targetObj.last_name}</div>
          {/* <div>የስራ ድርሻ፡ {targetObj?.groups[0]}</div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deactivateAccount()}>
            አሰናብት
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal De-activate End  */}

      {/*  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> ሰራተኞች መመዝገብያ ቅጽ </div>
        <Button
          variant="primary"
          className="btn-sm"
          onClick={() => setModalAdd(true)}
        >
          {t("officer.add")}
        </Button>
      </Alert>

      {emps.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th> የመጠቀምያ ስም </th>
              <th> ሙሉ ስም </th>
              <th>ኢሜይል </th>
              <th>የስራ ድርሻ </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {emps.map((emp) => {
              return (
                <>
                  {emp?.groups[0] !== "ROLE_ADMIN" && (
                    <tr style={{ margin: 0, padding: 0 }}>
                      <td>{emp.username}</td>
                      <td>{emp.first_name + " " + emp.last_name}</td>
                      <td> {emp.email} </td>
                      <td>{emp?.groups[0]?.slice(5, 20)} </td>
                      <td style={{ margin: 0 }}>
                        <ButtonGroup className="btn-sm">
                          {emp.is_active ? (
                            <Button
                              variant="danger"
                              className="btn-sm"
                              onClick={() => {
                                setTargetObj(emp);
                                setModalDeactivate(true);
                              }}
                            >
                              አሰናብት
                            </Button>
                          ) : (
                            <Button
                              variant="primary"
                              className="btn-sm"
                              onClick={() => {
                                setTargetObj(emp);
                                setModalActivate(true);
                              }}
                            >
                              ወደስራ መልስ
                            </Button>
                          )}

                          <Button
                            variant="warning"
                            className="btn-sm"
                            onClick={() => {
                              setusername(emp.username);
                              setemail(emp.email);
                              setfirst_name(emp.first_name);
                              setlast_name(emp.last_name);
                              setgroups(emp.groups[0]);
                              setTargetObj(emp);

                              setModalEdit(true);
                            }}
                          >
                            አስተካክል
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Employees;
