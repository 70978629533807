import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Header from "./Reusables/Header";
import ReportStyle from "./Styles/ReportStyle";
import ebrima from "./font/ebrima.ttf";
import ebrimabold from "./font/ebrimabd.ttf";
import getEthiopicDate from "../Common/modules/GregorianToEthiopian";
import { useParams } from "react-router-dom";
import axios from "axios";
import TextBoldUnderlined from "./Reusables/TextBoldUnderlined";

const Subtitle = ({ children, ...props }) => (
  <Text style={ReportStyle.h1b} {...props}>
    {children}
  </Text>
);

const Styles = StyleSheet.create({
  underlinedFont: {
    fontFamily: "ebrimabold",
    color: "black",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: "center",
    fontFamily: "ebrimabold",
    fontWeight: "bold",
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: "ebrima",
    fontWeight: "bold",
  },
  text_normal: {
    fontSize: 9,
    marginBottom: 3,
    fontFamily: "ebrima",
  },

  rightView: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    fontFamily: "ebrima",
    justifyContent: "flex-end",
  },
  withdrawdate: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    fontFamily: "ebrima",
    justifyContent: "flex-end",
  },
  text: {
    marginTop: 1,
    fontSize: 12,
    fontFamily: "AmharicFont",
    fontWeight: "bold",
  },
  textbordered: {
    marginTop: 1,
    fontSize: 12,
    fontFamily: "AmharicFont",
    fontWeight: "bold",
    borderWidth: 1,
  },
  textbold: {
    marginTop: 1,
    fontSize: 14,
    paddingTop: 5,
    fontFamily: "AmharicFont",
    fontWeight: "bold",
  },
});

Font.register({
  family: "ebrima",
  src: ebrima,
});

Font.register({
  family: "ebrimabold",
  src: ebrimabold,
});

function RptWithdraw() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { withdrawId } = useParams();
  const [theWithdraw, settheWithdraw] = useState({});

  useEffect(() => {
    getWithdrawing();
  }, []);

  const getWithdrawing = () => {
    console.log(withdrawId);
    axios
      .get(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`)
      .then((res) => {
        settheWithdraw(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toEthiopic = (date) => {
    if (date) {
      const [year, month, day] = date.split("-");
      return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
    } else {
      return " - ";
    }
  };

  return (
    <PDFViewer style={{ width: "100%", height: 1300 }}>
      <Document title="Withdraw Form">
        <Page style={ReportStyle.newbody} wrap>
          <Header type="ወጪ መጠየቅያ ፎርም" />

          {Object.keys(theWithdraw).length > 0 ? (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "space-between",
                rowGap: 10,
              }}
            >
              <View>
                <Text style={ReportStyle.rptwithdrawsubject}>
                  የመደበኛ አባላት ወጪ መጠየቅያ ፎርም
                </Text>

                <Text style={Styles.text}>
                  ይህ ፎርም በአሚጎስ ሰራተኛ የሚሞላ የወጪ ማዘዣ ፎርም ነው፡፡
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Text style={Styles.text}>
                    የአባሉ ሙሉ ስም:
                    <Text style={Styles.textbordered}>
                      {theWithdraw?.parent?.amDisplayName}
                    </Text>
                  </Text>

                  <Text style={Styles.text}>
                    ኮድ: {theWithdraw?.parent?.entityExternalId}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Text style={Styles.text}>
                    ስልክ ቁጥር: {theWithdraw?.parent?.mobileNo}
                  </Text>

                  <Text style={Styles.text}>
                    የማህበሩ ስም:
                    {theWithdraw?.parent?.groupName === null
                      ? "Personal "
                      : theWithdraw?.parent?.groupName}
                  </Text>
                </View>

                <View
                  style={{
                    borderWidth: 1,
                    marginTop: 10,
                    borderRadius: 10,
                    padding: 10,
                    rowGap: 5,
                  }}
                >
                  <Text style={Styles.text}>
                    ወጪ የተጠየቀበት ቀን፡{" "}
                    <Text style={Styles.underlinedFont}>
                      {" "}
                      {theWithdraw.plannedDate}
                    </Text>
                  </Text>
                  <Text style={Styles.text}>
                    የአሚጎስ አባል የሆኑበት ቀን፡{" "}
                    <Text style={Styles.underlinedFont}>
                      {" "}
                      {theWithdraw.parent?.memberSince}
                    </Text>
                  </Text>
                  <Text style={Styles.text}>
                    የመጨረሻ ቁጠባ ገቢ የተደረገበት ቀን፡{" "}
                    <Text style={Styles.underlinedFont}>
                      {theWithdraw.appointmentDate}
                    </Text>
                  </Text>
                  <View
                    style={{
                      paddingLeft: 20,
                    }}
                  >
                    <Text style={Styles.text}>
                      1. በዛሬው ቀን ያላቸው የአክስዮን መጠን/ Total Share፡
                      <Text style={Styles.underlinedFont}>
                        {" "}
                        {theWithdraw.amount_share?.toLocaleString()}{" "}
                      </Text>
                    </Text>
                    <Text style={Styles.text}>
                      2. በዛሬው ቀን ያላቸው የብድር ቁጠባ መጠን/ Loan Saving፡
                      <Text style={Styles.underlinedFont}>
                        {" "}
                        {theWithdraw.amount_loansaving?.toLocaleString()}{" "}
                      </Text>
                    </Text>
                    <Text style={Styles.text}>
                      3. በዛሬው ቀን ያላቸው መደበኛ ቁጠባ መጠን/ Compulsary፡
                      <Text style={Styles.underlinedFont}>
                        {" "}
                        {theWithdraw.amount_compulsary?.toLocaleString()}{" "}
                      </Text>
                    </Text>
                  </View>
                  <Text style={Styles.text}>
                    አጠቃላይ ያላቸው ተቀማጭ አክስዮንም ጭምሮ፡
                    <Text style={Styles.underlinedFont}>
                      {" "}
                      {(
                        theWithdraw.amount_share +
                        theWithdraw.amount_loansaving +
                        theWithdraw.amount_compulsary
                      ).toLocaleString()}{" "}
                    </Text>
                  </Text>
                  <Text style={Styles.text}>
                    ስለሆነም ከላይ ስማቸው የተጠቀሱ የድርጅታችን አባል ካላቸው አጠቃላይ ተቀማጭ የ
                    <Text style={Styles.underlinedFont}>
                      {" "}
                      {theWithdraw.withdraw_amount?.toLocaleString()}{" "}
                    </Text>
                    ብር ወጪ ማድረግ የሚችሉ ስለሆነ ወጪ እንዲደረግላቸው እጠይቃለው፡፡
                  </Text>

                  <Text style={Styles.textbold}>
                    እኔ ስሜ የተጠቀሰው የማህበሩ አባል ስሆን በድርጅቱ ብድር ለመውሰድ ያስቀመጥኩት ተቀማጭ ብድር
                    የመውሰድ ሃሳቤን ስለቀየርኩኝ ከላይ የተጠቀሰው ቁጠባዬ እንዲወጣልኝ እጠይቃለው፡፡
                  </Text>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 15,
                  justifyContent: "space-between",
                  padding: 30,
                }}
              >
                <View
                  style={{
                    fontSize: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ rowGap: 5 }}>
                    <Text style={Styles.text_normal}>የስራክፍሉ ኦፊሰር </Text>
                    <TextBoldUnderlined
                      text={
                        theWithdraw?.plannedby
                          ? theWithdraw?.plannedby?.first_name +
                            " " +
                            theWithdraw?.plannedby?.last_name
                          : ""
                      }
                    />
                    <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
                  </View>
                  <View style={{ rowGap: 5 }}>
                    <Text style={Styles.text_normal}> የአመልካቹ </Text>
                    <TextBoldUnderlined
                      text={theWithdraw?.parent?.amDisplayName}
                    />
                    <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
                  </View>
                </View>

                <View
                  style={{
                    border: 1,
                    padding: 5,
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#F1F9FA",
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "ebrimabold",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    ወጪ የተፈቀደበት ቀን እኤአ
                    <Text style={Styles.underlinedFont}> 9/5/2024 </Text>
                    ዓ.ም
                    <Text style={Styles.underlinedFont}> 30/12/2016 </Text>
                  </Text>
                  {/* ABCD */}
                </View>

                <View
                  style={{
                    fontSize: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ rowGap: 5 }}>
                    <TextBoldUnderlined text="ወጪው ስለመፈቀዱ" />

                    <Text style={Styles.text_normal}>1. የአባላት አስተዳደር ሀላፊ</Text>
                    <TextBoldUnderlined
                      text={
                        theWithdraw?.evaluatedby !== null &&
                        theWithdraw?.evaluatedby?.first_name +
                          " " +
                          theWithdraw?.evaluatedby?.last_name
                      }
                    />
                    <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
                  </View>
                  <View style={{ rowGap: 5 }}>
                    <Text style={Styles.text_normal}>2. ሲስተም ኦዲተር </Text>
                    <TextBoldUnderlined text="" />
                    <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
                  </View>
                </View>

                <View
                  style={{
                    fontSize: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ rowGap: 5 }}>
                    <Text style={Styles.text_normal}>3. የኦዲት ክፍል ሀላፊ</Text>
                    <TextBoldUnderlined
                      text={
                        theWithdraw?.auditorid !== null &&
                        theWithdraw?.auditorid?.first_name +
                          " " +
                          theWithdraw?.auditorid?.last_name
                      }
                    />
                    <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
                  </View>
                  <View style={{ rowGap: 5 }}>
                    <Text style={Styles.text_normal}>4. የፋይናንስ ክፍል ሀላፊ </Text>
                    <TextBoldUnderlined
                      text={
                        theWithdraw?.financePerson !== null &&
                        theWithdraw?.financePerson?.first_name +
                          " " +
                          theWithdraw?.financePerson?.last_name
                      }
                    />
                    <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
                  </View>
                </View>
              </View>
            </View>
          ) : (
            <></>
          )}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptWithdraw;
