import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ebrimabold from "../font/ebrimabd.ttf";

function TextBoldUnderlined(props) {
  const Styles = StyleSheet.create({
    dynamicText: {
      fontFamily: "ebrimabold",
      color: "black",
      textDecoration: "underline",
      fontWeight: "bold",
    },
  });

  return (
    <Text>
      <Text style={Styles.dynamicText}> {props.text} </Text>
    </Text>
  );
}

export default TextBoldUnderlined;
