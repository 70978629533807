import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Button, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

function NewTransfers() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [newtransfers, setnewtransfers] = useState([]);

  useEffect(() => {
    getNewTransfers();
  }, []);

  const getNewTransfers = () => {
    axios
      .get(`${serverIP}/transferresponse/miniapp`)
      .then(function (res) {
        console.log(res.data);
        setnewtransfers(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> በቴሌብር የተከፈሉ  </div>
        <Button
          variant="primary"
          className="btn-sm"
          // onClick={() => setModalAdd(true)}
        >
          ወደስይስተም ይገለብጡ
        </Button>
      </Alert>

      {newtransfers.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> የከፋይ ስም </th>
              <th> ስልክ ቁጥር </th>
              <th> accountNumber </th>
              <th> የብር መጠን </th>
            </tr>
          </thead>
          <tbody>
            {newtransfers.map((trans) => {
              return (
                <tr>
                  <td>{trans.id}</td>
                  <td>{trans.payerName}</td>
                  <td>{trans.phoneNumber}</td>
                  <td>{trans.accountNumber}</td>
                  <td>{trans.total_amount}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default NewTransfers;
