import axios from "axios";
import React, { useState, useEffect } from "react";
import { Alert, Table } from "react-bootstrap";
// import WithdrawStatus from "../Common/WithdrawStatus";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AuditorWithdrawHome() {
  const { serverIP } = useSelector((store) => store.allsettings);

  const [withdrawals, setWithdrawals] = useState([]);
  useEffect(() => {
    getWithdrawals();
  }, []);

  const getWithdrawals = () => {
    axios
      .get(`${serverIP}/withdrawrows/atevaluator`)
      .then((res) => {
        setWithdrawals(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }



  return (
    <div>
      {withdrawals.length > 0 ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>መጠን</th>
                <th> አመልካች </th>
                <th>ቀሪ ቀናቶች </th>

                <th>የአባላት አስተዳደር ሀላፊ </th>
                <th>ፋይናንስ </th>
                <th>አስተካክል </th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.map((withdraw) => {
                return (
                  <tr key={withdraw.id}>
                    <td>{withdraw?.withdraw_amount?.toLocaleString()}</td>
                    <td>{withdraw?.parent?.amDisplayName}</td>
                    <td>{getDateDifferenceInDays(withdraw.appointmentDate)}</td>

                    <td> {withdraw.toAuditor ? "አይቶታል" : "ገና"} </td>
                    <td> {withdraw.toFinance ? "አይቶታል" : "ገና"} </td>
                    <td>
                      {withdraw.toEvaluator ? (
                        <Link
                          to={`/auditorchecking/${withdraw.id}`}
                          className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline text-ስም"
                        >
                          አስተካክል
                        </Link>
                      ) : (
                        "ገና"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Alert variant="danger"> No Withdrawal Record </Alert>
        </>
      )}
    </div>
  );
}

export default AuditorWithdrawHome;
