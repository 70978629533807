import axios from "axios";
import React, { useState } from "react";
import { Alert, Button, InputGroup, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setTargetLoan } from "../Common/redux/systemLookups";
import {
  FaEdit,
  FaEye,
  FaFileAlt,
  FaFileMedicalAlt,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function AdditionalDocs({ targetLoan, successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);

  const [modalAdd, setmodalAdd] = useState(false);
  const [modalUpdate, setmodalUpdate] = useState(false);
  const [modalDelete, setmodalDelete] = useState(false);

  const [descript, setDescript] = useState("");
  const [viewObj, setViewObj] = useState([]);
  const [show, setShow] = useState(false);

  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const saveFiles = () => {
    if (!file) {
      errornotify();
      return;
    }
    if (!descript) {
      errornotify();
      return;
    }
    if (!targetLoan) {
      errornotify();
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", file.type);
    formData.append("fileUrl", file);
    formData.append("descript", descript);
    formData.append("additionalfiles", targetLoan.id);
    formData.append("user", data.id);

    axios
      .post(`${serverIP}/loanadditionaldocs/additionalfiles`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        successnotify();
        setmodalAdd(false);
        getLoan();
      })
      .catch((err) => {
        errornotify();
      });
  };

  const updateFiles = () => {};

  const deleteFile = () => {
    axios
      .delete(`${serverIP}/loanadditionaldocs/additionalfiles/${viewObj.id}`)
      .then((res) => {
        successnotify();
        setmodalDelete(false);
        getLoan();
      })
      .catch((err) => {
        errornotify();
      });
  };

  const getLoan = async () => {
    try {
      const res = await axios.get(`${serverIP}/loan/loans/${targetLoan.id}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  return (
    <div>
      {/* Start Modal  */}

      {/* Add Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{setViewObj.descript}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {JSON.stringify(setViewObj)} */}
          <img
            src={serverIP + viewObj.fileUrl}
            style={{ width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={modalAdd} onHide={() => setmodalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ተጨማሪ መረጃ ጫን </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12">
            <label className="form-label">የመረጃው መግለጫ</label>
            <input
              type="text"
              className="form-control"
              value={descript}
              placeholder="የመረጃው መግለጫ"
              onChange={(e) => setDescript(e.target.value)}
            />
          </div>
          <InputGroup style={{ paddingTop: 5 }}>
            <input
              onChange={(e) => {
                setFile(e.target.files[0]);
                console.log(e.target.files[0]);
              }}
              type="file"
              className="form-control"
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => saveFiles()}>
            ጫን
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add End  */}

      {/* Update Start  */}
      <Modal show={modalUpdate} onHide={() => setmodalUpdate(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ማስተካከያ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={{ paddingTop: 5 }}>
            <input
              onChange={(e) => {
                setFile(e.target.files[0]);
                console.log(e.target.files[0]);
              }}
              type="file"
              className="form-control"
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            // onClick={() => updateFiles(upload.id)}
            onClick={() => updateFiles()}
          >
            አዘምን
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update End  */}

      {/* Update Start  */}
      <Modal show={modalDelete} onHide={() => setmodalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title> አጠፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>{viewObj.descript}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => deleteFile()}>
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update End  */}

      {/* End Modal  */}
      {/* <div>{JSON.stringify(targetLoan.loanadditionalfiles)}</div> */}
      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: "bold", color: "GrayText" }}>
          ተጨማሪ ዶክመንት
        </div>
        <Button
          onClick={() => setmodalAdd(true)}
          className="flex flex-row items-center gap-2"
        >
          ጨምር
        </Button>
      </Alert>

      {targetLoan?.loanadditionalfiles?.length > 0 && (
        <Table striped bordered hover style={{ margin: 1 }}>
          <thead>
            <tr className="text-center">
              <th> የፋይል ስም </th>
              <th> እይ </th>
              <th> አስተካክል </th>
            </tr>
          </thead>
          <tbody>
            {targetLoan?.loanadditionalfiles.map((zfile) => {
              return (
                <tr className="text-center">
                  <td>{zfile.descript}</td>
                  <td>
                    <div className=" flex justify-center">
                      {zfile.fileType === "application/pdf" ? (
                        <a
                          target="_blank"
                          href={serverIP + zfile.fileUrl}
                          rel="noopener noreferrer"
                        >
                          <FaFileAlt />
                        </a>
                      ) : (
                        <FaEye
                          onClick={() => {
                            setViewObj(zfile);
                            setShow(true);
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-around">
                      <FaPencilAlt
                        onClick={() => setmodalUpdate(true)}
                        color="orange"
                      />
                      <FaTrashAlt
                        color="red"
                        onClick={() => {
                          setmodalDelete(true);
                          setViewObj(zfile);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default AdditionalDocs;
