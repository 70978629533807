import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { setTargetClient, setTargetLoan } from "../Common/redux/systemLookups";
import LoanStatus from "../Common/LoanStatus";

function TeamLeaderHome() {
  const [t] = useTranslation("global");
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [loans, setLoans] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setAllLoans();
  }, []);

  const setAllLoans = () => {
    axios
      .get(`${serverIP}/loan/loans`)
      .then(function (response) {
        console.log(response.data);
        setLoans(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const redirectPage = async (custId, loanId) => {
    try {
      await getLoan(loanId);
      await getCustomer(custId);
      navigate(`/judgement/${custId}/${loanId}`);
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  const getLoan = async (loanId) => {
    try {
      const res = await axios.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  const getCustomer = async (custId) => {
    try {
      const res = await axios.get(`${serverIP}/customer/customers/${custId}`);
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error("Error fetching customer:", err);
    }
  };

  return (
    <div>
      <Alert>
        {t("applicant.loansAssignedTo")} <strong> {data.first_name} </strong>
      </Alert>

      <div className="row">
        <div className="col-sm-12">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t("applicant.externalID")}</th>
                <th>{t("applicant.fullName")}</th>
                <th>{t("officer.approvedPrincipal")}</th>
                <th>  የያዘው አካል </th>
                <th> ኦፊሰር </th>
                <th> 5C </th>
                <th> ውሳኔ  </th>
              </tr>
            </thead>
            <tbody>
              {loans.map((loan) => {
                return (
                  <>
                    {!loan.auditorapproved && loan.teamleaderchecking && (
                      <tr key={loan.id}>
                        <td>
                          {loan.customer.entityExternalId }
                        </td>
                        <td> {loan.customer.amDisplayName} </td>
                        <td> {loan.approvedPrincipal?.toLocaleString()} </td>

                       
                         <td>
                          <LoanStatus loan={loan} />
                        </td>
                        <td>
                          {loan.submittedBy?.first_name + " " + loan.submittedBy?.last_name}
                        </td>
                        <td>
                          <Link
                            to={`/loanevaluation/${loan?.customer?.id}/${loan?.id}`}
                            className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                          >
                            ግምገማ
                          </Link>
                        </td>
                       
                        <td>

                        <Link
                              className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                              disabled={true}
                              onClick={() =>
                                redirectPage(loan?.customer?.id, loan?.id)
                              }
                            >
                              ውሳኔ
                            </Link>

                          {/* <Link
                            to={`/judgement/${loan?.customer?.id}/${loan?.id}`}
                            className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                          >
                            ውሳኔ
                          </Link> */}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default TeamLeaderHome;
