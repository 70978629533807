import * as Yup from "yup";

const SpauseSchema = Yup.object().shape({
  amFullName: Yup.string().min(6, "ሙሉ ስም ከ6 ፊደል በታች መሆን የለበትም").required("ሙሉ ስም ያስፈልጋል"),
  gender: Yup.string().required("እባክዎ ፆታ ይምረጡ"),
  amSubcity: Yup.number().required("ክፍለ ከተማ ይምረጡ"),
  woreda: Yup.string().required("Woreda is mandatory"),
  houseNum: Yup.string().required("የቤት ቁጥር ያስፈልጋል"),
  mobileNo: Yup.string().min(9, 'Phone number must be exactly 9 characters').max(9, 'exceeds 9 characters').required('Phone number is required'),
});

export default SpauseSchema;
