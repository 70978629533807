import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

function NearDisburseDate() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [disbursements, setDisbursements] = useState([]);
  useEffect(() => {
    getDisbursements();
  }, []);

  const getDisbursements = () => {
    axios
      .get(`${serverIP}/loan/disbursementPlannedLoans`)
      .then((res) => {
        setDisbursements(res.data);
        console.log("Disbursements");
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  return (
    <div>
      {disbursements.map((disburse) => (
        <Card>
          <Card.Header> {disburse.customer.amDisplayName} </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>መጠን</th>
                  <th>ቀሪ ቀናቶች</th>
                
                </tr>
              </thead>
              <tbody>
                {disburse.disbursement_detail.map((detail) => (
                  <tr>
                    <td>{detail.amount}</td>
                    <td>{getDateDifferenceInDays(detail.appointmentDate)}</td>
                  
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default NearDisburseDate;
