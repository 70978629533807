import React, { useEffect, useState } from "react";
import SpauseSchema from "../Schema/SpauseSchema";
import { Alert, Button, Table } from "react-bootstrap";
import TextFormikInput from "../ReusableFormiks/TextFormikInput";
import axios from "axios";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";

function SpauseComp({ successnotify, errornotify }) {
  const { langName, serverIP } = useSelector((store) => store.allsettings);
  const { customerId } = useParams();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [fullName, setfullName] = useState("");
  const [spus, setspus] = useState([]);
  const [targetObj, setTargetObj] = useState({});
  const [subcities, setsubcities] = useState([]);

  useEffect(() => {
    getSpauses();
    getsubcities();
  }, []);

  const getSpauses = () => {
    axios
      .get(`${serverIP}/spause/spausebyparent/${customerId}`)
      .then((res) => {
        setspus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getsubcities = () => [
    axios
      .get(`${serverIP}/subcity/subcitys`)
      .then((res) => {
        setsubcities(res.data);
      })
      .catch((err) => {
        console.log(err);
      }),
  ];

  const saveSpouse = (values) => {
    axios
      .post(`${serverIP}/spause/detail`, {
        amFullName: values.amFullName,
        gender: values.gender,
        mobileNo: values.mobileNo,
        amSubcity: values.amSubcity,
        woreda: values.woreda,
        houseNum: values.houseNum,
        parent: customerId,
      })
      .then((res) => {
        successnotify();
        getSpauses();
        setModalAdd(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSpause = () => {
    console.log(targetObj);
    axios
      .delete(`${serverIP}/spause/detail/${targetObj.id}`)
      .then((res) => {
        getSpauses();
        setModalDelete(false);
        successnotify();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSpause = (values) => {
    axios
      .patch(`${serverIP}/spause/detail/${targetObj.id}`, {
        amFullName: values.amFullName,
        gender: values.gender,
        mobileNo: values.mobileNo,
        amSubcity: values.amSubcity,
        woreda: values.woreda,
        amWoreda: values.woreda,
        houseNum: values.houseNum,
        parent: customerId,
      })
      .then((res) => {
        successnotify();
        getSpauses();
        setModalEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            amFullName: "",
            amSubcity: "",
            houseNum: "",
            woreda: "",
            gender: "",
            mobileNo: "",
          }}
          validationSchema={SpauseSchema}
          onSubmit={saveSpouse}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="amFullName" placeholder="ሙሉ ስም" />
                  </div>

                  <div>
                    {/* <TextFormikInput name="amSubcity" placeholder="ክፍለ ከተማ" /> */}
                    <label> ክ/ከተማ ይምረጡ </label>
                    <Field
                      as="select"
                      name="amSubcity"
                      className="form-control"
                      style={{
                        border: errors.amSubcity ? "1px solid red" : "",
                      }}
                    >
                      <option value="0"> ክ/ከተማ ይምረጡ </option>
                      {subcities.map((subc) => {
                        return (
                          <option key={subc.id} value={subc.id}>
                            {subc.amName}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="amSubcity"
                      component="div"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="woreda" placeholder="Woreda" />
                    <TextFormikInput name="houseNum" placeholder="የቤት ቁጥር" />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput
                      name="mobileNo"
                      placeholder="Mobiile Number"
                    />
                    <TextFormikInput name="gender" placeholder="ፆታ" />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}> Edit </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            amFullName: targetObj.amFullName,
            amSubcity: targetObj.amSubcity,
            amWoreda: targetObj.amWoreda,
            houseNum: targetObj.houseNum,
            woreda: targetObj.woreda,
            gender: targetObj.gender,
            mobileNo: targetObj.mobileNo,
          }}
          validationSchema={SpauseSchema}
          onSubmit={updateSpause}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput
                      name="amFullName"
                      value={fullName}
                      placeholder="ሙሉ ስም"
                    />
                  </div>

                  <div>
                    {/* <TextFormikInput name="amSubcity" placeholder="ክፍለ ከተማ" /> */}
                    <label> ክ/ከተማ ይምረጡ </label>
                    <Field
                      as="select"
                      name="amSubcity"
                      className="form-control"
                      style={{
                        border: errors.amSubcity ? "1px solid red" : "",
                      }}
                    >
                      <option value="0"> ክ/ከተማ ይምረጡ </option>
                      {subcities.map((subc) => {
                        return (
                          <option key={subc.id} value={subc.id}>
                            {subc.amName}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="amSubcity"
                      component="div"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="woreda" placeholder="Woreda" />
                    <TextFormikInput name="houseNum" placeholder="የቤት ቁጥር" />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput
                      name="mobileNo"
                      placeholder="Mobiile Number"
                    />
                    <TextFormikInput name="gender" placeholder="ፆታ" />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="warning btn-sm">
                    አስተካክል
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteSpause} variant="danger">
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals End  */}

      <div className="row">
        <div className="col-sm-12">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 0,
              margin: 0,
            }}
          >
            {spus.length === 0 && (
              <Button
                variant="primary"
                className="btn-sm"
                onClick={() => setModalAdd(true)}
              >
                ጨምር
              </Button>
            )}
          </div>
          <Table striped bordered hover style={{ marginTop: 10 }}>
            <thead>
              <tr>
                <th>ሙሉስም </th>
                <th>ሞባይል</th>
                <th>አድራሻ</th>
                <th>ሁኔታ</th>
              </tr>
            </thead>
            <tbody>
              {spus.map((sp) => {
                return (
                  <tr>
                    <td> {sp.amFullName} </td>
                    <td> {sp.mobileNo} </td>
                    <td> {sp.woreda} </td>
                    {/* <td> {sp.subcity + "/" + sp.woreda} </td> */}
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FaTrashAlt
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(sp);
                        }}
                        color="red"
                      />
                      &nbsp;
                      <FaPencilAlt
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(sp);
                        }}
                        color="orange"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default SpauseComp;
