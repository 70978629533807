import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Header from "./Reusables/Header";
import RequestFormStyles from "./Styles/ReportStyle";
import EtCurrency from "../Common/modules/currency";
import AmharicFont from "./font/ebrima.ttf";
import Btable from "./Reusables/Btable";
import Tr from "./Reusables/Tr";
import Trc from "./Reusables/Trc";
import getEthiopicDate from "../Common/modules/GregorianToEthiopian";

import ReusableTable from "./Reusables/ReusableTable";

import { useParams } from "react-router-dom";
import axios from "axios";

const Styles = StyleSheet.create({
  text: {
    marginTop: 1,
    fontSize: 12,
    textAlign: "center",
    fontFamily: "AmharicFont",
    fontWeight: "bold",
    color: "red",
  },
});

Font.register({
  family: "AmharicFont",
  src: AmharicFont,
});

const Subtitle = ({ children, ...props }) => (
  <Text style={RequestFormStyles.h1b} {...props}>
    {children}
  </Text>
);

function RequestFormDoc() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { customerId, loanId } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loan, setLoan] = useState([]);

  useEffect(() => {
    getCustomer();
    getLoan();
  }, []);

  const getCustomer = () => {
    axios
      .get(`${serverIP}/customer/customers/${customerId}`)
      .then((res) => {
        console.log(res.data);
        setCustomer(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoan = () => {
    axios
      .get(`${serverIP}/loan/loans/${loanId}`)
      .then((res) => {
        console.log(res.data);
        setLoan(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toEthiopic = (date) => {
    if (date) {
      const [year, month, day] = date.split("-");
      return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
    } else {
      return " - ";
    }
  };

  return (
    <PDFViewer style={{ width: "100%", height: 1300 }}>
      <Document title="ብድር መጠየቅያ ፎርም">
        <Page style={RequestFormStyles.body} wrap>
          <Header type="መጠየቅያ ፎርም" />

          {Object.keys(loan).length > 0 ? (
            <>
              <Trc
                thekey={`ጥያቄው የቀረበበት ፕሮጀክት ቁጥር : ` + loan.loanId}
                borders="t0r1b1l1"
              />
              <Btable zid="1" zkey="ሙሉ ስም" zvalue={customer.amDisplayName} />
              <Btable
                zid="2"
                zkey="መለያ ቁጥር"
                zvalue={customer.entityExternalId}
              />
              <Btable
                zid="3"
                zkey="አድራሻ/ስልክ"
                zvalue={customer.amAddress + "/" + customer.mobileNo}
              />
              <Btable zid="4" zkey="ፆታ" zvalue={customer.gender} />
              <Btable
                zid="5"
                zkey="ጥያቄው የቀረበበት ቀን (DD/MM/YY) በሃበሻ እና በፈረንጅ"
                zvalue={toEthiopic(loan.submittedOnDate)}
              />
              <Btable
                zid="6"
                zkey="አባል የሆኑበት ቀን (DD/MM/YY)"
                zvalue={toEthiopic(customer.activationDate)}
              />
              <Btable zid="7" zkey="የብድር አይነት" zvalue={loan.loanProductName} />
              <Btable zid="8" zkey="የዋስትና አይነት" zvalue={loan.loanPurposeName} />

              <Btable
                zid="11"
                zkey="አሁን ያላቸው የቁጠባ መጠን/መደበኛ + አክስዮን/"
                zvalue={Number(
                  Number(loan.totalSaving) + Number(loan.totalShares)
                ).toLocaleString("am-ET", EtCurrency)}
              />
              <Btable
                zid="12"
                zkey="ብድሩ የሚወሰድበት የብዜት መጠን"
                zvalue={loan.multiplier}
              />
              <Btable
                zid="13"
                zkey="የሚደርሰው ብድር መጠን"
                bgcolor="orange"
                textalign="center"
                zvalue={Number(
                  loan.r_approvedPrincipal.toFixed(2)
                ).toLocaleString("am-ET", EtCurrency)}
              />
              <Btable
                zid="14"
                zkey="የአግልግሎት ክፍያ መጠን %"
                zvalue={loan.prcntServiceCharge + " %"}
              />
              <Btable
                zid="15"
                zkey="የብድር ዋስትና (ኢንሹራንስ)"
                zvalue={loan.prcntLifeInsurance + " %"}
              />
              <Btable
                zid="16"
                zkey="የአግልግሎት ክፍያ (LIS, SCH) + የብድር ዋስትና ብር"
                zvalue={(
                  loan.flatServiceCharge + loan.flatLifeInsurance
                ).toLocaleString()}
              />
              <Btable
                zid="17"
                zkey="ብደሩን የሚወስዱበት የወለድ መጠን (%)"
                zvalue={loan.annualInterestRate + " %"}
              />
              <Btable
                zid="18"
                zkey="ብድሩ የሚመለስበት አመት"
                zvalue={loan.numberOfRepayments / 12 + " ኣመት"}
              />
              <Btable
                zid="19"
                zkey="ወርሃዊ ክፍያ"
                zvalue={loan.totalDueForPeriod?.toLocaleString()}
              />
              <Btable
                zid="20"
                zkey="ጠቅላላ ክፍያ ከወለድ ጋር"
                zvalue={loan.r_totalInterestPayment?.toLocaleString()}
              />
              <Btable zid="21" zkey="የወረፋ ጊዜ" zvalue={loan.queueTime} />

              <Trc
                thekey="ተበዳሪ አስፈላጊ የብድር ሰነዶችን ማሟላት የሚጀምረው የወረፋ ጊዜ ከተጠናቀቀ በኋላ ይሆናል፡፡"
                borders="t0r1b1l1"
              />
              <Trc
                thekey="ማንኛውም ተበዳሪ የግዜ ውል የሚዋዋለው የአሚጎስ ሰራተኞች ከገመቱ በኋላ ይሆናል፡፡"
                borders="t0r1b1l1"
              />
              <Trc
                thekey="የብድር አስፈላጊ ሰነዶች ካሟሉ በኋላ ለብድር ውል ዝግጅት /LOAN PROCESS/ ይደረጋል፡፡"
                borders="t0r1b1l1"
              />
              <Trc
                thekey="የብድር ውል የመጨረሻ ፊርማ እንዲሁም እግድ ከተጠናቀቀ በኋላ 15 ቀናት ለቼክ ፊርማ /LOAN DISBURSMENT/ የሚውል ይሆናል፡፡"
                borders="t0r1b1l1"
              />
              <Trc
                thekey="በቀሪ ገቢ ለሚደረግ የገንዘብ መጠን ላይ በተሰጠ የቀጠሮ ቀን ልክ የብድር ወረፋ ላይ ጭማሪ የሚደረግ ይሆናል፡፡"
                borders="t0r1b1l1"
              />
              <Trc
                thekey="አመታዊ ኢንሹራንስ ክፍያው 0.5% ከአባሉ አጠቃላይ ተቀማጭ ሒሳብ (አክሲዎን መደበኛ ቁጠባ፣ ፍቃደኝነት ቁጠባ) በአመት አንዴ ተቀናሽ ይሆናል፡፡"
                borders="t0r1b1l1"
              />

              <Trc
                style={{ color: "red" }}
                thekey="በብድር ጥያቄው ላይ የተሰጠ ማብራርያ"
                borders="t0r0b1l1"
              />
              <View style={RequestFormStyles.fullfreebox}></View>
              <Trc
                thekey="ማህበሩ በማንኛውም ሰአት ማንኛውም ማሻሻያ ሊያደርግ ይችላል፡፡ ይህም በማንኛውም አባል ላይ ተፈጻሚነት ይኖረዋል፡፡"
                borders="t0r0b1l1"
              />
              <View style={RequestFormStyles.fullfreebox}>
                <Btable zid="" zkey="ቅጹን ያስሞላችው፡ ሰምሀል የኋላሸት" zvalue="" />
              </View>
            </>
          ) : (
            <></>
          )}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RequestFormDoc;
