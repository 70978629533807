import React, { useState, useEffect } from "react";
import { Accordion, Alert, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CarFiles from "./Car/CarFiles";
import CarMarketValue from "./Car/CarMarketValue";
import CarGarageReport from "./Car/CarGarageReport";
import { useSelector } from "react-redux";
import { FaFastBackward } from "react-icons/fa";

function CarCollateralFiles() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan, targetClient } = useSelector(
    (store) => store.systemLookups
  );
  // :loanId/:carId
  const { loanId, carId } = useParams();
  const [collateralDetail, setCollateralDetail] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCollateralDetail();
  }, []);

  const getCollateralDetail = () => {
    axios
      .get(`${serverIP}/collateral_car/collateralcar/${carId}`)
      .then((res) => {
        console.log("res.data");
        console.log(res.data);

        setCollateralDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goBack = () => {
    navigate(`/loanonofficer/${targetClient.id}/${targetLoan.id}`);
  };

  return (
    <div>
      <div className="flex justify-between items-center bg-slate-300 mb-2 px-3 py-1 rounded-md">
        <div>
          {"Car model " + collateralDetail.model?.enName + " "}-
          {collateralDetail.manufacturedYear?.yearRange} -
          {" Insurance " + collateralDetail.insuranceValue}
        </div>
        <Button onClick={goBack}>
          <FaFastBackward color="orange" />
        </Button>
      </div>
   
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>የመኪናው ሙሉ መረጃ</Accordion.Header>
          <Accordion.Body>
            <CarFiles />
            {/* <CarFiles carfiles={collateralDetail?.carfiles} /> */}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header> የገበያ ዋጋ ጥናት </Accordion.Header>
          <Accordion.Body>
            <CarMarketValue />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header> የጋራጅ ሪፖርት </Accordion.Header>
          <Accordion.Body>
            <CarGarageReport />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default CarCollateralFiles;
