import axios from "axios";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { FaFile } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoanStatus from "../Common/LoanStatus";

function OfficerFinishedloans() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [finishedLns, setFinishedLns] = useState([]);
  // const [filteredLoans, setFilteredLoans] = useState([]);

  useEffect(() => {
    getFinishedLoans();
  }, []);

  const getFinishedLoans = async () => {
    await axios
      .get(`${serverIP}/loan/finishedloans`)
      .then((res) => {
        setFinishedLns(res.data);
        // setFilteredLoans(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th> ቁጥር </th>
            <th> ሙሉ ስም </th>
            <th> የተፈቀደው የብድር መጠን </th>
            <th> ድርጊት</th>
            <th> ሁኔታ</th>
            <th> ውሎች </th>
            <th> እግዶች </th>
          </tr>
        </thead>
        <tbody>
          {finishedLns.length > 0 &&
            finishedLns.map((loan) => {
              return (
                <tr key={loan.id}>
                  <td>
                    {loan.customer.entityExternalId + " - " + loan.customer.id}
                  </td>
                  <td> {loan.customer.amDisplayName} </td>
                  <td> {loan.r_approvedPrincipal?.toLocaleString()} </td>

                  <td>
                    {loan.teamleaderchecking ? (
                      " - "
                    ) : (
                      <Link
                        className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                        disabled={true}
                        onClick={() =>
                          // redirectPage(loan?.customer?.id, loan?.id)
                          console.log("object")
                        }
                      >
                        ማስታካከያ
                      </Link>
                    )}
                  </td>
                  <td>
                    <LoanStatus loan={loan} />
                  </td>
                  <td>
                    <Link
                      to={`/contracts/${loan?.customer?.id}/${loan?.id}`}
                      className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                    >
                      ውሎች
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/prohibitions/${loan?.customer?.id}/${loan?.id}`}
                      className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                    >
                      እግዶች
                    </Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default OfficerFinishedloans;
