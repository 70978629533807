import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AdminHome() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [allCheckListTypes, setAllCheckListTypes] = useState([]);
  const [carManufactures, setCarManufactures] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [homeTypes, setHomeTypes] = useState([]);

  useEffect(() => {
    allChecklistTypes();
    carManufactureYear();
    carModel();
    homeType();
  }, []);

  const carManufactureYear = () => {
    axios
      .get(`${serverIP}/car_manufacturer/carmanufacture`)
      .then((res) => {
        console.log(res.data);
        setCarManufactures(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const carModel = () => {
    axios
      .get(`${serverIP}/car_model/cars`)
      .then((res) => {
        console.log(res.data);
        setCarModels(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const homeType = () => {
    axios
      .get(`${serverIP}/home_type/hometypes`)
      .then((res) => {
        console.log(res.data);
        setHomeTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const allChecklistTypes = () => {
    axios
      .get(`${serverIP}/checklist_type/checklisttypes`)
      .then((res) => {
        console.log(res.data);
        setAllCheckListTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <ListGroup as="ol" numbered>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/delegation" className="ms-2 me-auto">
                <div className="fw-bold"> Delegation checklist </div>
                ውክልና ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/singlesurety" className="ms-2 me-auto">
                <div className="fw-bold">Single Guarantee Person checklist</div>
                ያላገባ የዋስ ግለሰብ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/married" className="ms-2 me-auto">
                <div className="fw-bold">
                  Married General loan document checklist
                </div>
                ያገባ አጠቃላይ የብድር ሰነድ ማረጋገጫ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/single" className="ms-2 me-auto">
                <div className="fw-bold">
                  Single General loan document checklist
                </div>
                ነጠላ አጠቃላይ የብድር ሰነድ ማረጋገጫ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/house" className="ms-2 me-auto">
                <div className="fw-bold">Checklist of home loan documents</div>
                በቤት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/cars" className="ms-2 me-auto">
                <div className="fw-bold"> Checklist of car loan documents </div>
                በመኪና ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/salary" className="ms-2 me-auto">
                <div className="fw-bold">
                  Checklist of loan documents guaranteed by salary
                </div>
                በደመወዝ ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/membership" className="ms-2 me-auto">
                <div className="fw-bold">
                  Checklist of loan documents guaranteed by the member
                </div>
                በአባል ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/share" className="ms-2 me-auto">
                <div className="fw-bold">
                  Check list of loan documents guaranteed by stock certificates
                </div>
                በአክሲዮን ሰርተፍኬት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/marriedsurety" className="ms-2 me-auto">
                <div className="fw-bold">
                  Married Guarantee Person Checklist
                </div>
                ያገባ የዋስ ግለሰብ ዝርዝር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/banks" className="ms-2 me-auto">
                <div className="fw-bold"> Banks </div>
                ባንኮች
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/country" className="ms-2 me-auto">
                <div className="fw-bold"> Manufactured Country </div>
                የተመረተበት አገር
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
              <Link to="/companytype" className="ms-2 me-auto">
                <div className="fw-bold"> Company Type </div>
                የድርጅት አይነት
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className="col-sm-6">
          <ListGroup as="ol" numbered className="px-3 py-1">
            <ListGroup.Item className="d-flex justify-content-between items-center ">
              <Link to="/carmodel" className="ms-2 me-auto">
                <div className="fw-bold"> Car Model </div>
                የመኪና ሞዴል
              </Link>
              <Badge bg="primary" pill>
                {carModels.length}
              </Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/carmanufactureyear" className="ms-2 me-auto">
                <div className="fw-bold"> CarManufactureYear </div>
                የመኪና ምርት ዘመን
              </Link>
              <Badge bg="primary" pill>
                {carManufactures.length}
              </Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/hometype" className="ms-2 me-auto">
                <div className="fw-bold"> Home Type </div>
                የቤት አይነት
              </Link>
              <Badge bg="primary" pill>
                {homeTypes.length}
              </Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/loancommittee" className="ms-2 me-auto">
                <div className="fw-bold"> Loan Committee </div>
                የብድር ኮሚቴዎች
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/jobposition" className="ms-2 me-auto">
                <div className="fw-bold"> Job Position </div>
                የስራ ድርሻ
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/subcity" className="ms-2 me-auto">
                <div className="fw-bold"> Sub City </div>
                ክፍለ ከተማ
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/loantype" className="ms-2 me-auto">
                <div className="fw-bold"> Loan Types </div>
                የብድር አይነት
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/holidays" className="ms-2 me-auto">
                <div className="fw-bold"> Holidays </div>
                በአላት
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/marriedgp" className="ms-2 me-auto">
                <div className="fw-bold"> Married Guarantee Person </div>
                ያገባ የመታወቅያ / የንብረት ተያዥ
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between items-center">
              <Link to="/singlegp" className="ms-2 me-auto">
                <div className="fw-bold"> Single Guarantee Person </div>
                ያላገባ የመታወቅያ / የንብረት ተያዥ
              </Link>
              <Badge bg="primary" pill></Badge>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
