import React, { useState } from "react";
import CustmerComp from "../Tabs/CustmerComp";
import CustmerCompNew from "./CustomerCompNew";
import LoanComp from "../Tabs/LoanComp";

import SingleDocComp from "../Tabs/SingleDocComp";
import MarriedDocComp from "../Tabs/MarriedDocComp";

import GuaranteePersonComp from "../Tabs/GuaranteePersonComp";
import CollateralCarComp from "../Tabs/CollateralCarComp";
import CollateralHomeComp from "../Tabs/CollateralHomeComp";

import CollateralSalaryComp from "../Tabs/CollateralSalaryComp";
import CollateralStockComp from "../Tabs/CollateralStockComp";
import WitnessComp from "../Tabs/WitnessComp";
import AdditionalDocs from "./AdditionalDocs";
import CarCollateral from "./CarCollateral";

import SpauseComp from "../Tabs/SpauseComp";
// import SpauseFiles from "./SpauseFiles";

// New Way Practice
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import {
  FaChartLine,
  FaFilePdf,
  FaMoneyBillWave,
  FaRing,
  FaUserAlt,
} from "react-icons/fa";
import { FaCarAlt, FaFemale, FaHome, FaMale } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

function TabOrganizer() {
  const [t] = useTranslation("global");
  const { targetClient, targetLoan } = useSelector(
    (store) => store.systemLookups
  );

  const [key, setKey] = useState("CustmerComp");

  const successnotify = (message) => {
    toast.success(message === "" ? "ስራውን በተሳካ ሁኔታ ተከናዉኗል!" : message);
  };

  const errornotify = (message) => {
    toast.error(message === "" ? "ስህተት አጋጥሟል!" : message);
  };

  return (
    <>
      <ToastContainer />
      <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
        {/* <Tab eventKey="CustmerCompNew" title="New">
          <CustmerCompNew
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
            targetClient={targetClient}
          />
        </Tab> */}

        <Tab
          eventKey="CustmerComp"
          title={<span>{t("applicant.customerInfo")}</span>}
        >
          <CustmerComp
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
            targetClient={targetClient}
          />
        </Tab>
        <Tab
          eventKey="LoanComp"
          title={<span>{t("applicant.loanInformation")}</span>}
        >
          <LoanComp
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
            targetClient={targetClient}
          />
        </Tab>
        {targetClient?.isMarried && (
          <Tab
            eventKey="MarriedDocComp"
            title={
              <span className="flex flex-row gap-1 px-2">
                <FaRing /> {t("applicant.loanerfiles")}
              </span>
            }
          >
            <MarriedDocComp
              errornotify={errornotify}
              successnotify={successnotify}
              targetClient={targetClient}
              targetLoan={targetLoan}
            />
          </Tab>
        )}
        {!targetClient?.isMarried && (
          <Tab
            eventKey="SingleDocComp"
            title={<span>{t("applicant.loanerfiles")} </span>}
          >
            <SingleDocComp
              errornotify={errornotify}
              successnotify={successnotify}
              targetClient={targetClient}
            />
          </Tab>
        )}
        {targetClient?.isMarried && (
          <Tab
            eventKey="SpauseComp"
            title={
              <span className="flex flex-row gap-1 px-2 items-center">
                <div className="flex flex-row">
                  <FaFemale />/<FaMale />
                </div>
                {t("applicant.spouseInformation")}
              </span>
            }
          >
            <SpauseComp
              errornotify={errornotify}
              successnotify={successnotify}
            />
          </Tab>
        )}

        {/* <Tab
        eventKey="CarCollateral"
        title={
          <span className="flex flex-row gap-1 px-2">
            <FaCarAlt /> <div> collateral Car </div>
          </span>
        }
      >
        <CarCollateral
          errornotify={errornotify}
          successnotify={successnotify}
          targetLoan={targetLoan}
        />
      </Tab> */}

        <Tab
          eventKey="CollateralCarComp"
          title={
            <span className="flex flex-row gap-1 px-2">
              <FaCarAlt /> <div> {t("applicant.carCollateral")} </div>
            </span>
          }
        >
          <CollateralCarComp
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>
        <Tab
          eventKey="CollateralHomeComp"
          title={
            <span className="flex flex-row gap-1 px-2">
              <FaHome /> {t("applicant.houseCollateral")}
            </span>
          }
        >
          <CollateralHomeComp
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>
        <Tab
          eventKey="CollateralSalaryComp"
          title={
            <span className="flex flex-row gap-1 px-2">
              <FaMoneyBillWave /> {t("applicant.salaryCollateral")}
            </span>
          }
        >
          <CollateralSalaryComp
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>
        <Tab
          eventKey="CollateralStockComp"
          title={
            <span className="flex flex-row gap-1 px-2">
              <FaChartLine /> {t("applicant.stockCollateral")}
            </span>
          }
        >
          <CollateralStockComp
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>

        <Tab
          eventKey="GuaranteePersonComp"
          title={
            <span className="flex flex-row gap-1 px-2">
              <FaUserAlt /> {t("applicant.humanCollateral")}
            </span>
          }
        >
          <GuaranteePersonComp
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>
        <Tab
          eventKey="AdditionalDocs"
          title={
            <span className="flex flex-row gap-1 px-2">
              <FaFilePdf /> ተጨማሪ ዶክመንቶች
            </span>
          }
        >
          <AdditionalDocs
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>
      </Tabs>
    </>
  );
}

export default TabOrganizer;
