import axios from "axios";
import React, { useState, useEffect } from "react";
import { Accordion, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import RptHomeProhibition from "./RptHomeProhibition";
import RptCarProhibition from "./RptCarProhibition";
import RptShareProhibition from "./RptShareProhibition";

function Prohibitions() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { customerId, loanId } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loan, setLoan] = useState([]);

  useEffect(() => {
    getLoan();
    getCustomer();
  }, []);

  const getCustomer = async () => {
    await axios
      .get(`${serverIP}/customer/customers/${customerId}`)
      .then((res) => {
        console.log("customer");
        console.log(res.data);
        setCustomer(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoan = async () => {
    await axios
      .get(`${serverIP}/loan/loans/${loanId}`)
      .then((res) => {
        console.log("LOAN");
        console.log(res.data);
        setLoan(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <Alert style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <b>{customer.amDisplayName}</b>
              {" - " + customer.mobileNo}
            </div>
            <div>
              <b>{customer.gender}</b>
              <b>{customer.isMarried ? " - Married - " : " - Single - "}</b>
              <b> {customer.entityExternalId} </b>
            </div>
          </Alert>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Accordion defaultActiveKey="1">
            {loan?.collateralcar?.length > 0 &&
              loan.collateralcar.map((car) => {
                return (
                  <>
                    <Accordion.Item eventKey="carp">
                      <Accordion.Header>
                        <b style={{ color: "red" }}> መኪና እግድ </b> &nbsp;
                        <i>{car.cargp?.fullname}</i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RptCarProhibition loan={loan} car={car} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}

            {loan?.collateralhome?.length > 0 &&
              loan.collateralhome.map((home) => {
                return (
                  <>
                    <Accordion.Item eventKey="homep">
                      <Accordion.Header>
                        <b style={{ color: "red" }}> የቤት እግድ </b> &nbsp;
                        <i>{home.homegp?.fullname}</i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RptHomeProhibition loan={loan} home={home} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}

            {loan?.collateralstock?.length > 0 &&
              loan.collateralstock.map((stock) => {
                return (
                  <>
                    <Accordion.Item eventKey="stockp">
                      <Accordion.Header>
                        <b style={{ color: "red" }}> የአክስዮን እግድ </b> &nbsp;
                        <i>{stock.stockgp?.fullname}</i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RptShareProhibition loan={loan} stock={stock} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Prohibitions;
