import React, { useEffect, useState } from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import ReportStyle from "./Styles/ReportStyle";
import Header from "./Reusables/Header";
import getEthiopicDate from "../Common/modules/GregorianToEthiopian";

import Tr from "./Reusables/Tr";
import Trc from "./Reusables/Trc";

function LoanCover({ customer, loan, committee }) {
  const [etmemberSince, setetmemberSince] = useState();
  useEffect(() => {
    // To convert date to Ethiopian
    setetmemberSince(toEthiopic(customer.memberSince));
  }, []);

  const toEthiopic = (date) => {
    if (date) {
      const [year, month, day] = date.split("-");
      return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
    } else {
      return " - ";
    }
  };

  return (
    <PDFViewer style={{ width: "100%", height: 700 }}>
      <Document title="ከቨር">
        <Page style={ReportStyle.body} wrap>
          <Header type="የብድር ከቨር" />
          {/* first half start  */}
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ width: "40%" }}>
              <Tr
                thekey="የአመልካች ስም ከነ አያት: "
                val={customer.amDisplayName}
                borders="t1r1b1l1"
              />
              <Tr
                thekey="ክ/ከተማ: "
                val={customer.amSubcity}
                borders="t0r1b1l1"
              />
              <Tr
                thekey="ወረዳ: "
                val={<Text style={ReportStyle.h5b}>{customer.woreda}</Text>}
                borders="t0r1b1l1"
              />
              <Tr
                thekey="የቤት ቁጥር: "
                val={<Text style={ReportStyle.h5b}>{customer.houseNum}</Text>}
                borders="t0r1b1l1"
              />
              <Tr
                thekey="ስልክ ቁጥር: "
                val={<Text style={ReportStyle.h5b}>{customer.mobileNo}</Text>}
                borders="t0r1b1l1"
              />
              <Tr
                thekey="የመታወቅያ ቁጥር: "
                val={<Text style={ReportStyle.h5b}>{customer.idNum}</Text>}
                borders="t0r1b1l1"
              />
              <Tr
                thekey="የጋብቻ ሁኔታ: "
                val={
                  <Text style={ReportStyle.h5b}>
                    {customer.isMarried ? "Married" : "Single"}
                  </Text>
                }
                borders="t0r1b1l1"
              />

              <Tr
                thekey="የማህበሩ አባል የሆኑበት ጊዜ: "
                val={etmemberSince}
                borders="t0r1b1l1"
              />
              <Tr thekey="አንድ አባል ባስገባ ተጨማሪ: " val="" borders="t0r1b1l1" />
              <Tr thekey="በትክክል ቁጠባ የተቆጠበበት ወራት: " val="" borders="t0r1b1l1" />
              <Tr thekey="ቁጠባ ያልተቆጠበበት ወራት: " val="" borders="t0r1b1l1" />
              <Tr
                thekey="ያላቸው ቁጠባና አክስዮን: "
                val={<Text style={ReportStyle.h5b}>{loan.totalshares}</Text>}
                borders="t0r1b1l1"
              />
              <Tr thekey="ብድር የሚወሰድበት የብዜት መጠን: " val="" borders="t0r1b1l1" />
              <Tr thekey="የተጠየቀው የብድር መጠን: " val="" borders="t0r1b1l1" />
              <Tr
                thekey="አጠቃላይ ወርሀዊ ገቢ የባልና የሚስት: "
                val=""
                borders="t0r1b1l1"
              />
              <Tr thekey="ከድርጅቱ የሚገኝ የዋስትና ጥቅም: " val="" borders="t0r1b1l1" />
              <Tr
                thekey="ማስያዝ የሚጠበቅባቸው የዋስትና መጠን: "
                val=""
                borders="t0r1b1l1"
              />
              {/* <Tr thekey="አጠቃላይ ወርሀዊ ገቢ: " val="" borders="t0r1b1l1" />
              <Tr thekey="ወርሀዊ መደበኛ ቁጠባ: " val="" borders="t0r1b1l1" /> */}
            </View>

            <View style={{ width: "30%" }}>
              <Tr thekey="የብድር አይነቶች: " val="" borders="t1r1b1l0" />
              <Tr
                style={ReportStyle.h5b}
                thekey="የአደጋ ጊዜ ተጠሪ (የባለቤት ሙሉ ስም)"
                val=""
                borders="t0r1b1l0"
              />
              <Tr thekey="የተበዳሪው ተጠሪ ስም: " val="" borders="t0r1b1l0" />
              {/* spause info start  */}
              {customer.isMarried && (
                <>
                  <Tr thekey="የተበዳሪው የባለቤት ስም: " val="" borders="t0r1b1l0" />
                  <Tr thekey="ስልክ: " val="" borders="t0r1b1l0" />
                  <Tr thekey="ክ/ከተማ: " val="" borders="t0r1b1l0" />
                  <Tr thekey="ወረዳ: " val="" borders="t0r1b1l0" />
                  <Tr thekey="የቤት ቁጥር: " val="" borders="t0r1b1l0" />
                  <Tr thekey="አድራሻ: " val="" borders="t0r1b1l0" />
                </>
              )}
              {/* spause info end  */}
            </View>

            <View style={{ width: "30%" }}>
              <Tr thekey="የአባል የብድር መለያ ቁጥር: " val="" borders="t1r1b1l0" />
              <Tr thekey="ብድር የተወሰደበት ቀን: " val="" borders="t0r1b1l0" />
              <Tr thekey="ክፍያ የሚጀመርበት ቀን: " val="" borders="t0r1b1l1" />
              <Tr thekey="ክፍያ የሚያልቅበት ቀን: " val="" borders="t0r1b1l1" />
              <Tr thekey="የብድር መጠን: " val="" borders="t0r1b1l1: " />
              <Tr thekey="የወለድ መጠን: " val="" borders="t0r1b1l1" />
              <Tr thekey="የብድርና ወለድ መጠን: " val="" borders="t0r1b1l1" />
            </View>
          </View>
          {/* first half start  */}

          {/* second half start  */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <Text style={ReportStyle.h5}>
              የምበደረውን ብድር የህዝብ ገንዘብ እንደመሆኑ በወቅቱና በአግባቡ ለመክፈል በፈቃደኝነት ከታች የተዘረዘሩት
              ነጥቦች ላይ ተስማምቼ ተዋውያለው። አባሉ ድርጅቱ ለሚያ ወጣቸው የአሰራር ና የወለድ አተሳሰብ ደምቦች
              በቅድሚያ እንደተስማማባቸው ተደርጎ ይቆጠራል:: እንዴሁም ለሚያቀርበው ዋስትና የዋስትናው ኢንሹራንስ እጎስ
              በሚመርጠው የኢንሹራንስ ድርጅት የሚያሰራ ይሆናል፡፡ በተጨማሪም በመኪናው ላይ የአጎስ ሎጎ የመለጠፍ ማዴታ
              አለበት። የንብረቱ ኢንሹራንስ ማዜ አልፎት ካልተስተካከለ ድርጅቱ ከአባሉ ቁጠባ ቀንሶ ኢንሹራንሱ የሚያድስ
              ይሆናል። ብድር ሲፈቀድ አንድ አባል በቀረበለት የተለያዩ የቁጠባ ዓይነቶች በተቀማጭ እጥፍ ይሰላል፡፡
              ከፍተኛው የብድር ጣርያ የድርጅቱ የተከፈለ ካፒታል 10 % አይበልጥም [75ዐዐዐዐዐዐዐ] የብድር ጊዜና
              ወለድ፡ ብድሩን እንደሚወስደው የቁጠባ ኣይነት ከ6 ወራት እስከ 20 ዓመት ከ13-19% ወለድ የሚከፍል
              ይሆናል ወሰድ በቀሪው ብድር የሚሰላ [Decling intrest metiod] ይሆናል፡፡ ከፍተኛው የብድር
              ጊዜ 120 ወራት ይሆናል፡፡ የብድር የአገልሎት ክፍያ በአጠቃላይ የብድር መጠን በቅድምያ 3.0% እና
              የህይወት መድህን ዋስት 3.0% የሚከፈል ይሆናል ብድር በወቅቱ ባይከፈል በወርሃዊው ባልተከፊለ ክፍያ እና
              በቀጣይ ባልተከፍሉ ወራቶች ድምር ከፍያ 20ሬ ቅጣት ይከፍላል፡፡ በተጨማሪም ብድሩ እንደተበላሸ ብድር
              ከተያዘ ስለብድር አመላለሱ ለመሸምገል ድርጅቱ በሚመድባቸው ገላጋይ ሽማማሌዎች ለመዳኘት ተስማምቻለው።
              ብድር ሳይከፈል 1 ሳምንት ጊዜ ቢያልፈው ህጋዊ እርምጃ ያስከትላል አይከፈል ቀርቶ በማህበረዊከሚቱ እንደ
              ከሆነ እስከ ገበሬ ተጨማር ቅጣት እርምጃ ከተወሰነ ደዳሞ እስከ ያስከትላል፡፡
            </Text>
          </View>
          {/* second half start  */}

          {/* third half start  */}
          <Trc
            thekey="በማንኛውም ምክንያት የብድር ክፍያ ቢቆም ድርጅቱ አባሉ ካላቸው ተቀማጭ የመቀነስ (የማቻቻል) መብት ይኖረዋል  "
            width="100%"
            borders="t1r1b1l1"
          />
          <View>
            <Text style={ReportStyle.h5}>
              ብድር ተወስዶ በቀሪ የብድር መጠን በአንድ ጊዜ መከፈል ቢያስፈል ያለተከፍለ ወለድ እና ቅጣት ከነበረው
              ተከፈፍሎ ቀሪው ወለድ ተቀንሶ ብድሩን እንዲከፈል ይሆናል፡፡
            </Text>
          </View>
          {/* third half start  */}

          {/* fourth half start  */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Text style={ReportStyle.h5b}> የአመልካች እና የዋስ ስም ከነ ተቀማጭ መጠን </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Tr
              style={ReportStyle.h5b}
              thekey=" የአመልካች ስም "
              width="24%"
              borders="t1r1b1l1"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" አክስዮን  "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey="መደበኛ ቁጠባ"
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የፍቃደኝነት ቁጠባ "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የሚፈቀደው የብድር መጠን "
              width="19%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የአክስዮንና ቁጠባ ድምር "
              width="18%"
              borders="t1r1b1l0"
            />
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Tr
              thekey={<Text>{customer.amDisplayName}</Text>}
              width="24%"
              borders="t0r1b1l1"
            />
            <Tr
              thekey={<Text>{loan.totalShares?.toLocaleString()}</Text>}
              width="13%"
              borders="t0r1b1l0"
            />
            <Tr
              thekey={
                <Text>
                  {loan.totalSaving === null
                    ? " "
                    : loan.totalSaving?.toLocaleString()}
                </Text>
              }
              width="13%"
              borders="t0r1b1l0"
            />
            <Tr
              thekey={loan.willSaving?.toLocaleString()}
              width="13%"
              borders="t0r1b1l0"
            />
            <Tr
              thekey={
                <Text>{loan.r_totalInterestPayment?.toLocaleString()}</Text>
              }
              width="19%"
              borders="t0r1b1l0"
            />
            <Tr
              thekey={<Text>{loan.totalSaving + loan.totalShares}</Text>}
              width="18%"
              borders="t0r1b1l0"
            />
          </View>
          {/* fourth half start  */}

          <View
            style={{ display: "flex", flexDirection: "row", paddingTop: 5 }}
          ></View>

          {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Tr
              style={ReportStyle.h5b}
              thekey=" ለብድር ዋስትና የቀረቡ ዋስትናዎች  "
              width="25%"
              borders="t1r1b1l1"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የዋስትና አቅራቢ ስም "
              width="23%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የብር መጠን "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" ክ/ከተማ "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" ወረዳ "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የመ/ቁጥር "
              width="13%"
              borders="t1r1b1l0"
            />
          </View>

          {/* home */}
          {loan.collateralhome?.length > 0 &&
            loan.collateralhome.map((home) => {
              return (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Tr
                    thekey={home.hometype.amName}
                    width="25%"
                    borders="t0r1b1l1"
                  />
                  <Tr
                    thekey={
                      " " + loan.customer.amDisplayName || home.homegp?.fullname
                    }
                    width="23%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={(
                      home.homearea * home.hometype?.pricepercaremeter
                    ).toFixed(2)}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={home.subcity?.amName}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={home.subcity?.amName}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={home.subcity?.amName}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                </View>
              );
            })}

          {/* car */}
          {loan.collateralcar?.length > 0 &&
            loan.collateralcar.map((car) => {
              return (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Tr thekey=" መኪና " width="25%" borders="t0r1b1l1" />
                  <Tr
                    thekey={
                      car.cargp === null
                        ? customer.amDisplayName
                        : car.cargp?.fullname
                    }
                    width="23%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={(
                      (car.insuranceValue +
                        car?.marketvalue[0]?.marketValue +
                        car?.garageReport[0]?.garageValue) /
                      3
                    ).toFixed(2)}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={" " + customer.subcity || car.cargp?.subcity}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={
                      car.cargp === null ? customer.woreda : car.cargp?.woreda
                    }
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={" " + car.cargp?.idnum || customer.idNum}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                </View>
              );
            })}

          {/* Employee Salary */}
          {loan.collateralemployee?.length > 0 &&
            loan.collateralemployee.map((employee) => {
              return (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Tr thekey=" ደመወዝ " width="25%" borders="t0r1b1l1" />
                  <Tr
                    thekey={employee.empAmName}
                    width="23%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={employee.grossSalary * 3.6}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  {/* <Tr thekey="  " width="13%" borders="t0r1b1l0" />
                  <Tr thekey="  " width="13%" borders="t0r1b1l0" />
                  <Tr thekey="  " width="13%" borders="t0r1b1l0" /> */}
                  <Tr
                    thekey={employee.subcity?.amName}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr thekey={employee.woreda} width="13%" borders="t0r1b1l0" />
                  <Tr thekey={employee.idNum} width="13%" borders="t0r1b1l0" />
                </View>
              );
            })}

          {/* collateralstock  */}
          {loan.collateralstock?.length > 0 &&
            loan.collateralstock.map((stock) => {
              return (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Tr thekey=" ሼር  " width="25%" borders="t0r1b1l1" />
                  <Tr
                    thekey={
                      " " + customer.amDisplayName || stock.stockgp?.fullname
                    }
                    width="23%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={stock.stockqty * stock.priceperstock}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={
                      loan.customer.subcity ||
                      (stock.stockgp?.subcity == null
                        ? " "
                        : stock.stockgp?.subcity)
                    }
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={
                      loan.customer.woreda ||
                      (stock.stockgp?.woreda == null
                        ? " "
                        : stock.stockgp?.woreda)
                    }
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={
                      loan.customer.idNum ||
                      (stock.stockgp?.idnum == null
                        ? " "
                        : stock.stockgp?.idnum)
                    }
                    width="13%"
                    borders="t0r1b1l0"
                  />
                </View>
              );
            })}

          {/* fourth half end  */}

          {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}

          {/* final div start  */}
          <View
            style={{ display: "flex", flexDirection: "row", paddingTop: 7 }}
          >
            <View style={{ width: "50%" }}>
              <Tr thekey="የተፈቀደው የብድር መጠን" val="" borders="t1r1b1l1" />
              <Tr thekey="ብድሩ ተከፍሎ የሚያልቅበት ወር" borders="t0r1b1l1" />
              <Tr thekey="አመታዊ የወለድ መጠን" borders="t0r1b1l1" />
              <Tr thekey="ወርሀዊ የወለድና የዋናው ብድር ድምር ክፍያ" borders="t0r1b1l1" />
              <Tr thekey="ወርሀዊ መደበኛ ቁጠባ" borders="t0r1b1l1" />
              <Tr thekey="በቅድሚያ የሚከፈል የአገልግሎት ክፍያ" borders="t0r1b1l1" />
              <Tr thekey="የኢንሹራንስ ክፍያ" borders="t0r1b1l1" />
              <Tr
                thekey="ድምር የአገልግሎት የቴምብር እና የኢንሹራንስ ክፍያ"
                borders="t0r1b1l1"
              />
              <Tr
                thekey="ክፍያዎች ተቀንሰው በተበዳሪ እጅ የሚደርሰው ገንዘብ"
                borders="t0r1b1l1"
              />

              <Text style={{ ...ReportStyle.h5b, paddingTop: 10 }}>
                የብድር ኮሚቴ ቃለ ጉባኤ እና ውሳኔ
              </Text>
              <Text style={ReportStyle.h5}> የዋሶች ስምምነት</Text>
              <Text style={ReportStyle.h5}>
                እኔ ስሜ ከስር የተጠቀሰው ከአሚጎስ ለተሰጠው ብደር ሙሉ ዋስ መሆኔን በፊርማየ አረጋግጣለው፡፡ የዋሶች
                ስምና ፊርማ
              </Text>

              {/* three rows table design start */}
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Tr
                  style={ReportStyle.h5b}
                  thekey="ለብድር ዋስትና የቀረቡ ዋስትናዎች"
                  width="50%"
                  borders="t1r1b1l1"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey="ስልክ"
                  width="30%"
                  borders="t1r1b1l0"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey="ፊርማ"
                  width="20%"
                  borders="t1r1b1l0"
                />
              </View>
              {loan.guaranteeperson?.length > 0 &&
                loan.guaranteeperson.map((gp) => {
                  return (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Tr
                        thekey={gp.amFullname}
                        width="50%"
                        borders="t0r1b1l1"
                      />
                      <Tr thekey={gp.phonenum} width="30%" borders="t0r1b1l0" />
                      <Tr thekey=" " width="20%" borders="t0r1b1l0" />
                    </View>
                  );
                })}

              {/* የብድር ኮሚቴዎች */}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 10,
                }}
              >
                <Tr
                  style={ReportStyle.h5b}
                  thekey="የብድር ኮሚቴ"
                  width="50%"
                  borders="t1r1b1l1"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey="የስራ ድርሻ"
                  width="30%"
                  borders="t1r1b1l0"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey="ፊርማ"
                  width="20%"
                  borders="t1r1b1l0"
                />
              </View>

              {committee?.length > 0 &&
                committee.map((cmt) => {
                  return (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Tr
                        thekey={cmt.amFullName}
                        width="50%"
                        borders="t0r1b1l1"
                      />
                      <Tr
                        thekey={cmt.jobposition?.amName}
                        width="30%"
                        borders="t0r1b1l0"
                      />
                      <Tr thekey=" " width="20%" borders="t0r1b1l0" />
                    </View>
                  );
                })}

              {/* የብድር ኮሚቴዎች */}
            </View>
            <View style={{ width: "2%" }}></View>
            <View style={{ width: "48%" }}>
              <Text style={ReportStyle.h5b}>የብድር አስፈፃሚ ፍቃድና ፊርማ </Text>
              <Text style={ReportStyle.h5}>
                ተበዳሪው፤ የሚጠበቅባቸውን በአግባቡ ስላሙዋሉ ብድሩ እንዲፈቀድላቸው ስል በፊርማዬ አረጋግጣለሁ ፡፡
              </Text>
              <Text style={ReportStyle.h5}>ስም: ___________________ </Text>
              <Text style={ReportStyle.h5}>ፊርማ: _______________________ </Text>
              <View style={{ paddingTop: 5 }}> </View>
              <Text style={ReportStyle.h5b}> ውል ሰጪ ፡የብድር ክፍል ሃላፊ</Text>
              <Text style={ReportStyle.h5}>ስም: ___________________ </Text>
              <Text style={ReportStyle.h5}>ፊርማ: _______________________ </Text>
              <Text style={ReportStyle.h5}>ቀን: _______________________ </Text>
              <View style={{ paddingTop: 5 }}> </View>
              <Text style={ReportStyle.h5b}> ውል ተቀባይ /ተበዳሪ</Text>
              <Text style={ReportStyle.h5}>ስም: ___________________ </Text>
              <Text style={ReportStyle.h5}>ፊርማ: _______________________ </Text>
              <Text style={ReportStyle.h5}>ቀን: _______________________ </Text>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 5,
                }}
              >
                <Tr
                  style={ReportStyle.h5b}
                  thekey="እማኞች"
                  width="50%"
                  borders="t1r1b1l1"
                />
                <Tr
                  style={ReportStyle.h5b}
                  thekey="ፊርማ"
                  width="20%"
                  borders="t1r1b1l0"
                />
              </View>
              {loan.loanwitness?.length > 0 &&
                loan.loanwitness.map((witn) => {
                  return (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Tr
                        thekey={witn.amFullName}
                        width="50%"
                        borders="t0r1b1l1"
                      />
                      <Tr thekey=" " width="20%" borders="t0r1b1l0" />
                    </View>
                  );
                })}

              <View style={{ paddingTop: 5 }}> </View>
              <Text style={ReportStyle.h5b}> የገንዘብ ወይም የቼክ ተቀባይ ስምና ፊርማ</Text>
              <Text style={ReportStyle.h5}>ስም: ___________________ </Text>
              <Text style={ReportStyle.h5}>
                የቼክ መጠን: _______________________
              </Text>
              <Text style={ReportStyle.h5}>
                የቼክ ቁጥር: _______________________
              </Text>
            </View>
          </View>
          <View style={{ paddingTop: 5 }}>
            <Text style={{ ...ReportStyle.h5b, textAlign: "center" }}>
              የብድር መመለሻ ጊዜ የሚያዘው(የሚቆጠረው) ቼክ ከወሰዱበት ቀን ጀምሮ ይሆናል፡፡
            </Text>
          </View>
          {/* final div end  */}
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default LoanCover;
