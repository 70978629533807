import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaTelegram } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// redux toolkit
import { setTargetClient, setTargetLoan } from "../Common/redux/systemLookups";
import TabOrganizer from "../Tabs/TabOrganizer";
import ChatBox from "../Common/ChatBox";
import { fifthC, firstC, thirdC } from "../Common/modules/FiveCChecker";
import { toast } from "react-toastify";

function LoanOnOfficer() {
  const [t, i18n] = useTranslation("global");
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan, targetClient } = useSelector(
    (store) => store.systemLookups
  );
  const { data } = useSelector((store) => store.customer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalToTeamLeader, setModalToTeamLeader] = useState(false);
  const { customerId, loanId } = useParams();

  useEffect(() => {
    getCustomer_Loan();
  }, []);

  const getCustomer_Loan = async () => {
    try {
      await getLoan();
      await getCustomer();
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  const getLoan = async () => {
    try {
      const res = await axios.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  const getCustomer = async () => {
    try {
      const res = await axios.get(
        `${serverIP}/customer/customers/${customerId}`
      );
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error("Error fetching customer:", err);
    }
  };

  const sendToTeamLeader = () => {

    


    if (
      firstC(targetClient, targetLoan) === 35 &&
      thirdC(targetClient, targetLoan) >= 13 &&
      fifthC(targetLoan) === 25
    ) {
      axios
        .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
          auditorchecking: false,
          auditorapproved: false,
          teamleaderchecking: true,
          submittedBy: data.id,
          officerid: data.id,
        })
        .then((res) => {
          console.log(res.data);
          setModalToTeamLeader(false);
          getLoan();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error(" 5C አልተሟላም :: ");
    }
    setModalToTeamLeader(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: 5,
          paddingLeft: 10,
        }}
      >
        <div style={{ color: "orange" }}>
          <b>{targetClient.amDisplayName}</b> &nbsp;&nbsp;&nbsp;
          <b style={{ color: "black", fontStyle: "italic" }}>
            {targetClient.displayName}
          </b>
        </div>
        <div>
          {targetLoan?.teamleaderchecking ? (
            <> ወደ ቡድን መሪ ተልኳል </>
          ) : (
            <Button
              onClick={() => setModalToTeamLeader(true)}
              className="flex flex-row gap-1 px-2 items-center"
            >
              <FaTelegram /> ወደ ቡድን መሪ
            </Button>
          )}
        </div>
      </div>
      {/* Modal Edit Start  */}
      <Modal
        show={modalToTeamLeader}
        onHide={() => setModalToTeamLeader(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}>ወደ ቡድን መሪ</Modal.Title>
        </Modal.Header>
        <Modal.Body>ወደ ቡድን መሪ ለመላክ እርግጠኛ ነዎት? </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              sendToTeamLeader();
            }}
            className="flex flex-row gap-1 px-2"
          >
            <FaTelegram size={25} />
            {t("officer.send")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      <TabOrganizer />

      {/* <DaisyChat /> */}
      <ChatBox />
      {/* <ChatBoxTaileind /> */}
    </div>
  );
}

export default LoanOnOfficer;
