import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Define styles
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'column',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid black',
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    flexGrow: 1,
  },
  tableCell: {
    flexGrow: 1,
    padding: 5,
  },
  tableHeader: {
    backgroundColor: '#e0e0e0',
    fontWeight: 'bold',
  },
});

// Reusable Table Component
const ReusableTable = ({ columns, data }) => {
  return (
    <View style={styles.tableContainer}>
      {/* Render table headers */}
      <View style={[styles.tableRow, styles.tableHeader]}>
        {columns.map((column, index) => (
          <Text key={index} style={styles.tableCell}>
            {column.header}
          </Text>
        ))}
      </View>

      {/* Render table rows */}
      {data.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          {columns.map((column, colIndex) => (
            <Text key={colIndex} style={styles.tableCell}>
              {row[column.accessor]}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

export default ReusableTable;
