import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  ListGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FaMoneyBillWave, FaUserAlt } from "react-icons/fa";
import { RiEmotionUnhappyLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { setTargetClient, setTargetLoan } from "../Common/redux/systemLookups";
import { PulseLoader } from "react-spinners";

function OfficerHome() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [externalId, setExternalId] = useState(0);
  const [djangoUser, setDjangoUser] = useState([]);

  const [savingAccountTotal, setSavingAccountTotal] = useState(0);
  const [shareAccountTotal, setShareAccountTotal] = useState(0);
  const [activeLoanPlan, setActiveLoanPlan] = useState({});

  const [clientInitial, setClientInitial] = useState([]);
  const [clientDetail, setClientDetail] = useState({});
  const [loanPlan, setLoanPlan] = useState({});
  const [allSavings, setAllSavings] = useState({});

  const [objVoluntary, setObjVoluntary] = useState([]);
  const [objServiceCharge, setObjServiceCharge] = useState([]);
  const [objLifeInsurance, setObjLifeInsurance] = useState({});
  const [objTembir, setObjTembir] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [noPlan, setNoPlan] = useState(false);

  const [fileUrl, setFileUrl] = useState({});

  useEffect(() => {
    Object.keys(activeLoanPlan).length > 0
      ? getLoanPlanFromAmisisAndUpdateLpsLoanPlan(activeLoanPlan.id)
      : terminate();
  }, [activeLoanPlan]);

  const terminate = () => {
    setClientInitial([]);
    setClientDetail({});
    setLoanPlan({});
    setAllSavings({});
    setIsLoading(false);
    setTimeout(() => {
      setNoPlan(true);
    }, 2000);
  };

  const resetClientData = () => {
    setClientInitial([]);
    setClientDetail({});
    setLoanPlan({});
    setAllSavings({});
    setIsLoading(false);
  };

  function formatDateForDjango(year, month, date) {
    const formattedDate = new Date(year, month - 1, date);
    // Format the date to match Django's expected input format
    return formattedDate.toISOString().split("T")[0];
  }

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: "#FFA500" }}>Loading ...</div>
        </div>
      </div>
    );
  }

  const getLoanPlanFromAmisisAndUpdateLpsLoanPlan = (activeLoanPlanId) => {
    console.log("getLoanPlanFromAmisisAndUpdateLpsLoanPlan");
    axios
      .get(`${serverIP}/fineract/clientloanplan?loanPlanId=${activeLoanPlanId}`)
      .then(function (response) {
        setNoPlan(false);
        setLoanPlan(response.data);
        console.log("setLoanPlan");
        console.log(response.data);

        setObjServiceCharge(
          response.data.charges?.filter((x) =>
            x.name.toString().includes("CHA")
          )
        );
        setObjLifeInsurance(
          response.data.charges?.filter((x) =>
            x.name.toString().includes("INSU")
          )
        );
        setObjTembir(
          response.data.charges?.filter((x) =>
            x.name.toString().includes("TEMB")
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getClientByExternalId = async (externalId) => {
    resetClientData();
    setIsLoading(true);

    try {
      const res_ci = await axios.get(
        `${serverIP}/fineract/clientbyexternalid?entityExternalId=${Number(
          externalId
        )}`
      );
      setClientInitial(res_ci.data);
      console.log("setClientInitial");
      console.log(res_ci.data);

      const res_lps = await axios.get(
        `${serverIP}/customer/clientbyexternalid/${externalId}`
      );
      setDjangoUser(res_lps.data);
      console.log("setDjangoUser");
      console.log(res_lps.data);

      console.log("res_ci.data[0]?.entityId");
      console.log(res_ci.data[0]?.entityId);

      const res_ud = await axios.get(
        `${serverIP}/fineract/userdetail?entityAccountNo=${res_ci.data[0]?.entityId}`
      );
      setClientDetail(res_ud.data);
      console.log("setClientDetail");
      console.log(res_ud.data);

      // Customer Image Start
      // const res_image = await axios.get(
      //   `${serverIP}/fineract/customerimage?imageId=4`
      // );
      // setFileUrl(res_image.data);
      setFileUrl(null);
      // console.log("res_image");
      // console.log(res_image.data);
      // Customer Image End

      const res_as = await axios.get(
        `${serverIP}/fineract/allsaving?entityAccountNo=${res_ci.data[0]?.entityId}`
      );
      setAllSavings(res_as.data);
      console.log("setAllSavings");
      console.log(res_as.data);

      setActiveLoanPlan(
        res_as.data.loanAccounts !== undefined &&
          (res_as.data.loanAccounts?.find((ln) => ln.status.id !== 500) ===
          undefined
            ? new Object()
            : res_as.data.loanAccounts?.find((ln) => ln.status.id !== 500))
      );

      setObjVoluntary(
        res_as.data.savingsAccounts?.filter((x) =>
          x.productName.toString().includes("VOLUN")
        )
      );

      // const sum_savings = res_as.data.savingsAccounts?.reduce((accum, obj) => {
      //   return accum + (obj.accountBalance && !obj.productName.toString().includes("FUN") ? obj.accountBalance : 0);
      // }, 0);

      const sum_savings = res_as.data.savingsAccounts?.reduce((accum, obj) => {
        return accum + (obj.accountBalance ? obj.accountBalance : 0);
      }, 0);

      const sum_shares = res_as.data.shareAccounts?.reduce((accum, obj) => {
        return accum + obj.totalApprovedShares;
      }, 0);

      setShareAccountTotal(sum_shares);
      setSavingAccountTotal(sum_savings);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyCustomer = async () => {
    console.log(fileUrl);
    await axios
      .post(`${serverIP}/customer/customers`, {
        entityAccountNo: clientDetail.accountNo,
        entityExternalId: clientDetail.externalId,

        activationDate: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        memberSince: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        active: clientDetail.active,
        displayName: clientDetail.displayName,
        amDisplayName: clientDetail.displayName,
        firstname: clientDetail.firstname,
        amFirstname: clientDetail.firstname,
        middlename: clientDetail.middlename,
        amMiddlename: clientDetail.middlename,
        lastname: clientDetail.lastname,
        amLastname: clientDetail.lastname,
        gender: clientDetail.gender?.name,
        mobileNo: clientDetail.mobileNo,
        address: "Addis Ababa",
        amAddress: "Addis Ababa",
        dateOfBirth: formatDateForDjango(
          clientDetail?.dateOfBirth[0],
          clientDetail?.dateOfBirth[1],
          clientDetail?.dateOfBirth[2]
        ),
        isMarried: false,
        imageId: clientDetail.imageId,
        imagePresent: clientDetail.imagePresent,
        // fileUrl: fileUrl,
        imgBaseSixtyFour: fileUrl || null,
      })
      .then((res) => {
        // searchUserOn_Django();
        getClientByExternalId(externalId);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(" catch");
        console.log(err);
      });
  };

  const copyLoan = async () => {
    await axios
      .post(`${serverIP}/loan/loans`, {
        submittedBy: data.id,
        officerid: data.id,
        loanId: activeLoanPlan.id,
        totalSaving: savingAccountTotal,
        totalShares: shareAccountTotal,
        customer: djangoUser[0]?.id,
        approvedPrincipal: loanPlan.approvedPrincipal,
        approvedPrincipalDisbursed: loanPlan.netDisbursalAmount,
        schFromLoan:
          loanPlan.netDisbursalAmount !== loanPlan.approvedPrincipal
            ? false
            : true,
        annualInterestRate: loanPlan.annualInterestRate,
        numberOfRepayments: loanPlan.numberOfRepayments,
        // totalDueForPeriod: loanPlan.fixedEmiAmount,
        // totalDueForPeriod: loanPlan.totalDueForPeriod,
        totalDueForPeriod:
          loanPlan?.repaymentSchedule?.periods[2]?.totalDueForPeriod,

        willSaving:
          objVoluntary[0]?.accountBalance === undefined
            ? 0
            : objVoluntary[0]?.accountBalance,
        prcntServiceCharge: objServiceCharge[0]?.percentage,
        prcntLifeInsurance: objLifeInsurance[0]?.percentage,
        flatServiceCharge: objServiceCharge[0]?.amount,
        flatLifeInsurance: objLifeInsurance[0]?.amount,
        tembr: objTembir[0]?.amount,
        loanPurposeName: loanPlan.loanPurposeName,
        loanProductId: loanPlan.loanProductId,
        loanProductName: loanPlan.loanProductName,
        totalInterestPayment: loanPlan.repaymentSchedule?.totalInterestCharged,

        lastReturnDate: formatDateForDjango(
          loanPlan?.timeline?.expectedMaturityDate[0],
          loanPlan?.timeline?.expectedMaturityDate[1],
          loanPlan?.timeline?.expectedMaturityDate[2]
        ),

        expectedDisbursementDate: formatDateForDjango(
          loanPlan?.timeline?.expectedDisbursementDate[0],
          loanPlan?.timeline?.expectedDisbursementDate[1],
          loanPlan?.timeline?.expectedDisbursementDate[2]
        ),
        officerchecking: true,
      })
      .then((res) => {
        console.log(res.data);
        // searchUserOn_Django();
        getClientByExternalId(externalId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectPage = async (custId, loanId) => {
    try {
      await getLoan(loanId);
      await getCustomer(custId);
      navigate(`/loanonofficer/${custId}/${loanId}`);
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  const getLoan = async (loanId) => {
    try {
      const res = await axios.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  const getCustomer = async (custId) => {
    try {
      const res = await axios.get(`${serverIP}/customer/customers/${custId}`);
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error("Error fetching customer:", err);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={t("applicant.clientID")}
              value={externalId}
              onChange={(ev) => {
                setExternalId(ev.target.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  getClientByExternalId(externalId);
                }
              }}
            />
            <Button
              onClick={() => getClientByExternalId(externalId)}
              variant="outline-secondary"
              id="button-addon2"
            >
              {t("applicant.search")}
            </Button>
          </InputGroup>
        </div>
      </div>
      {noPlan ? (
        <>
          <div className="flex flex-col justify-center items-center py-60">
            <div>
              <RiEmotionUnhappyLine size={90} color="#FFA500" />
            </div>
            <div className="text-3xl text-orange-400 py-5">
              NO ACTIVE LOAN FOUND!
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            {(clientInitial.length > 0 ||
              Object.keys(clientInitial).length > 0) &&
            (Object.keys(clientDetail).length > 0 || clientDetail.length > 0) &&
            (loanPlan.length > 0 || Object.keys(loanPlan).length > 0) &&
            (allSavings.length > 0 || Object.keys(allSavings).length > 0) ? (
              <>
                <div className="col-sm-6">
                  <Card>
                    <Card.Header className="flex flex-row items-center gap-2">
                      <FaUserAlt /> የተበዳሪ መረጃ
                    </Card.Header>
                    <ListGroup>
                      <ListGroup.Item>
                        {`ሙሉ ስም: ${clientDetail.displayName}`}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {`${t("applicant.entityAccountNo")}: ${
                          clientDetail.accountNo
                        }`}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {`${t("applicant.account")}: ${
                          clientDetail.externalId
                        }`}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {`${t("applicant.gender")}: ${
                          clientDetail.gender?.name
                        }`}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {`${t("applicant.mobileNo")}: ${clientDetail.mobileNo}`}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {t("applicant.maritalStatus")}:
                        {clientDetail.isMarried
                          ? " " + t("review.Married")
                          : " " + t("review.Unmarried")}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {/* activationDate: {amisisUser.activationDate} */}
                        {`${t("applicant.activationDate")}: 
                        ${formatDateForDjango(
                          clientDetail?.activationDate[0],
                          clientDetail?.activationDate[1],
                          clientDetail?.activationDate[2]
                        )}`}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {`${t("applicant.dateOfBirth")}: 
                        ${formatDateForDjango(
                          clientDetail?.dateOfBirth[0],
                          clientDetail?.dateOfBirth[1],
                          clientDetail?.dateOfBirth[2]
                        )}`}
                      </ListGroup.Item>
                    </ListGroup>
                    <ListGroup></ListGroup>
                    <Card.Footer>
                      {djangoUser.length > 0 ? (
                        <Alert> የደንበኛ መረጃ ተገልብጧል </Alert>
                      ) : (
                        <Button onClick={copyCustomer}>የተብዳሪ መረጃ ይገልብጡ</Button>
                      )}
                    </Card.Footer>
                  </Card>
                </div>
                <div className="col-sm-6">
                  <Card>
                    <Card.Header className="flex flex-row items-center gap-2">
                      <FaMoneyBillWave /> <div>የብድር መረጃ </div>
                    </Card.Header>
                    <ListGroup>
                      <ListGroup>
                        <ListGroup.Item>
                          {`${t("applicant.saving")}: 
                          ${savingAccountTotal?.toLocaleString()}`}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {` አክስዮን : 
                          ${shareAccountTotal?.toLocaleString()}`}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {`የፍላጎት ቁጠባ፡ ${
                            loanPlan?.willSaving
                              ? loanPlan?.willSaving?.toLocaleString()
                              : 0
                          }`}
                        </ListGroup.Item>

                        {Object.keys(loanPlan).length > 0 ? (
                          <>
                            <ListGroup.Item>
                              {"የተፈቀደው የብድር መጠን፡ " +
                                loanPlan.approvedPrincipal?.toLocaleString()}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {` ${t("applicant.interest")}:
                              ${loanPlan.annualInterestRate + " %"}`}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {`${t("applicant.monthlyPay")}:
                              ${
                                loanPlan?.repaymentSchedule?.periods[2]
                                  ?.totalDueForPeriod
                                  ? loanPlan?.repaymentSchedule?.periods[2]?.totalDueForPeriod?.toLocaleString()
                                  : " አልተገኝም "
                              }`}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {t("applicant.numberOfRepayments")}:
                              {" " +
                                loanPlan.numberOfRepayments +
                                " " +
                                t("review.months")}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {t("applicant.tembir")}: {objTembir[0]?.amount}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {t("applicant.serviceCharge")}:
                              {" " +
                                objServiceCharge[0]?.percentage +
                                "% => " +
                                objServiceCharge[0]?.amount?.toLocaleString()}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {t("applicant.lifeInsurance")}: &nbsp;
                              {` ${
                                objLifeInsurance[0]?.percentage === undefined
                                  ? 0
                                  : objLifeInsurance[0]?.percentage
                              }% :  ${objLifeInsurance[0]?.amount?.toLocaleString()}`}
                            </ListGroup.Item>
                          </>
                        ) : (
                          t("applicant.noPlan")
                        )}
                      </ListGroup>
                    </ListGroup>
                    <Card.Footer>
                      {activeLoanPlan !== undefined &&
                      Object.keys(activeLoanPlan).length > 0 &&
                      !djangoUser.length > 0 ? (
                        <Alert variant="danger">መጀመርያ የተብዳሪ መረጃ ይገልብጡ</Alert>
                      ) : (
                        <>
                          {djangoUser[0]?.loans.length === 0 ? (
                            <Button onClick={copyLoan}>
                              {t("applicant.initializeLoanPlan")}
                            </Button>
                          ) : (
                            <Link
                              onClick={() => {
                                redirectPage(
                                  djangoUser[0]?.id,
                                  djangoUser[0]?.loans[
                                    djangoUser[0]?.loans.length - 1
                                  ]?.id
                                );
                              }}
                              className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                            >
                              {t("applicant.customerLoans")}
                            </Link>
                          )}
                        </>
                      )}
                    </Card.Footer>
                  </Card>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default OfficerHome;
