import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Header from "./Reusables/Header";
import ReportStyle from "./Styles/ReportStyle";
import ebrima from "./font/ebrima.ttf";
import ebrimabold from "./font/ebrimabd.ttf";
import getEthiopicDate from "../Common/modules/GregorianToEthiopian";
import { useParams } from "react-router-dom";
import axios from "axios";
import TextBoldUnderlined from "./Reusables/TextBoldUnderlined";

const Subtitle = ({ children, ...props }) => (
  <Text style={ReportStyle.h1b} {...props}>
    {children}
  </Text>
);

const Styles = StyleSheet.create({
  underlinedFont: {
    fontFamily: "ebrimabold",
    color: "black",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: "center",
    fontFamily: "ebrimabold",
    fontWeight: "bold",
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: "ebrima",
    fontWeight: "bold",
  },
  text_normal: {
    fontSize: 9,
    marginBottom: 3,
    fontFamily: "ebrima",
  },

  rightView: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    fontFamily: "ebrima",
    justifyContent: "flex-end",
  },
  withdrawdate: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    fontFamily: "ebrima",
    justifyContent: "flex-end",
  },
  text: {
    marginTop: 1,
    fontSize: 12,
    fontFamily: "AmharicFont",
    fontWeight: "bold",
  },
  textbordered: {
    marginTop: 1,
    fontSize: 12,
    fontFamily: "AmharicFont",
    fontWeight: "bold",
    borderWidth: 1,
  },
  textbold: {
    marginTop: 1,
    fontSize: 14,
    paddingTop: 5,
    fontFamily: "AmharicFont",
    fontWeight: "bold",
  },
});

Font.register({
  family: "ebrima",
  src: ebrima,
});

Font.register({
  family: "ebrimabold",
  src: ebrimabold,
});

function RptDisbursement() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { disburseId } = useParams();

  const toEthiopic = (date) => {
    if (date) {
      const [year, month, day] = date.split("-");
      return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
    } else {
      return " - ";
    }
  };

  return (
    <PDFViewer style={{ width: "100%", height: 1300 }}>
      <Document title="Withdraw Form">
        <Page style={ReportStyle.newbody} wrap>
          <Header type="የተፈቀደ ብድር ወጪ መጠየቅያ ፎርም" />
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptDisbursement;
