import React from "react";
import { Text, View } from "@react-pdf/renderer";
import ebrimanormal from "../font/ebrima.ttf";
import ebrimabold from "../font/ebrimabd.ttf";

function Trc(props) {
  return (
    <View
      style={{
        backgroundColor: props.bgcolor,
        width: props.width,
        display: "flex",
        textAlign: "center",
      }}
    >
      <View
        style={{
          ...props.style,
          fontFamily: "ebrimanormal",
          borderTopWidth: props.borders[1],
          borderBottomWidth: props.borders[3],
          borderRightWidth: props.borders[5],
          borderLeftWidth: props.borders[7],
          fontSize: 9,
        }}
      >
        <Text>{props.thekey}</Text>
        <Text>{props.val}</Text>
      </View>
    </View>
  );
}
export default Trc;
