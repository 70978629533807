import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Badge, Button, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { setTargetClient } from "../Common/redux/systemLookups";
import { FaEye, FaFileAlt } from "react-icons/fa";

function SingleDocComp({ targetClient, successnotify, errornotify }) {
  const dispatch = useDispatch();
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);

  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState([]);
  const [file, setFile] = useState(null);
  const [ddlSingleList, setDdlSingleList] = useState([]);
  const [progress, setProgress] = useState({
    started: false,
    pc: 0,
  });

  useEffect(() => {
    getDdlSingle();
  }, []);

  const getDdlSingle = () => {
    axios
      .get(`${serverIP}/ddlsingle/ddlsingles`)
      .then((res) => {
        const transformedArray = res?.data?.map((item) => ({
          parent: targetClient.id,
          user: data.id,
          amDesc: item.amName,
          checkListId: item.id,
          isMandatory: item.isMandatory,
          markValue: item.markValue,
        }));
        setDdlSingleList(transformedArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleUpload(fileid) {
    // console.log(fileid)
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append("file", file);
    fd.append("parent", targetClient.id);
    fd.append("fileType", file.type);
    fd.append("fileUrl", file);
    fd.append("isUploaded", true);

    setProgress((prevState) => {
      return { ...prevState, started: true };
    });

    axios
      .patch(`${serverIP}/customer_single/custommersingle/${fileid}`, fd, {
        onUploadProgress: (progressEvent) => {
          setProgress((prevState) => {
            return { ...prevState, pc: progressEvent.progress * 100 };
          });
        },
        headers: {
          "Custom-Header": "value",
        },
      })
      .then((res) => {
        successnotify("የተበዳሪ መረጃ ተስተካክሏል!");
        getCustomer();
      })
      .catch((err) => {
        errornotify();
        console.log(err);
      });
  }

  const handleDeleteFile = (fileid) => {
    axios
      .patch(`${serverIP}/customer_single/custommersingle/${fileid}`, {
        isUploaded: false,
      })
      .then((res) => {
        successnotify("የተበዳሪ መረጃ ተስተካክሏል!");
        getCustomer();
      })
      .catch((err) => {
        errornotify();
        console.log(err);
      });
  };

  const reLoadData = async () => {
    axios
      .delete(
        `${serverIP}/customer_single/custommersingle/deletebyparent/${targetClient.id}`
      )
      .then((res) => {
        console.log(res.data);
        getCustomer();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadData = async () => {
    try {
      const res = await axios.post(
        `${serverIP}/customer_single/custommersingle/bulkinsert`,
        ddlSingleList
      );
      console.log("re feched");
      getCustomer();
    } catch (err) {
      console.error("Error fetching customer:", err);
    }
  };

  const getCustomer = async () => {
    try {
      const res = await axios.get(
        `${serverIP}/customer/customers/${targetClient.id}`
      );
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error("Error fetching customer:", err);
    }
  };

  return (
    <div>
      {/* Modal Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj?.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + viewObj?.fileUrl}
            style={{ width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal End  */}
      {Object.keys(targetClient?.singlegeneralfiles)?.length > 0 ? (
        <>
          <Alert
            style={{ display: "flex", justifyContent: "space-between" }}
            className="mt-2 py-2 items-center"
          >
            <div>ያላገባ የዋስ ግለሰብ ዝርዝር</div>
            <Button className="btn btn-warning btn-sm" onClick={reLoadData}>
              የፋይል ዝርዝር አጥፋ
            </Button>
          </Alert>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th> የዶክመንት ስም </th>
                <th> እይ </th>
                <th> አስፈላጊነቱ </th>
                <th> ፋይል </th>
                <th> አጥፋ </th>
              </tr>
            </thead>
            <tbody>
              {targetClient?.singlegeneralfiles?.length > 0 &&
                targetClient?.singlegeneralfiles.map((upload) => {
                  return (
                    <tr
                      className={upload.isUploaded ? `` : `table-warning`}
                      style={{ margin: 0 }}
                      key={upload.id}
                    >
                      <td>
                        <Badge>{upload.markValue}</Badge> {upload.amDesc}
                      </td>
                      {/* <td> {upload.checkListId?.enName} </td> */}
                      <td>
                        {upload.isUploaded ? (
                          <>
                            {upload.fileType === "application/pdf" ? (
                              <a
                                target="_blank"
                                href={serverIP + upload.fileUrl}
                                rel="noopener noreferrer"
                              >
                                <FaFileAlt />
                              </a>
                            ) : (
                              <FaEye
                                onClick={() => {
                                  setViewObj(upload);
                                  setShow(true);
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {upload.isMandatory ? (
                          "መጫን አለበት"
                        ) : (
                          <div className="text-red-500">ግዴታ አይደለም</div>
                        )}
                      </td>
                      <td style={{ width: "30%", padding: 0 }}>
                        {data.groups[0] === "ROLE_OFFICER" ? (
                          <>
                            <InputGroup style={{ paddingTop: 0 }}>
                              <input
                                onChange={(e) => {
                                  setFile(e.target.files[0]);
                                  console.log(e.target.files[0]);
                                }}
                                type="file"
                                className="form-control"
                              />
                              <Button
                                variant="outline-primary"
                                onClick={() => handleUpload(upload.id)}
                              >
                                {!upload.isUploaded ? <>ጫን</> : <>አስተካክል</>}
                              </Button>
                            </InputGroup>
                          </>
                        ) : (
                          <>{upload.isUploaded ? "ጫን" : "አልተሰቀለም"}</>
                        )}
                      </td>
                      <td style={{ width: "10%", padding: 0 }}>
                        {upload.isUploaded && (
                          <Button
                            variant="outline-danger"
                            onClick={() => handleDeleteFile(upload.id)}
                          >
                            አጥፋ
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </>
      ) : (
        <Alert className="flex justify-between p-2">
           <div style={{ fontSize: 20, fontWeight: "bold", color: "GrayText" }}>
           ያላገባ የዋስ ግለሰብ ዝርዝር</div>
          <Button className="btn-sm" onClick={loadData}>
            ሳብ
          </Button>
        </Alert>
      )}
      {/* {JSON.stringify(targetClient)} */}
    </div>
  );
}

export default SingleDocComp;
