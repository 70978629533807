import React, { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import ChatBox from "../Common/ChatBox";
import { setTargetLoan } from "../Common/redux/systemLookups";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Badge, Button, Modal } from "react-bootstrap";
import { FaEye, FaFileAlt, FaTelegram } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

function JudgeLoan() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan, targetClient } = useSelector(
    (store) => store.systemLookups
  );
  const dispatch = useDispatch();
  const { loanId } = useParams();
  const { data } = useSelector((store) => store.customer);

  const [modalApprove, setModalApprove] = useState(false);
  const [modalToAuditor, setModalToAuditor] = useState(false);
  const [modalSendBack, setModalSendBack] = useState(false);

  const [viewObj, setViewObj] = useState([]);
  const [show, setShow] = useState(false);



  const approveLoan = () => {
    axios
      .patch(`${serverIP}/loan/loans/${loanId}`, {
        auditorapproved: true,
        auditorid: data.id,
      })
      .then((res) => {
        setModalApprove(false);
        toast.success("Sucessfully Approved");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toAuditor = () => {
    axios
      .patch(`${serverIP}/loan/loans/${loanId}`, {
        auditorchecking: true,
        teamleaderid: data.id,
      })
      .then((res) => {
        setModalApprove(false);
        setModalToAuditor(false);
        toast.success("Sucessfully Approved");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendBack = () => {
    console.log("ብድሩን ወደ ኦፊሰር መልስ");
    axios
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        teamleaderchecking: false,
        officerchecking: true,
      })
      .then((res) => {
        console.log(res.data);
        setModalSendBack(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ToastContainer autoClose={1000} />

      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{viewObj.amDesc}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {JSON.stringify(setViewObj)} */}
          <img
            src={serverIP + viewObj.fileUrl}
            style={{ width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>

      {/* Modal Send Back Start  */}
      <Modal show={modalSendBack} onHide={() => setModalSendBack(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ብድሩን ወደ ኦፊሰር መልስ </Modal.Title>
        </Modal.Header>
        <Modal.Body> ብድሩን ወደ ኦፊሰር መልስ </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning btn-sm"
            onClick={() => {
              sendBack();
            }}
          >
            መልስ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Send Back End  */}

      {/* Modal Approve Start  */}
      <Modal show={modalApprove} onHide={() => setModalApprove(false)}>
        <Modal.Header closeButton>
          <Modal.Title>የብድር እቅዱን አጽድቅ </Modal.Title>
        </Modal.Header>
        <Modal.Body>የብድር እቅዱን አጽድቅ</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary btn-sm"
            onClick={() => {
              approveLoan();
            }}
          >
            አጽድቅ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Approve End  */}

      {/* Modal Approve Start  */}
      <Modal show={modalToAuditor} onHide={() => setModalToAuditor(false)}>
        <Modal.Header closeButton>
          <Modal.Title>የብድር እቅዱን ወደ ኦዲተር ላክ </Modal.Title>
        </Modal.Header>
        <Modal.Body>የብድር እቅዱን ወደ ኦዲተር ላክ</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary btn-sm"
            onClick={() => {
              toAuditor();
            }}
          >
            ወደ ኦዲተር
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Approve End  */}

      <div className="px-3 flex flex-row justify-between p-1 items-center bg-gray-500 mb-2 rounded-md">
        <div style={{ color: "white" }}>
          <b>{targetClient.amDisplayName}</b> &nbsp;&nbsp;&nbsp;
          <b style={{ color: "black", fontStyle: "italic" }}>
            {targetClient.displayName}
          </b>
        </div>
        <div>
          {targetLoan.auditorchecking &&
            !targetLoan.auditorapproved &&
            data.groups[0] === "ROLE_AUDITOR" && (
              <Button
                onClick={() => setModalApprove(true)}
                className="flex flex-row items-center gap-2"
              >
                <FaTelegram /> አጽድቅ
              </Button>
            )}

          {targetLoan.auditorapproved && data.groups[0] === "ROLE_AUDITOR" && (
            <> ጸድቋል </>
          )}

          {targetLoan.teamleaderchecking &&
            !targetLoan.auditorchecking &&
            data.groups[0] === "ROLE_TEAM_LEADER" && (
              <div className="flex gap-1">
                <Button
                  className="btn btn-warning"
                  onClick={() => setModalSendBack(true)}
                >
                  ውድቅ አድርግ
                </Button>
                <Button
                  onClick={() => setModalToAuditor(true)}
                  className="flex flex-row items-center gap-2"
                >
                  <FaTelegram /> ወደ ኦዲተር ላክ
                </Button>
              </div>
            )}
          {targetLoan.auditorchecking &&
            data.groups[0] === "ROLE_TEAM_LEADER" && <> ወደ ኦዲተር ተልኳል </>}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-3">
          <ListGroup>
            <ListGroup.Item active="true">የደንበኛው መረጃ </ListGroup.Item>
            <ListGroup.Item>ሙሉ ስም፡ {targetClient.amDisplayName}</ListGroup.Item>
            <ListGroup.Item>
              የትዳር ሁኔታ፡ {targetClient.isMarried ? "ያገባ" : "ያላገባ"}
            </ListGroup.Item>
          </ListGroup>

          {targetClient.isMarried ? (
            <>
              <ListGroup style={{ paddingTop: 15 }}>
                <ListGroup.Item active="true"> የደንበኛው ፋይሎች </ListGroup.Item>
                {targetClient.marriedgeneralfiles?.map((mariedf) => {
                  return (
                    <ListGroup.Item
                      class="list-group-item"
                      style={{
                        backgroundColor: mariedf.isUploaded
                          ? "green"
                          : "orange",
                      }}
                    >
                      {mariedf.amDesc}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
              <ListGroup style={{ paddingTop: 15 }}>
                <ListGroup.Item active="true">የትዳር አጋር መረጃዎች </ListGroup.Item>
                <ListGroup.Item>
                  {targetClient.spausedetail[0]?.amFullName}
                </ListGroup.Item>
                <ListGroup.Item>
                  {targetClient.spausedetail[0]?.mobileNo}
                </ListGroup.Item>
              </ListGroup>
            </>
          ) : (
            <ListGroup style={{ paddingTop: 15 }}>
              <ListGroup.Item active="true"> የብድር መረጃ ፋይሎች </ListGroup.Item>
              {targetClient.singlegeneralfiles?.map((single) => {
                return (
                  <>
                    {single.isUploaded ? (
                      <ListGroup.Item className="flex justify-between">
                        <div>{single.amDesc}</div>
                        <div>
                          {single.fileType === "application/pdf" ? (
                            <a
                              target="_blank"
                              href={serverIP + single.fileUrl}
                              rel="noopener noreferrer"
                            >
                              <FaFileAlt />
                            </a>
                          ) : (
                            <FaEye
                              onClick={() => {
                                setViewObj(single);
                                setShow(true);
                              }}
                            />
                          )}
                        </div>
                      </ListGroup.Item>
                    ) : (
                      <ListGroup.Item
                        style={{
                          backgroundColor: "orange",
                        }}
                      >
                        {single.amDesc}
                      </ListGroup.Item>
                    )}
                  </>
                );
              })}
            </ListGroup>
          )}

          <ListGroup style={{ paddingTop: 15 }}>
            <ListGroup.Item active="true">የብድር መረጃ </ListGroup.Item>
            <ListGroup.Item>
              {`Total Saving: ${targetLoan.totalSaving?.toLocaleString()}`}
            </ListGroup.Item>
            <ListGroup.Item>
              {`Total Share: ${targetLoan.totalShares?.toLocaleString()}`}
            </ListGroup.Item>
            <ListGroup.Item>
              {`Approved Principal: ${targetLoan.r_approvedPrincipal?.toLocaleString()}`}
            </ListGroup.Item>
            <ListGroup.Item>
              {`Total Interest: ${targetLoan.r_totalInterestPayment?.toLocaleString()}`}
            </ListGroup.Item>
            <ListGroup.Item>
              {`Number Of repayments: ${targetLoan.numberOfRepayments}`} Months
            </ListGroup.Item>
            <ListGroup.Item>
              {`Annual interest Rate: ${targetLoan.annualInterestRate}`} %
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className="col-sm-9">
          <div className="row">
            <div className="col-sm-4">
              {/* collateral employee start  */}
              {targetLoan.collateralemployee?.length > 0 && (
                <ListGroup>
                  <ListGroup.Item active="true"> የደመወዝ መያዣዎች </ListGroup.Item>
                  {targetLoan.collateralemployee.map((emp) => {
                    return (
                      <>
                        <ListGroup>
                          <ListGroup.Item>{emp.empAmName}</ListGroup.Item>
                          <ListGroup.Item>
                            ስልክ ፡ {emp.empPhoneNum}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {`የድርጅት ስም፡ ${emp.companyname}`}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {`የድርጅት ስልክ፡ ${emp.mobileNo}`}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {`የድርጅት እድሜ፡ ${emp.companyage.toLocaleString()}`}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {`ደመወዝ፡ ${emp.grossSalary.toLocaleString()}`}
                          </ListGroup.Item>
                        </ListGroup>
                        <ListGroup.Item>
                          {emp.salaryfiles.length > 0 && (
                            <ListGroup>
                              {emp.salaryfiles.map((empf) => {
                                return (
                                  <>
                                    {empf.isUploaded ? (
                                      <ListGroup.Item className="flex justify-between">
                                        <div>{empf.amDesc}</div>
                                        <div>
                                          {empf.fileType ===
                                          "application/pdf" ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + empf.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setViewObj(empf);
                                                setShow(true);
                                              }}
                                            />
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                    ) : (
                                      <ListGroup.Item
                                        style={{
                                          backgroundColor: "orange",
                                        }}
                                      >
                                        {empf.amDesc}
                                      </ListGroup.Item>
                                    )}
                                  </>
                                );
                              })}
                            </ListGroup>
                          )}
                        </ListGroup.Item>
                      </>
                    );
                  })}
                </ListGroup>
              )}
              {/* collateral employee end  */}
              {/* collateral stock start  */}
              {targetLoan.collateralstock?.length > 0 && (
                <ListGroup className="pt-3">
                  <ListGroup.Item active="true"> የአክስዮን መያዣ </ListGroup.Item>
                  {targetLoan.collateralstock.map((stock) => {
                    return (
                      <>
                        <ListGroup>
                          <ListGroup.Item>
                            {`የአንድ የአክስዮን ዋጋ፡ ${stock.priceperstock}`}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {`የአክስዮን ብዛት: ${stock.stockqty}`}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {`የአክስዮን የሰጠው ድርጅት: ${stock.bankId?.amName}`}
                          </ListGroup.Item>

                          {stock.stockgp !== null ? (
                            <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                              <div>{`ባለንብረት: ${stock?.stockgp?.fullname}`}</div>
                            </ListGroup.Item>
                          ) : (
                            <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                              <div>ባለንብረት</div>
                              <div>ተበዳሪ</div>
                            </ListGroup.Item>
                          )}
                        </ListGroup>
                        <ListGroup.Item>
                          {stock.stockfiles.length > 0 && (
                            <ListGroup>
                              {stock.stockfiles.map((stf) => {
                                return (
                                  <>
                                    {stf.isUploaded ? (
                                      <ListGroup.Item className="flex justify-between">
                                        <div>{stf.amDesc}</div>
                                        <div>
                                          {stf.fileType ===
                                          "application/pdf" ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + stf.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setViewObj(stf);
                                                setShow(true);
                                              }}
                                            />
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                    ) : (
                                      <ListGroup.Item
                                        style={{
                                          backgroundColor: "orange",
                                        }}
                                      >
                                        {stf.amDesc}
                                      </ListGroup.Item>
                                    )}
                                  </>
                                );
                              })}
                            </ListGroup>
                          )}
                        </ListGroup.Item>
                      </>
                    );
                  })}
                </ListGroup>
              )}
              {/* collateral stock end  */}
            </div>

            {/* collateral car start  */}
            {targetLoan.collateralcar?.length > 0 && (
              <div className="col-sm-4">
                <ListGroup>
                  <ListGroup.Item active="true"> የመኪና መያዣዎች </ListGroup.Item>
                  {targetLoan.collateralcar.map((car, index) => {
                    return (
                      <>
                        <ListGroup.Item> {car.chassisNumber} </ListGroup.Item>
                        <ListGroup.Item>
                          Insurance: {car.insuranceValue?.toLocaleString()}
                        </ListGroup.Item>
                        <ListGroup.Item
                          style={{
                            backgroundColor:
                              car.garageReport.length > 0 ? "" : "red",
                          }}
                        >
                          {`Garage: ${car.garageReport[0]?.garageValue.toLocaleString()}`}
                        </ListGroup.Item>
                        <ListGroup.Item
                          style={{
                            backgroundColor:
                              car.marketvalue.length > 0 ? "" : "red",
                          }}
                        >
                          Market Value:{" "}
                          {car.marketvalue[0]?.marketValue.toLocaleString()}
                        </ListGroup.Item>

                        {car.cargp !== null ? (
                          <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                            <div>{`ባለንብረት: ${car?.cargp?.fullname}`}</div>
                          </ListGroup.Item>
                        ) : (
                          <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                            <div>ባለንብረት</div>
                            <div>ተበዳሪ</div>
                          </ListGroup.Item>
                        )}

                        <ListGroup.Item index={index}>
                          {car.carfiles.length > 0 && (
                            <ListGroup>
                              {car.carfiles.map((cc) => {
                                return (
                                  <>
                                    {cc.isUploaded ? (
                                      <ListGroup.Item className="flex justify-between">
                                        <div>{cc.amDesc}</div>
                                        <div>
                                          {cc.fileType === "application/pdf" ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + cc.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setViewObj(cc);
                                                setShow(true);
                                              }}
                                            />
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                    ) : (
                                      <ListGroup.Item
                                        style={{
                                          backgroundColor: "orange",
                                        }}
                                      >
                                        {cc.amDesc}
                                      </ListGroup.Item>
                                    )}
                                  </>
                                );
                              })}
                            </ListGroup>
                          )}
                        </ListGroup.Item>
                      </>
                    );
                  })}
                </ListGroup>
              </div>
            )}

            {/* collateral car end  */}

            {/* collateral home start  */}
            {targetLoan.collateralhome?.length > 0 && (
              <div className="col-sm-4">
                <ListGroup>
                  <ListGroup.Item active="true"> የቤት መያዣ </ListGroup.Item>
                  {targetLoan.collateralhome.map((home) => {
                    return (
                      <>
                        <ListGroup>
                          <ListGroup.Item>
                            {home.hometype?.amName}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            የካርታ ቁጥር፡ {home.uniquenum}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            አድራሻ፡ ክ/ከተማ-{home.subcity?.amName}, ወረዳ-
                            {home.woreda}, የቤት ቁጥር-{home.houseno}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            የቦታ ስፋት፡ {home.homearea} ካሬ
                          </ListGroup.Item>
                          <ListGroup.Item>
                            የህንጻ ቁጥር፡ {home.bldgno}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            ፍሎር ቁጥር፡ {home.floorno}
                          </ListGroup.Item>
                          <ListGroup.Item>ሰፈር {home.homearea}</ListGroup.Item>
                          <ListGroup.Item>
                            የዋጋ ግምት፡
                            {(
                              home.homearea * home.hometype?.pricepercaremeter
                            ).toLocaleString()}
                          </ListGroup.Item>

                          {home.homegp !== null ? (
                            <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                              <div>{`ባለንብረት: ${home?.homegp?.fullname}`}</div>
                            </ListGroup.Item>
                          ) : (
                            <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                              <div>ባለንብረት</div>
                              <div>ተበዳሪ</div>
                            </ListGroup.Item>
                          )}

                          {/* {Object.keys(home.homegp)?.length > 0 ? (
                            <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                              <div>{`ባለንብረት: ${home?.homegp?.fullname}`}</div>
                            </ListGroup.Item>
                          ) : (
                            <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                              <div>ባለንብረት</div>
                              <div>ተበዳሪ</div>
                            </ListGroup.Item>
                          )} */}
                        </ListGroup>
                        <ListGroup.Item>
                          {home.homefiles.length > 0 && (
                            <ListGroup>
                              {home.homefiles.map((cc) => {
                                return (
                                  <>
                                    {cc.isUploaded ? (
                                      <ListGroup.Item className="flex justify-between">
                                        <div>{cc.amDesc}</div>
                                        <div>
                                          {cc.fileType === "application/pdf" ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + cc.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setViewObj(cc);
                                                setShow(true);
                                              }}
                                            />
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                    ) : (
                                      <ListGroup.Item
                                        style={{
                                          backgroundColor: "orange",
                                        }}
                                      >
                                        {cc.amDesc}
                                      </ListGroup.Item>
                                    )}
                                  </>
                                );
                              })}
                            </ListGroup>
                          )}
                        </ListGroup.Item>
                      </>
                    );
                  })}
                </ListGroup>
              </div>
            )}
            {/* collateral home end  */}
          </div>
        </div>
      </div>
      {/* {data.groups[0] === "ROLE_AUDITOR" && !targetLoan.auditorapproved && (
        <ChatBox />
      )}
      {data.groups[0] === "ROLE_TEAM_LEADER" && !targetLoan.auditorchecking && (
        <ChatBox />
      )} */}
      <ChatBox />
    </div>
  );
}

export default JudgeLoan;
