import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Profile() {
  const { data } = useSelector((store) => store.customer);
  const { serverIP } = useSelector((store) => store.allsettings);
  const [t, i18n] = useTranslation("global");
  const [user, setUser] = useState([]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    axios
      .get(`${serverIP}lpsauth/allusers`)
      .then((res) => {
        // setUser(res.data.filter((x)=> x.id === data.user.id));
        console.log("res.data");
        console.log(res.data);
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div
        className="row"
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="col-sm-4"></div>
        <div className="col-sm-4">
          <ListGroup as="ul">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto"> ስም </div>
              <div>{user.user_name}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">{t("auth.firstname")}</div>
              <div>{user.first_name}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">{t("auth.middlename")}</div>
              <div>{user.last_name}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">{t("auth.lastname")}</div>
              <div>{user.last_name}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">{t("auth.email")}</div>
              <div>{user.email}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">{t("auth.role")}</div>
              {/* <div>{user?.groups[0]}</div> */}
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  );
}

export default Profile;
