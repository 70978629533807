import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import EtCurrency from "../Common/modules/currency";

import ebrima from "./font/ebrima.ttf";
import ebrimabold from "./font/ebrimabd.ttf"; // bold
import ReportStyle from "./Styles/ReportStyle";

import GuaranteeHeader from "./Reusables/GuaranteeHeader";
import GuaranteeStyles from "./Styles/ReportStyle";
import TextBoldUnderlined from "./Reusables/TextBoldUnderlined";
import getEthiopicDate from "../Common/modules/GregorianToEthiopian";
import Trc from "./Reusables/Trc";
import Tr from "./Reusables/Tr";
import NumberToWordDisplay from "./Reusables/NumberToWordDisplay";
import { numberToWords } from "../Common/modules/EnNumToWord";
import ArticleOne from "./Articles/ArticleOne";

const Styles = StyleSheet.create({
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: "center",
    fontFamily: "ebrimabold",
    fontWeight: "bold",
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: "ebrima",
    fontWeight: "bold",
  },
  text_normal: {
    fontSize: 9,
    marginBottom: 3,
    fontFamily: "ebrima",
  },

  rightView: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    fontFamily: "ebrima",
    justifyContent: "flex-end",
  },
});

Font.register({
  family: "ebrima",
  src: ebrima,
});

Font.register({
  family: "ebrimabold",
  src: ebrimabold,
});

function RptSalaryGuarantee({ loan, salary }) {
  const curdate = new Date();
  const etcurrdate =
    getEthiopicDate(
      curdate.getDay(),
      +" " + curdate.getMonth() + 1,
      +" " + curdate.getFullYear()
    ) + " ዓ.ም";

    const toEthiopic = (date) => {
      const [year, month, day] = date.split("-");
      return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
    };
  

  return (
    <PDFViewer style={{ width: "100%", height: 700 }}>
      <Document title="የደመወዝ መያዣ">
        <Page style={GuaranteeStyles.body} wrap>
          <GuaranteeHeader type="የደመወዝ" />

          <View style={{ paddingBottom: 5 }}></View>

          {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ START */}
          <View>
            <Text style={Styles.heading_one}>የውል ሰጪ እና ውል ተቀባይ ኣድራሻ</Text>
          </View>
          <View>
            <View>
              {salary?.salarygp === null ? (
                <>
                  <Text style={Styles.text_normal}>
                    ከዚህ ቀጥሎ "አስያዥ" እየተባሉ የሚጠቀሱት
                    {<TextBoldUnderlined text={loan.customer.amDisplayName} />}
                  </Text>
                  <Text style={Styles.text_normal}>
                    ኣድራሻ ክ/ከተማ &nbsp;
                    {<TextBoldUnderlined text={loan.customer.amSubcity} />}
                    ወረዳ
                    {<TextBoldUnderlined text={loan.customer.woreda} />}
                    የቤት/ቁጥር
                    {<TextBoldUnderlined text={loan.customer.houseNum} />}
                    የመታወቅያ/ቁጥር{" "}
                    {<TextBoldUnderlined text={loan.customer.idNum} />}
                    ስልክ ቁጥር
                    {<TextBoldUnderlined text={loan.customer.mobileNo} />}
                  </Text>
                </>
              ) : (
                <>
                  <Text style={Styles.text_normal}>
                    ከዚህ ቀጥሎ "አስያዥ" እየተባሉ የሚጠቀሱት
                    {<TextBoldUnderlined text={salary?.empAmName} />}
                  </Text>
                  <Text style={Styles.text_normal}>
                    ኣድራሻ ክ/ከተማ
                    {<TextBoldUnderlined text={salary?.subcity?.amName} />}
                    ወረዳ
                    {<TextBoldUnderlined text={salary?.woreda} />}
                    የቤት/ቁጥር
                    {<TextBoldUnderlined text={salary?.housenum} />}
                    የመታወቅያ/ቁጥር {<TextBoldUnderlined text={salary.idNum} />}
                    ስልክ ቁጥር
                    {<TextBoldUnderlined text={salary?.empPhoneNum} />}
                  </Text>
                </>
              )}

              <Text style={Styles.text_normal}>
                ከዚህ ቀጥሎ "ተቋም" እየተባለ የሚጠራው አሚጎስ የቁጠባና ብድር ህብረት ስራ ማህበር(ህስማ)
              </Text>
              <Text style={Styles.text_normal}>
                አድራሻ፦ ፖስታሳ.ቁ፦
                <TextBoldUnderlined text="20732/1000፤አዲስ አበባ" />
                ስልክ ቁጥር፦ <TextBoldUnderlined text="+251-111-2676-57" />
              </Text>
              <Text style={Styles.text_normal}>
                ይህንን የመያዣ ውል ዛሬ {<TextBoldUnderlined text={etcurrdate} />}
                ቀጥሎ በተዘረዘረው ዓይነት ተዋውለዋል፡፡
              </Text>
            </View>
          </View>

             {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ END */}

             <View>
                <Text style={Styles.heading_one}>አንቀጽ 1 ፡ የብድር መጠን ከነ ውሉ </Text>
              </View>
              <ArticleOne loan={loan} />
          {/* አንቀጽ 2 ፡ የብድር ዋስትና መጠን START */}
          <View>
            <Text style={Styles.heading_one}>አንቀጽ 2 ፡ የብድር ዋስትና መጠን </Text>
          </View>
          <View>
            <Text style={Styles.text_normal}>
              አስያዥ እስከ
              <TextBoldUnderlined
                text={(
                  loan.r_totalInterestPayment + loan.r_approvedPrincipal
                )?.toLocaleString()}
              />
              <NumberToWordDisplay
                text={numberToWords(
                  loan.r_totalInterestPayment + loan.r_approvedPrincipal
                )}
              />
              ለሚደርስ ተበዳሪ ላልከፈሉት ብድር ለወለድና ለማንኛውም ወጪና ኪሳራ ማስከፈያ ከዚህ ቀጥሎ ባለው ሰንጠረዥ
              ውስጥ የተጠቀሱት ንብረቶች በመያዣነት ለተቋሙ ሰጥተዋል፡፡
            </Text>
          </View>

          <View>
            <Text style={{ ...Styles.heading_two }}>
              2.2. በአስያዥ የቀረቡት ዋስትናዎች
            </Text>
          </View>

          {/* አንቀጽ 2 ፡ የብድር ዋስትና መጠን END  */}

          <View style={{ backgroundColor: "orange" }}>
            <Text style={{ ...Styles.heading_two, textAlign: "center" }}>
              አጠቃላይ ለብድሩ የተያዙ ዋስትናዎች
            </Text>
          </View>

          <View>
            <Text style={Styles.heading_two}>
              2.2.1. የተበዳሪው አክስዮን ፣ በተቋሙ ውስጥ ያለ ቁጠባና ወለድ
            </Text>

            <View style={{ display: "flex", flexDirection: "row" }}>
              <Trc borders="t1r1b1l1" val="ስም ከነ አያት" width="25%" />
              <Trc borders="t1r1b1l1" val="መደባኛ ቁጠባ" width="20%" />
              <Trc borders="t1r1b1l1" val="የአክስዮን ቁጠባ" width="20%" />
              <Trc borders="t1r1b1l1" val="የፍቃደኝነት ቁጠባ" width="15%" />
              <Trc borders="t1r1b1l1" val="ድምር መጠን" width="20%" />
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Trc
                borders="t1r1b1l1"
                val={loan.customer.amDisplayName}
                width="25%"
              />
              <Trc borders="t1r1b1l1" val={loan.totalSaving} width="20%" />
              <Trc borders="t1r1b1l1" val={loan.totalShares} width="20%" />
              <Trc borders="t1r1b1l1" val="val" width="15%" />
              <Trc
                borders="t1r1b1l1"
                val={loan.totalSaving + loan.totalShares}
                width="20%"
              />
            </View>
          </View>
          <View>
            <Text style={Styles.heading_two}>2.2.2. ተጨማሪ ዋስትናዎች</Text>
          </View>

          {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Tr
              style={ReportStyle.h5b}
              thekey=" ለብድር ዋስትና የቀረቡ ዋስትናዎች  "
              width="25%"
              borders="t1r1b1l1"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የዋስትና አቅራቢ ስም "
              width="23%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የብር መጠን "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" ክ/ከተማ "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" ወረዳ "
              width="13%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" የመ/ቁጥር "
              width="13%"
              borders="t1r1b1l0"
            />
          </View>

          {/* Employee Salary */}
          {loan.collateralemployee?.length > 0 &&
            loan.collateralemployee.map((employee) => {
              return (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Tr thekey=" ደመወዝ " width="25%" borders="t0r1b1l1" />
                  <Tr
                    thekey={employee.empAmName}
                    width="23%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={employee.grossSalary * 3.6}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                  <Tr thekey={employee.subcity?.amName} width="13%" borders="t0r1b1l0" />
                  <Tr thekey={employee.woreda} width="13%" borders="t0r1b1l0" />
                  <Tr thekey={employee.idNum} width="13%" borders="t0r1b1l0" />
                </View>
              );
            })}

          {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}

          {loan.customer?.isMarried &&
            loan.customer?.spausedetail?.length > 0 && (
              <>
                <View>
                  <Text style={Styles.heading_two}>
                    2.2.3. የዋስ ባለቤት ፈቃድ በተመለከተ
                  </Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}>
                    የተበዳሪ ባለቤት (spause) የሆኑት
                    {
                      <TextBoldUnderlined
                        text={loan.customer?.spausedetail[0]?.amFullName}
                      />
                    }
                    &nbsp; ስልክ ቁጥር
                    {
                      <TextBoldUnderlined
                        text={loan.customer?.spausedetail[0]?.mobileNo}
                      />
                    }
                  </Text>
                  <Text style={Styles.text_normal}>
                    ኣድራሻ ክ/ከተማ
                    {
                      <TextBoldUnderlined
                        text={loan.customer?.spausedetail[0]?.amSubcity}
                      />
                    }
                    &nbsp; ወረዳ
                    {
                      <TextBoldUnderlined
                        text={loan.customer?.spausedetail[0]?.woreda}
                      />
                    }
                    &nbsp; የቤት/ቁጥር
                    {
                      <TextBoldUnderlined
                        text={loan.customer?.spausedetail[0]?.houseNum}
                      />
                    }
                    &nbsp; የመታወቅያ/ቁጥር {<TextBoldUnderlined text="የመቁ" />}
                  </Text>
                </View>
              </>
            )}

          {/* footer start  */}

          <View style={{ paddingTop: 10 }}>
            <Text style={Styles.text_normal}>
              በዚህ ውል የተጠቀሰዉን የተበዳሪ ግዴታ ሁሉ ወደውና ተስማምተው መቀበላቸውን በፊርማቸው አረጋግጠዋል፡፡
              ይህ ውል ከላይ በተጠቀሰው ቀንና ዓ.ም ስማቸው ከታች የተጠቀሰው ምስክሮች ባሉበት ተፈርሟል፡፡
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text style={Styles.text_normal}> ________________ </Text>
              <Text style={Styles.text_normal}> የተቋሙ ውል ሰጪ ስምና ፊርማ </Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> ________________ </Text>
              <Text style={Styles.text_normal}> የዋስ ስምና ፊርማ </Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> ________________ </Text>
              <Text style={Styles.text_normal}> የዋስ ባለቤት ስምና ፊርማ </Text>
            </View>
          </View>

          <View style={{ padding: 10 }}>
            <Text style={Styles.text_normal}>
              አንድ ቅጂ ውስጃልው &nbsp;{" "}
              <TextBoldUnderlined text={loan?.customer.amDisplayName} />
              &nbsp; ፊርማ፡ ___________________
            </Text>
          </View>

          <View>
            <Text style={Styles.text_normal}>ምስክሮች</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text style={Styles.text_normal}> ቃለወንጌል አድማሱ </Text>
              <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> አላዛር ካሳ ሀይሌ </Text>
              <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> ሰላማዊት ጻዲቅ </Text>
              <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
            </View>
          </View>
          <View
            style={{
              textAlign: "right",
              position: "absolute",
              bottom: 30,
              right: 35,
              fontSize: 10,
            }}
          >
            <Text style={Styles.text_normal}>
              ውሉ ያዘጋጀው፦
              <TextBoldUnderlined text="ቃልኪዳን ደረጄ" />{" "}
            </Text>
            <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
          </View>
          {/* footer End  */}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptSalaryGuarantee;
