import React from "react";
import { Text, View } from "@react-pdf/renderer";
import ReportStyle from "../Styles/ReportStyle";
import ebrimanormal from "../font/ebrima.ttf";
import ebrimabold from "../font/ebrimabd.ttf";

function Tr(props) {
  return (
    <View
      style={{
        backgroundColor: props.bgcolor,
        width: props.width,
      }}
    >
      <View
        style={{
          ...props.style,
          borderTopWidth: props.borders[1],
          borderBottomWidth: props.borders[3],
          borderRightWidth: props.borders[5],
          borderLeftWidth: props.borders[7],
          display: "flex",
          flexDirection: "row",
          paddingLeft: 5,
        }}
      >
        <Text style={ReportStyle.h5}>{props.thekey}</Text>
        <Text style={ReportStyle.h5b}>{props.val}</Text>
      </View>
    </View>
  );
}

export default Tr;
