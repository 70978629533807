import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CarCollateralSchema from "../Schema/CarCollateralSchema";
import axios from "axios";

import {
  FaCarAlt,
  FaFileAlt,
  FaPencilAlt,
  FaExchangeAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { Alert, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setTargetLoan } from "../Common/redux/systemLookups";
import TextInputTw from "../ReusableFormiks/TextInputTw";

function CarCollateral({ successnotify, errornotify }) {
  const { data } = useSelector((store) => store.customer);
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan } = useSelector((store) => store.systemLookups);
  
  const dispatch = useDispatch();
  const { loanId } = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [gpModal, setGpModal] = useState(false);
  const [gpid, setGpid] = useState(null);

  const [carModels, setCarModels] = useState([]);
  const [carManufactureYear, setCarManufactureYear] = useState([]);
  const [subcities, setsubcities] = useState([]);
  const [targetCar, setTargetCar] = useState([]);

  const [chassisNumber, setchassisNumber] = useState("");
  const [engineNumber, setengineNumber] = useState("");
  const [librebookid, setlibrebookid] = useState("");
  const [carPlate, setcarPlate] = useState("");
  const [carCC, setcarCC] = useState("");
  const [insuranceValue, setinsuranceValue] = useState("");

  const [manufacturedYear, setmanufacturedYear] = useState("");
  const [loan, setloan] = useState("");
  const [checkListId, setcheckListId] = useState("");
  const [model, setmodel] = useState("");
  const [subcity, setsubcity] = useState("");
  const [serialnum, setserialnum] = useState("");

  const [sunctionedAt, setsunctionedAt] = useState("");
  const [manufacturedCountry, setmanufacturedCountry] = useState(0);
  const [ddlcoutry, setsddlcoutry] = useState([]);

  useEffect(() => {
    getCarModels();
    getCarManufactureYears();
    getsubcities();
    getManufacturedCountry();
  }, []);

  const getManufacturedCountry = () => [
    axios
      .get(`${serverIP}/ddlcountry/ddlcountries`)
      .then((res) => {
        setsddlcoutry(res.data);
      })
      .catch((err) => {
        console.log(err);
      }),
  ];

  const getsubcities = () => [
    axios
      .get(`${serverIP}/subcity/subcitys`)
      .then((res) => {
        setsubcities(res.data);
      })
      .catch((err) => {
        console.log(err);
      }),
  ];

  const getCarModels = () => {
    axios
      .get(`${serverIP}/car_model/cars`)
      .then((res) => {
        console.log(res.data);
        setCarModels(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCarManufactureYears = () => {
    axios
      .get(`${serverIP}/car_manufacturer/carmanufacture`)
      .then((res) => {
        console.log(res.data);
        setCarManufactureYear(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveCarCollateral = (values, actions) => {
    console.log(values);
    axios
      .post(`${serverIP}/collateral_car/collateralcar`, {
        manufacturedYear: values.manufacturedYear,
        chassisNumber: values.chassisNumber,
        engineNumber: values.engineNumber,
        librebookid: values.librebookid,
        carPlate: values.carPlate,
        carCC: values.carCC,
        loan: loanId,
        manufacturedCountry: values.checkListId,
        model: values.model,
        insuranceValue: values.insuranceValue,
        subcity: values.subcity,
        sunctionedAt: values.sunctionedAt,
        serialnum: values.serialnum,
        manufacturedCountry: values.manufacturedCountry,
      })
      .then((res) => {
        successnotify();
        setShowAddModal(false);
        // getCustomer();
        getLoan();
      })
      .catch((err) => {
        errornotify();
      });
    getLoan();
  };

  const editCar = () => {
    axios
      .patch(`${serverIP}/collateral_car/collateralcar/${targetCar.id}`, {
        manufacturedYear: manufacturedYear,
        chassisNumber: chassisNumber,
        engineNumber: engineNumber,
        librebookid: librebookid,
        carPlate: carPlate,
        carCC: carCC,
        loan: loan,
        manufacturedCountry: checkListId,
        model: model,
        insuranceValue: insuranceValue,
        subcity: subcity,
        sunctionedAt: sunctionedAt,
        serialnum: serialnum,
      })
      .then((res) => {
        console.log(res.data);
        getLoan();
        setShowEditModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getLoan();
  };

  const deleteCar = () => {
    axios
      .delete(`${serverIP}/collateral_car/collateralcar/${targetCar.id}`)
      .then((res) => {
        console.log(res.data);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeGP = () => {
    axios
      .patch(`${serverIP}/collateral_car/collateralcar/${targetCar.id}`, {
        cargp: gpid,
      })
      .then((res) => {
        successnotify();
        setGpModal(false);

        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoan = async () => {
    try {
      const res = await axios.get(`${serverIP}/loan/loans/${targetLoan.id}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  const handleNormalize = (value) => {
    return value.toUpperCase();
  };

  return (
    <div>
      <FaCarAlt
        style={{
          position: "absolute",
          zIndex: 0,
          backgroundRepeat: "repeat-x",
          width: "50%",
          height: "50%",
          paddingLeft: "25%",
          opacity: 0.03,
        }}
      />
      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: "bold", color: "GrayText" }}>
          የመኪና መያዣ
        </div>
        {data.groups[0] === "ROLE_OFFICER" && (
          <Button
            onClick={() => setShowAddModal(true)}
            className="flex flex-row items-center gap-2"
            disabled={targetLoan.teamleaderchecking}
          >
            <FaCarAlt /> ጨምር
          </Button>
        )}
      </Alert>
      {/* change guarantee person modal Start  */}
      <Modal show={gpModal} onHide={() => setGpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ዋስ ግለሰብ ቀይር </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className="form-select"
            onChange={(e) => {
              setGpid(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option value="null" selected>
              ተበዳሪ
            </option>
            {targetLoan.gp?.length > 0 &&
              targetLoan.gp.map((gp) => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.fullname}
                  </option>
                );
              })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" onClick={() => changeGP()}>
            Change
          </Button>
        </Modal.Footer>
      </Modal>
      {/* change guarantee person modal End  */}

      {/* add modal Start  */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> አዲስ መኪና መያዣ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            manufacturedYear: "",
            chassisNumber: "",
            engineNumber: "",
            librebookid: "",
            carPlate: "",
            carCC: "",
            loan: loanId,
            manufacturedCountry: "",
            model: "",
            insuranceValue: "",
            subcity: "",
            sunctionedAt: "",
            serialnum: "",
          }}
          validationSchema={CarCollateralSchema}
          onSubmit={saveCarCollateral}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <label>የመኪና ሞዴል</label>
                  <Field
                    as="select"
                    name="model"
                    className="form-control"
                    style={{
                      border: errors.model ? "1px solid red" : "",
                    }}
                  >
                    <option value="0"> የመኪና ሞዴል ይምረጡ </option>
                    {carModels.map((model) => {
                      return (
                        <option key={model.id} value={model.id}>
                          {model.enName}
                        </option>
                      );
                    })}
                  </Field>

                  <ErrorMessage
                    style={{ color: "red" }}
                    name="model"
                    component="div"
                  />
                  <label> የተመረተበት ዘመን</label>
                  <Field
                    as="select"
                    name="manufacturedYear"
                    className="form-control"
                    style={{
                      border: errors.manufacturedYear ? "1px solid red" : "",
                    }}
                  >
                    <option value="0"> የተመረተበት ዘመን ይምረጡ </option>
                    {carManufactureYear.map((manufactureYear) => {
                      return (
                        <option
                          key={manufactureYear.id}
                          value={manufactureYear.id}
                        >
                          {manufactureYear.yearRange}
                        </option>
                      );
                    })}
                  </Field>

                  <ErrorMessage
                    style={{ color: "red" }}
                    name="manufacturedYear"
                    component="div"
                  />

                  <label>የተመረተበት አገር</label>
                  <Field
                    as="select"
                    name="manufacturedCountry"
                    className="form-control"
                    style={{
                      border: errors.manufacturedCountry ? "1px solid red" : "",
                    }}
                  >
                    <option value="0"> የተመረተበት አገር ይምረጡ </option>
                    {ddlcoutry.map((ctry) => {
                      return (
                        <option key={ctry.id} value={ctry.id}>
                          {ctry.amName}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="manufacturedCountry"
                    component="div"
                  />

                  <label> ሊብሬው የተሰጠበት ክ/ከተማ </label>
                  <Field
                    as="select"
                    name="subcity"
                    className="form-control"
                    style={{
                      border: errors.subcity ? "1px solid red" : "",
                    }}
                  >
                    <option value="0"> ሊብሬው የተሰጠበት ክ/ከተማ </option>
                    {subcities.map((subc) => {
                      return (
                        <option key={subc.id} value={subc.id}>
                          {subc.amName}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="subcity"
                    component="div"
                  />

                  <label> የሊብሬ ቁጥር </label>
                  <Field
                    className="form-control"
                    placeholder="librebookid"
                    type="text"
                    name="librebookid"
                    style={{
                      border: errors.librebookid ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="librebookid"
                    component="div"
                  />

                  <label>የቻሲስ ቁጥር </label>
                  <Field
                    className="form-control"
                    placeholder="chassisNumber"
                    type="text"
                    name="chassisNumber"
                    normalize={handleNormalize}
                    oninput="this.value = this.value.toUpperCase()"
                    style={{
                      border: errors.chassisNumber ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="chassisNumber"
                    component="div"
                  />

                  <label> የኢንጅን ቁጥር </label>
                  <Field
                    className="form-control"
                    placeholder="engineNumber"
                    type="text"
                    name="engineNumber"
                    style={{
                      border: errors.engineNumber ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="engineNumber"
                    component="div"
                  />

                  <label>ታርጋ ቁጥር </label>
                  <Field
                    className="form-control"
                    placeholder="carPlate"
                    type="text"
                    name="carPlate"
                    style={{
                      border: errors.carPlate ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="carPlate"
                    component="div"
                  />

                  <label> የኢንሹራንስ ግምት </label>
                  <Field
                    className="form-control"
                    placeholder="የኢንሹራንስ ግምት"
                    type="number"
                    name="insuranceValue"
                    style={{
                      border: errors.insuranceValue ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="insuranceValue"
                    component="div"
                  />

                  <label> የመኪና CC </label>
                  <Field
                    className="form-control"
                    placeholder="carCC"
                    type="number"
                    name="carCC"
                    style={{
                      border: errors.carCC ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="carCC"
                    component="div"
                  />

                  <label> ሴሪያል ቁጥር </label>
                  <Field
                    className="form-control"
                    placeholder="ሴሪ ቁጥር"
                    type="number"
                    name="serialnum"
                    style={{
                      border: errors.serialnum ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="serialnum"
                    component="div"
                  />

                  <label> እግድ የተሰጠበት ቀን </label>
                  <Field
                    className="form-control"
                    placeholder="sunctionedAt"
                    type="date"
                    name="sunctionedAt"
                    style={{
                      border: errors.sunctionedAt ? "1px solid red" : "",
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "red" }}
                    name="sunctionedAt"
                    component="div"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* add modal End  */}

      {/* edit modal Start  */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>የመኪና መረጃ አስተካክል</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            onChange={(e) => setmodel(e.target.value)}
            className="form-control"
          >
            <option value="0"> የመኪና ሞዴል ይምረጡ </option>
            {carModels.map((model) => {
              return (
                <option
                  key={model.id}
                  selected={model.id === targetCar?.model?.id}
                  value={model.id}
                >
                  {model.enName}
                </option>
              );
            })}
          </select>
          <div className="py-2">
            <select
              onChange={(e) => setmanufacturedYear(e.target.value)}
              className="form-control py-2"
            >
              <option value="0"> የተመረተበት ዘመን ይምረጡ </option>
              {carManufactureYear.map((manufactureYear) => {
                return (
                  <option
                    selected={
                      manufactureYear.id === targetCar?.manufacturedYear?.id
                    }
                    key={manufactureYear.id}
                    value={manufactureYear.id}
                  >
                    {manufactureYear.yearRange}
                  </option>
                );
              })}
            </select>
          </div>
          <select
            onChange={(e) => setcheckListId(e.target.value)}
            className="form-control"
          >
            <option value="0"> የተመረተበት አገር ይምረጡ </option>
            {ddlcoutry.map((ctry) => {
              return (
                <option
                  selected={ctry.id === targetCar?.checkListId?.id}
                  key={ctry.id}
                  value={ctry.id}
                >
                  {ctry.amName}
                </option>
              );
            })}
          </select>
          <div className="py-2">
            <select
              onChange={(e) => setsubcity(e.target.value)}
              className="form-control"
            >
              <option value="0"> ሊብሬው የተሰጠበት ክ/ከተማ </option>
              {subcities.map((subc) => {
                return (
                  <option
                    selected={subc.id === targetCar?.subcity?.id}
                    key={subc.id}
                    value={subc.id}
                  >
                    {subc.amName}
                  </option>
                );
              })}
            </select>
          </div>

          <TextInputTw
            placeholder="የሊብሬ ቁጥር"
            onChange={(e) => setlibrebookid(e.target.value)}
            value={librebookid}
          />
          <TextInputTw
            placeholder="የቻሲስ ቁጥር"
            onChange={(e) => setchassisNumber(e.target.value)}
            value={chassisNumber}
          />
          <TextInputTw
            placeholder="የኢንጅን ቁጥር"
            onChange={(e) => setengineNumber(e.target.value)}
            value={engineNumber}
          />
          <TextInputTw
            placeholder="ታርጋ ቁጥር"
            onChange={(e) => setcarPlate(e.target.value)}
            value={carPlate}
          />
          <TextInputTw
            placeholder="የኢንሹራንስ ግምት"
            onChange={(e) => setinsuranceValue(e.target.value)}
            value={insuranceValue}
          />
          <TextInputTw
            placeholder="የመኪና CC"
            onChange={(e) => setcarCC(e.target.value)}
            value={carCC}
          />

          <TextInputTw
            placeholder="ሴርያል ቁጥር"
            onChange={(e) => setserialnum(e.target.value)}
            value={serialnum}
          />

          <label className="w-full py-1">
            <div className="label">
              <span className="label-text"> እግድ የተሰጠበት ቀን </span>
            </div>
            <input
              type="date"
              value={sunctionedAt}
              onChange={(e) => setsunctionedAt(e.target.value)}
              className="input input-bordered w-full form-control"
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" onClick={() => editCar()}>
            አስተካከል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit modal End  */}

      {/* delete modal Start  */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> በመያዣነት የቀረበው መኪና አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>!</Modal.Body>
        <Modal.Footer>
          <Button variant="danger btn-sm" onClick={() => deleteCar()}>
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete modal End  */}

      {Object.keys(targetLoan?.collateralcar)?.length > 0 && (
        <Table striped bordered hover style={{ marginTop: 10 }}>
          <thead>
            <tr>
              <th>Chasis-Engine-Plate</th>
              <th>Insurance</th>
              <th>Model</th>
              {data.groups[0] === "ROLE_OFFICER" ? <th>Action</th> : <></>}
              <th>Files</th>
              <th>Status</th>
              <th>Owned By</th>
            </tr>
          </thead>
          <tbody>
            {targetLoan.collateralcar?.map((car) => {
              return (
                <tr>
                  <td>
                    {car.chassisNumber +
                      " - " +
                      car.engineNumber +
                      " - " +
                      car.carPlate}
                  </td>
                  <td> {car.insuranceValue?.toLocaleString()} </td>
                  <td>
                    {car.model?.enName +
                      " - " +
                      car.manufacturedCountry?.amName}{" "}
                  </td>
                  {data.groups[0] === "ROLE_OFFICER" ? (
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FaTrashAlt
                        onClick={() => {
                          setShowDeleteModal(true);
                          setTargetCar(car);
                        }}
                        color="red"
                      />
                      &nbsp;
                      <FaPencilAlt
                        onClick={() => {
                          setShowEditModal(true);
                          setTargetCar(car);

                          setchassisNumber(car.chassisNumber);
                          setengineNumber(car.engineNumber);
                          setlibrebookid(car.librebookid);
                          setcarPlate(car.carPlate);
                          setcarCC(car.carCC);
                          setinsuranceValue(car.insuranceValue);

                          setmanufacturedYear(car.manufacturedYear.id);
                          setloan(car.loan?.id);
                          setmodel(car.model?.id);
                          setsubcity(car.subcity?.id);
                          setmanufacturedCountry(car.manufacturedCountry?.id);

                          setsunctionedAt(car.sunctionedAt);
                          setserialnum(car.serialnum);
                        }}
                        color="orange"
                      />
                    </td>
                  ) : (
                    <></>
                  )}
                  <td>
                    <span style={{ color: "orange" }}>
                      <Link
                        to={`/carfiles/${targetLoan.id}/${car.id}`}
                        className="flex flex-row items-center gap-2"
                      >
                        <Badge>
                          {
                            car.carfiles?.filter((x) => x.isUploaded === true)
                              ?.length
                          }
                          / {car.carfiles?.length}
                        </Badge>
                        <FaFileAlt />
                      </Link>
                    </span>
                  </td>
                  <td>{car.isApproved ? "Approved" : "Waiting"}</td>
                  <td>
                    <div className="flex flex-row gap-3 justify-center items-center">
                      {car.cargp !== null ? car.cargp?.fullname : "የተበዳሪ"}
                      {data.groups[0] === "ROLE_OFFICER" ? (
                        <span>
                          <FaExchangeAlt
                            color="orange"
                            onClick={() => {
                              setGpModal(true);
                              setTargetCar(car);
                            }}
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            {/*  */}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default CarCollateral;
