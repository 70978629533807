import { data } from "autoprefixer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, ListGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WithdrawForm from "../Role_Planner/Reusables/WithdrawForm";

function AuditorChecking() {
  const { withdrawId } = useParams();
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);

  const [theWithdraw, settheWithdraw] = useState({});
  const [show, setShow] = useState(false);
  const [withdrawAmount, setwithdrawAmount] = useState(0);

  useEffect(() => {
    getWithdrawing(withdrawId);
  }, []);

  const getWithdrawing = (withdrawId) => {
    console.log(withdrawId);
    axios
      .get(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`)
      .then((res) => {
        settheWithdraw(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendToFinance = () => {
    axios
      .patch(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`, {
        toFinance: true,
        auditorid: data.id,
      })
      .then((res) => {
        console.log(res.data);
        getWithdrawing(withdrawId);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  return (
    <div className="container">
      {/* Modal Start  */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ወደ ኦዲት ክፍል ሃላፊ ላክ </Modal.Title>
        </Modal.Header>
        <Modal.Body>ወደ ኦዲት ክፍል ሃላፊ መላክ ይፈልጋሉ? </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendToFinance}>
            ላክ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal End  */}

      <div className="row">
        {/* {JSON.stringify(theWithdraw)} */}
        <div className="col-sm-8">
          <WithdrawForm theWithdraw={theWithdraw} />
        </div>
        <div className="col-sm-4">
          <Card className="mt-4">
            <Card.Header>Withdrawal Request Detail</Card.Header>
            <Card.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item>
                  የተፈቀደው የብር መጠን፡
                  <b>{theWithdraw.withdraw_amount?.toLocaleString() + " ብር"}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የቀረው ቀን፡
                  {/* <b>
                    {theWithdraw.date_difference
                      ? theWithdraw.date_difference + " ቀናቶች"
                      : ""}
                  </b> */}
                  <b>
                    {getDateDifferenceInDays(theWithdraw.appointmentDate) +
                      "  ቀን ቀረው"}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የስራክፍሉ ኦፊሰር :
                  <b>
                    {theWithdraw?.plannedby
                      ? theWithdraw?.plannedby?.first_name +
                        " " +
                        theWithdraw?.plannedby?.last_name
                      : ""}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የአባላት አስተዳደር ሀላፊ :
                  <b>
                    {theWithdraw?.evaluatedby !== null &&
                      theWithdraw?.evaluatedby?.first_name +
                        " " +
                        theWithdraw?.evaluatedby?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የኦዲት ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.auditorid !== null &&
                      theWithdraw?.auditorid?.first_name +
                        " " +
                        theWithdraw?.auditorid?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የፋይናንስ ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.financePerson !== null &&
                      theWithdraw?.financePerson?.first_name +
                        " " +
                        theWithdraw?.financePerson?.last_name}
                  </b>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          <div className="pt-2">
            {theWithdraw.toFinance ? (
              <Alert>ወደ ፋይናንስ ክፍል ሃላፊ ተልኳል </Alert>
            ) : (
              <Button onClick={() => setShow(true)}>
                {" "}
                ወደ ፋይናንስ ክፍል ሃላፊ ላክ{" "}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditorChecking;
