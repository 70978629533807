import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setTargetWithdraw } from "../Common/redux/systemLookups";
import { Card } from "react-bootstrap";

function LpsWithdraws() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    getAllWithdrawsInLps();
  }, []);

  const getAllWithdrawsInLps = async () => {
    const response = await axios.get(
      `${serverIP}/withdrawcustomers/withdrawcustomers`
    );
    console.log(response.data);
    setWithdrawals(response.data);
  };

  const redirectPage = async (customerId) => {
    try {
      await getCustomerWithdrawalPlans(customerId);
      navigate(`/withdrawtab/${customerId}`);
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  const getCustomerWithdrawalPlans = async (customerId) => {
    try {
      const res = await axios.get(
        `${serverIP}/withdrawals/withcustomerId/${customerId}`
      );
      dispatch(setTargetWithdraw({ withdraw: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  return (
    <Card className="mt-5">
      <Card.Header>የቆዩ</Card.Header>
      <Card.Body>
        {withdrawals.length > 0 && (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Mobile</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.map((withdrawal) => (
                <tr key={withdrawal.id}>
                  <td>{withdrawal.amDisplayName}</td>
                  <td>{withdrawal.mobileNo}</td>
                  <td></td>
                  <td>
                    <Link
                      onClick={() => {
                        redirectPage(withdrawal.id);
                      }}
                      className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                    >
                      Withdraw
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Card.Body>
    </Card>
  );
}

export default LpsWithdraws;
