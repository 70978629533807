import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Table, Modal } from "react-bootstrap";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import WoredaSchema from "../../Schema/Lookups/WoredaSchema";
import TextFormikInput from "../../ReusableFormiks/TextFormikInput";

function Woreda() {
  const [t, i18n] = useTranslation("global");
  const { serverIP } = useSelector((store) => store.allsettings);
  const { chktypeid } = useParams();

  const [subcity, setSubcity] = useState([]);
  const [woredas, serWoredas] = useState([]);

  const [name, setName] = useState("");
  const [amName, setAmName] = useState("");

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [targetObj, setTargetObj] = useState({});

  useEffect(() => {
    getWoredas();
    getSubcities();
  }, []);

  const getWoredas = () => {
    axios
      .get(`${serverIP}/woreda/woredas`)
      .then(function (response) {
        console.log(response);
        serWoredas(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSubcities = () => {
    axios
      .get(`${serverIP}/subcity/subcitys`)
      .then(function (response) {
        setSubcity(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveWoreda = (values) => {
    console.log(values);
    axios
      .post(`${serverIP}/woreda/woredas`, {
        enName: values.enName,
        amName: values.amName,
        parent: values.parent,
      })
      .then((res) => {
        console.log(res.data);
        getWoredas();
        setModalAdd(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editWoreda = () => {
    axios
      .patch(`${serverIP}/woreda/woredas/${targetObj.id}`, {
        enName: name,
        amName: amName,
      })
      .then((res) => {
        console.log(res.data);
        getWoredas();
        setModalEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteCheckList = () => {
    axios
      .delete(`${serverIP}/woreda/woredas/${targetObj.id}`)
      .then((res) => {
        console.log(res.data);
        getWoredas();
        setModalDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>ወረዳ</div>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            enName: "",
            amName: "",
            parent: "",
          }}
          validationSchema={WoredaSchema}
          onSubmit={saveWoreda}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <label>ክፍለ ከተማ</label>
                  <Field
                    as="select"
                    name="parent"
                    className="form-control"
                    style={{
                      border: errors.parent ? "1px solid red" : "",
                    }}
                  >
                    <option value="">ክፍለ ከተማ ይምረጡ </option>
                    {subcity.map((sc) => {
                      return (
                        <option key={sc.id} value={sc.id}>
                          {sc.amName}
                        </option>
                      );
                    })}
                  </Field>
                  <TextFormikInput name="amName" placeholder="የክ/ከተማ ስም." />
                  <TextFormikInput name="enName" placeholder="Subcity Name" />
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}>
            {t("officer.edit")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="flex flex-col gap-2">
          <input
            type="text"
            placeholder="የክ/ከተማ ስም."
            className="form-control"
            onChange={(e) => setAmName(e.target.value)}
            value={amName}
          />
          <input
            type="text"
            placeholder="Subcity Name"
            className="form-control"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editWoreda()}>
            {t("officer.edit")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> {t("officer.delete")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("admin.confirmDelete")} <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteCheckList()}>
            {t("officer.delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modals End  */}

      <Alert variant="info">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
          }}
        >
          <div> ወረዳ ማስተካከያ </div>
          <div>
            <Button
              variant="primary"
              className="btn-sm"
              onClick={() => setModalAdd(true)}
            >
              {t("officer.add")}
            </Button>
          </div>
        </div>
      </Alert>
      {woredas.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ስም </th>
              <th> Name </th>
              <th>{t("auditor.action")}</th>
            </tr>
          </thead>
          <tbody>
            {woredas.map((chk) => {
              return (
                <tr>
                  <td>{chk.id}</td>
                  <td>{chk.amName}</td>
                  <td>{chk.enName}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <FaTrashAlt
                        color="red"
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(chk);
                        }}
                      />
                      &nbsp;
                      <FaPencilAlt
                        color="orange"
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(chk);
                          setName(chk.enName);
                          setAmName(chk.amName);
                        }}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Woreda;
