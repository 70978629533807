import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ebrimabold from "../font/ebrimabd.ttf";

function TextBold(props) {
  const Styles = StyleSheet.create({
    dynamicText: {
      fontFamily: "ebrimabold",
      // color:  "green",
      color: props.color ? "black" : "green",
    },
  });

  return <Text style={Styles.dynamicText}>{props.text}</Text>;
}

export default TextBold;
