import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTargetClient } from "../Common/redux/systemLookups";

function CustmerComp({ targetLoan, targetClient, successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const [amFirstname, setamFirstname] = useState("");
  const [amMiddlename, setamMiddlename] = useState("");
  const [amLastname, setamLastname] = useState("");
  const [amAddress, setamAddress] = useState("");
  const [houseNum, sethouseNum] = useState("");
  const [amSubcity, setamSubcity] = useState("");
  const [woreda, setworeda] = useState("");
  const [monthlyIncome, setmonthlyIncome] = useState("");
  const [isMarried, setisMarried] = useState("");
  const [characterEvaluation, setCharacterEvaluation] = useState(0);

  const [phoneNum, setphoneNum] = useState("");
  const [idNum, setidNum] = useState("");
  const [memberSince, setmemberSince] = useState("");
  const [monthlySaving, setmonthlySaving] = useState("");
  const [loanType, setloanType] = useState("");

  const [lntypes, setlntypes] = useState([]);
  const [subcities, setsubcities] = useState([]);

  useEffect(() => {
    getLocalCustomer();
    getLoanTypes();
    getsubcities();
  }, []);

  const getsubcities = () => [
    axios
      .get(`${serverIP}/subcity/subcitys`)
      .then((res) => {
        setsubcities(res.data);
      })
      .catch((err) => {
        console.log(err);
      }),
  ];

  const getLoanTypes = () => {
    axios
      .get(`${serverIP}/loantype/loantypes`)
      .then((res) => {
        setlntypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocalCustomer = () => {
    axios
      .get(`${serverIP}/customer/customers/${customerId}`)
      .then((res) => {
        setamFirstname(res.data.amFirstname);
        setamMiddlename(res.data.amMiddlename);
        setamLastname(res.data.amLastname);

        setamAddress(res.data.amAddress);
        sethouseNum(res.data.houseNum);
        setamSubcity(res.data.amSubcity);

        setworeda(res.data.woreda);
        setmonthlyIncome(res.data.monthlyIncome);
        setisMarried(res.data.isMarried);

        setphoneNum(res.data.mobileNo);
        setidNum(res.data.idNum);
        setmemberSince(res.data.memberSince);
        setmonthlySaving(res.data.monthlySaving);
        setloanType(res.data.loanType);
        setCharacterEvaluation(res.data.characterEvaluation);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCustomerRecord = () => {
    console.log("update customer record");

    axios
      .patch(`${serverIP}/customer/customers/${targetClient.id}`, {
        amDisplayName: amFirstname + " " + amMiddlename + " " + amLastname,
        amFirstname: amFirstname,
        amMiddlename: amMiddlename,
        amLastname: amLastname,
        amAddress: amAddress,
        amSubcity: amSubcity,
        woreda: woreda,
        houseNum: houseNum,
        isMarried: isMarried,
        monthlyIncome: monthlyIncome,
        mobileNo: phoneNum,
        idNum: idNum,
        memberSince: memberSince,
        monthlySaving: monthlySaving,
        loanType: loanType,
        characterEvaluation: characterEvaluation,
      })
      .then((res) => {
        console.log("success notfy");
        successnotify("የደንበኛ መረጃ ተስተካክሏል");
        getCustomer();
      })
      .catch((err) => {
        errornotify("");
      });
  };

  const getCustomer = async () => {
    try {
      const res = await axios.get(
        `${serverIP}/customer/customers/${targetClient.id}`
      );
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error("Error fetching customer:", err);
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-sm-4">
              <label className="form-label">ስም</label>
              <input
                value={amFirstname}
                type="text"
                className="form-control"
                onChange={(e) => setamFirstname(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
              <label className="form-label">የአባት ስም</label>
              <input
                type="text"
                className="form-control"
                value={amMiddlename}
                onChange={(e) => setamMiddlename(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">የአያት ስም</label>
              <input
                type="text"
                className="form-control"
                value={amLastname}
                onChange={(e) => setamLastname(e.target.value)}
              />
            </div>

            {/* new comment start  */}

            <div className="col-sm-4">
              <label className="form-label">ስልክ ቁጥር</label>
              <input
                type="text"
                className="form-control"
                value={phoneNum}
                onChange={(e) => setphoneNum(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">የመታወቅያ ቁጥር</label>
              <input
                type="text"
                className="form-control"
                value={idNum}
                onChange={(e) => setidNum(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">አባል የሆኑበት ጊዜ</label>
              <input
                type="date"
                className="form-control"
                value={memberSince}
                onChange={(e) => setmemberSince(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">ወርሀዊ ቁጠባ</label>
              <input
                type="text"
                className="form-control"
                value={monthlySaving}
                onChange={(e) => setmonthlySaving(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">የብድር አይነት</label>
              <select
                className="form-control"
                value={loanType}
                onChange={(e) => setloanType(e.target.value)}
              >
                <option value="0"> የብድር አይነት ይምረጡ </option>
                {lntypes.map((ln) => {
                  return (
                    <option key={ln.id} value={ln.id}>
                      {ln.amName}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* new comment end  */}

            <div className="col-sm-4">
              <label className="form-label">አድራሻ</label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                value={amAddress}
                onChange={(e) => setamAddress(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label> የተበዳሪ ክ/ከተማ </label>
              <select
                as="select"
                value={amSubcity}
                onChange={(e) => setamSubcity(e.target.value)}
                className="form-control"
              >
                <option value="0"> የተበዳሪ ክ/ከተማ ይምረጡ </option>
                {subcities.map((subc) => {
                  return (
                    <option key={subc.id} value={subc.id}>
                      {subc.amName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-sm-4">
              <label className="form-label">ወረዳ</label>
              <input
                type="text"
                className="form-control"
                value={woreda}
                onChange={(e) => setworeda(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">የቤት ቁጥር</label>
              <input
                type="text"
                className="form-control"
                value={houseNum}
                onChange={(e) => sethouseNum(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">አጠቃላይ ወርሀዊ ገቢ</label>
              <input
                type="text"
                className="form-control"
                value={monthlyIncome}
                onChange={(e) => setmonthlyIncome(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">character evaluation</label>
              <input
                type="text"
                className="form-control"
                value={characterEvaluation}
                onChange={(e) => setCharacterEvaluation(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label>.</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isMarried}
                  id="flexCheckDefault"
                  onChange={(e) => {
                    setisMarried(e.target.checked);
                  }}
                />
                <label className="form-check-label">Is Married</label>
              </div>
            </div>
            <div className="col-sm-4">
              {data.groups[0] === "ROLE_OFFICER" &&
                targetLoan.officerchecking && (
                  <Button
                    style={{ marginTop: 10 }}
                    className="btn btn-warning"
                    onClick={updateCustomerRecord}
                  >
                    አስተካክል
                  </Button>
                )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CustmerComp;
