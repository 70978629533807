import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaTelegram } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setTargetWithdraw } from "../../Common/redux/systemLookups";
import { numberToWords } from "../../Common/modules/EnNumToWord";

function WithdrawOrDisbursePlan({ amountTotal, customerId, arrIndex }) {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetWithdraw } = useSelector((store) => store.systemLookups);

  const { data } = useSelector((store) => store.customer);

  const [unplanned, setUnplanned] = useState(0);
  const [amount, setAmount] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [totalOnWithdraw, setTotalOnWithdraw] = useState(0);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalToEvaluator, setModalToEvaluator] = useState(false);
  const [targetObj, setTargetObj] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    const sum = targetWithdraw[arrIndex]?.withdrawaldetail?.reduce(
      (sum, item) => sum + item.amount,
      0
    );
    setTotalOnWithdraw(sum);
    setUnplanned(amountTotal - sum);
  }, [targetWithdraw[arrIndex]?.withdrawaldetail?.length]);

  const saveWithdraw = () => {
    if (amount <= 0) {
      setError(`Amount cannot be ZERO or Less`);
    } else {
      axios
        .post(`${serverIP}/withdrawaldetails/withdrawaldetail`, {
          parent: targetWithdraw[arrIndex]?.id,
          amount: amount,
          plannedby: data.id,
          appointmentDate,
        })
        .then((res) => {
          console.log(res.data);
          setModalAdd(false);
          getCustomerWithdrawalPlans(customerId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editWithdraw = () => {
    if (amount <= 0) {
      setError(`Amount cannot be ZERO or Less`);
    } else {
      axios
        .patch(
          `${serverIP}/withdrawaldetails/withdrawaldetail/${targetObj.id}`,
          {
            amount: amount,
            plannedby: data.id,
            appointmentDate,
          }
        )
        .then((res) => {
          console.log(res.data);
          setModalEdit(false);
          getCustomerWithdrawalPlans(customerId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteWithdraw = () => {
    axios
      .delete(`${serverIP}/withdrawaldetails/withdrawaldetail/${targetObj.id}`)
      .then((res) => {
        console.log(res.data);
        setModalDelete(false);
        getCustomerWithdrawalPlans(customerId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendToEvaluator = () => {
    console.log(targetObj);

    axios
      .patch(`${serverIP}/withdrawaldetails/withdrawaldetail/${targetObj.id}`, {
        toEvaluator: true,
      })
      .then((res) => {
        console.log(res.data);
        setModalToEvaluator(false);
        getCustomerWithdrawalPlans(customerId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "" || Number(inputValue) <= unplanned) {
      setAmount(inputValue);
      setError("");
    } else {
      setError(`Value cannot exceed ${unplanned}`);
    }
  };

  const getCustomerWithdrawalPlans = async (customerId) => {
    try {
      const res = await axios.get(
        `${serverIP}/withdrawals/withcustomerId/${customerId}`
      );
      dispatch(setTargetWithdraw({ withdraw: res.data }));
    } catch (err) {
      console.error("Error fetching loan:", err);
    }
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  return (
    <div>
      {/* {targetWithdraw[arrIndex]?.id} */}
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="p-1">መጠን</label>
          <Form.Control
            placeholder="መጠን"
            type="number"
            required
            aria-describedby="basic-addon1"
            onChange={handleChange}
          />

          {error && <div style={{ color: "red" }}>{error}</div>}

          <label className="p-1"> የቀጠሮ ቀን </label>
          <Form.Control
            placeholder="የቀጠሮ ቀን"
            type="date"
            required
            aria-describedby="basic-addon1"
            onChange={(e) => setAppointmentDate(e.target.value)}
          />

          {/* <input type="date" id="start" name="trip-start" value="2018-07-22" min="2018-01-01" max="2018-12-31" /> */}
        </Modal.Body>
        <Modal.Footer>
          {!error && (
            <Button variant="primary" onClick={() => saveWithdraw()}>
              {t("officer.save")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}>
            {t("officer.edit")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">መጠን </InputGroup.Text>
            <Form.Control
              placeholder="መጠን"
              aria-describedby="basic-addon1"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </InputGroup>
          <label className="p-1"> የቀጠሮ ቀን </label>
          <Form.Control
            placeholder="የቀጠሮ ቀን"
            type="date"
            required
            value={appointmentDate}
            aria-describedby="basic-addon1"
            onChange={(e) => setAppointmentDate(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editWithdraw()}>
            {t("officer.edit")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> {t("officer.delete")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("admin.confirmDelete")} <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteWithdraw()}>
            {t("officer.delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modal Send To Evaluator Start  */}
      <Modal
        show={modalToEvaluator}
        onHide={() => setModalToEvaluator(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title color="red"> ወደ ገምጋሚ ላክ </Modal.Title>
        </Modal.Header>
        <Modal.Body>የተቀመጠውን የወጪ ዝርዝር ወደ ሚገመግም አካል ይላኩ፡፡</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => sendToEvaluator()}
            className="flex gap-2 items-center"
          >
            ላክ <FaTelegram />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Send To Evaluator End  */}
      {/* Modals End  */}

      {amountTotal == 0 ? (
       <div class="w-11/12 pt-60 flex justify-center items-center">
          <div className="text-3xl">ZERO</div>
        </div>
      ) : (
        <>
          <div className="pt-2">
            <ProgressBar
              animated
              variant="danger"
              label={
                ((totalOnWithdraw / amountTotal) * 100)?.toLocaleString() + " %"
              }
              now={(totalOnWithdraw / amountTotal) * 100}
            />
          </div>

          <div className="flex justify-between border-gray-500 border-2 py-1 px-2 rounded-md mt-2 items-center">
            {`ከወጪ ቀሪ፡ ${(amountTotal - totalOnWithdraw).toLocaleString()}`}
            <div>
              {unplanned < 0.1 ? (
                <></>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={() => setModalAdd(true)}
                >
                  ጨምር
                </button>
              )}
            </div>
          </div>
        </>
      )}

      <div className="pt-3">
        {targetWithdraw[arrIndex]?.withdrawaldetail?.length > 0 ? (
          <div className="row">
            {targetWithdraw[arrIndex]?.withdrawaldetail.map((plann) => (
              <div className="col-sm-4">
                <Card border="dark" className="mt-2">
                  <Card.Header>{plann.amount?.toLocaleString()}</Card.Header>
                  <ListGroup as="ul" style={{ padding: 0 }}>
                    <ListGroup.Item as="li">
                      {`${(
                        (plann.amount / amountTotal) *
                        100
                      ).toLocaleString()} %`}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      {numberToWords(plann.amount)}
                    </ListGroup.Item>
                    <ListGroup.Item className="flex justify-between">
                      <div className="text-green-700 font-bold">
                        {plann.plannedDate}
                      </div>
                      <div className="text-red-700 font-bold">
                        {plann.appointmentDate}
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      {getDateDifferenceInDays(plann.appointmentDate) +
                        " ቀናቶች ይቀራሉ"}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      {"ጠያቂ: " + plann.plannedby?.first_name + " " + plann.plannedby?.last_name}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      {"የገመገመው: " + plann.plannedby?.first_name}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      {"የፈቀደው: " + plann.plannedby?.first_name}
                    </ListGroup.Item>
                  </ListGroup>
                  {!targetWithdraw[arrIndex].financeCheking && (
                    <>
                      <Card.Footer className="flex justify-between">
                        <Button
                          variant="warning"
                          className="btn btn-sm"
                          onClick={() => {
                            setModalEdit(true);
                            setTargetObj(plann);
                          }}
                        >
                          አስተካክል
                        </Button>
                        <Button
                          variant="danger"
                          className="btn btn-sm"
                          onClick={() => {
                            setTargetObj(plann);
                            setModalDelete(true);
                          }}
                        >
                          ሰርዝ
                        </Button>
                      </Card.Footer>
                      <Card.Footer>
                        {plann.toEvaluator ? (
                          <div> ወደ ገምጋሚ ተልኳል </div>
                        ) : (
                          <button
                            className="btn btn-primary flex gap-2 items-center"
                            onClick={() => {
                              setTargetObj(plann);
                              setModalToEvaluator(true);
                            }}
                          >
                            ወደ ገምጋሚ <FaTelegram />
                          </button>
                        )}
                      </Card.Footer>
                    </>
                  )}
                </Card>
              </div>
            ))}
          </div>
        ) : (
         <>
         {amountTotal !== 0 &&  <Alert variant="danger">የተያዘ ፕላን የለም</Alert>}
         </>
        )}
      </div>
    </div>
  );
}

export default WithdrawOrDisbursePlan;
