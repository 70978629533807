import * as Yup from "yup";

const CarCollateralSchema = Yup.object().shape({
  manufacturedYear: Yup.number().required("የተመረተበት ዓ.ም ያስገቡ"),
  chassisNumber: Yup.string().required("ቻሲስ ቁጥር ያስገቡ"),
  engineNumber: Yup.string().required("የኢንጅን ቁጥር ያስገቡ"),
  librebookid: Yup.string().required("የሊብሬ ቁጥር ያስገቡ"),
  carPlate: Yup.string().required("የመኪና ታርጋ ያስገቡ"),
  carCC: Yup.string().required("የመኪና ሲሲ ያስገቡ"),
  loan: Yup.string().required("loan is required"),
  manufacturedCountry: Yup.number().required("የተመረተበት አገር ይምረጡ"),
  model: Yup.number().required("የመኪና ሞዴል ያስገቡ"),
  insuranceValue: Yup.string().required("የኢንሹራንስ ግምት ያስገቡ"),
  subcity: Yup.number().required("ክፍለ ከተማ ያስገቡ"),
  sunctionedAt: Yup.date().required("እግድ የተሰጠበት ቀን ያስገቡ"),
  serialnum: Yup.string().required("ሴሪ ቁጥር ያስገቡ "),
});

export default CarCollateralSchema;
