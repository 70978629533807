import * as Yup from "yup";

const WitnessSchema = Yup.object().shape({
  amFullName: Yup.string().min(6, "ሙሉ ስም ከ6 ፊደል በታች መሆን የለበትም").required("ሙሉ ስም ያስፈልጋል"),
  fullName: Yup.string().min(3, "Fullname Must be minimum of 3 characters").required("Fullname is mandatory"),
  gender: Yup.string().required("እባክዎ ፆታ ይምረጡ"),
  amSubcity: Yup.string().min(2, "ክፍለ ከተማ ከ2 ፊደል በታች መሆን የለበትም ").required("ክፍለ ከተማ ያስፈልጋል"),
  subcity: Yup.string().min(3, "Subcity Must be minimum of 3 characters").required("Subcity is mandatory"),
  woreda: Yup.string().required("Woreda is mandatory"),
  houseNum: Yup.string().required("የቤት ቁጥር ያስፈልጋል"),
  mobileNo: Yup.string().min(9, 'Phone number must be exactly 9 characters').max(9, 'exceeds 9 characters').required('Phone number is required'),
});

export default WitnessSchema