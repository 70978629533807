import React, { useEffect, useState } from "react";
import {
  Document,
  Font,
  Page,
  Text,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import AgreementStyles from "../Styles/ReportStyle";
import ReportStyle from "../Styles/ReportStyle";
import HeaderHistory from "../Reusables/HeaderHistory";
import { useSelector } from "react-redux";
import axios from "axios";
import Tr from "../Reusables/Tr";
import Trc from "../Reusables/Trc";

function RptHistoryLoan() {
  const { serverIP } = useSelector((store) => store.allsettings);

  const [loans, setLoans] = useState([]);
  const [loansum, setLoansum] = useState(0);
  const curdate = new Date();

  useEffect(() => {
    getLoan();
  }, []);

  const getLoan = () => {
    axios
      .get(`${serverIP}/loan/finishedloans`)
      .then((res) => {
        console.log(res.data);
        setLoans(res.data);
        const sum_lns = res.data?.reduce((accum, obj) => {
            return accum + obj.approvedPrincipal;
          }, 0);
    
          setLoansum(sum_lns);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PDFViewer style={{ width: "100%", height: 1200 }}>
      <Document title="ያለቁ ብድሮች ሪፖርት">
        <Page style={AgreementStyles.body} wrap>
          <HeaderHistory type="ያለቁ ብድሮች ሪፖርት" />
          <Trc borders="t0r0b0l0" thekey="ጠቅላላ ያለቁ ብድሮች ሪፖርት" />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "gray",
              marginTop: 5,
            }}
          >
            <Tr
              style={ReportStyle.h5b}
              thekey="ሙሉ ስም"
              width="22%"
              borders="t1r1b1l1"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey="የብድር መጠን"
              width="19%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey="የተጠየቀበት ቀን  - የተሰጠበት ቀን"
              width="25%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey="የፈጀው ቀናት"
              width="12%"
              borders="t1r1b1l0"
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=" ስልክ "
              width="22%"
              borders="t1r1b1l0"
            />
          </View>
          {loans.length > 0 &&
            loans.map((loan) => {

              return (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.customer.amDisplayName}
                    width="22%"
                    borders="t0r1b1l1"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.approvedPrincipal?.toLocaleString()}
                    width="19%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.submittedOnDate + " - " + loan.auditorat}
                    width="25%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={0 + 1}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.customer.mobileNo}
                    width="22%"
                    borders="t0r1b1l0"
                  />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>ጠቅላላ የብድር መጠን: {loansum?.toLocaleString()} </Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {loans.length} </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptHistoryLoan;
