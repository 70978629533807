import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Table, Modal } from "react-bootstrap";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { Formik, Form } from "formik";

// import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextFormikInput from "../../ReusableFormiks/TextFormikInput";
import HolidaySchema from "../../Schema/Lookups/HolidaySchema";

// holidayName: Yup.string().required("የክ/ከተማ ስም ያስገቡ"),
// holyDate: Yup.date().required("ቀን ይምረጡ"),
// description: Yup.string().required("Fill subcity name"),

function Holidays() {
  const [t] = useTranslation("global");
  const { serverIP } = useSelector((store) => store.allsettings);
  const [holidayName, setholidayName] = useState([]);
  const [holyDate, setHolyDate] = useState("");
  const [description, setDescription] = useState("");

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [targetObj, setTargetObj] = useState({});

  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    getHolidays();
  }, []);

  const getHolidays = () => {
    axios
      .get(`${serverIP}/holidays/holidays`)
      .then(function (response) {
        setHolidays(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveholidayName = (values) => {
    console.log(values);
    axios
      .post(`${serverIP}/holidays/holidays`, {
        holidayName: values.holidayName,
        holyDate: values.holyDate,
        description: values.description,
      })
      .then((res) => {
        console.log(res.data);
        setModalAdd(false);
        getHolidays();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editholidayName = (values) => {
    console.log("editholidayName");
    console.log(values);
    axios
      .patch(`${serverIP}/holidays/holidays/${targetObj.id}`, {
        holidayName: values.holidayName,
        holyDate: values.holyDate,
        description: values.description,
      })
      .then((res) => {
        console.log(res.data);
        getHolidays();
        setModalEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteHoliday = () => {
    console.log(targetObj);
  };

  return (
    <div>
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div> በአላት ጨምር </div>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            holidayName: "",
            holyDate: "",
            description: "",
          }}
          validationSchema={HolidaySchema}
          onSubmit={saveholidayName}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <TextFormikInput name="holidayName" placeholder="የበአል ስም" />
                  <TextFormikInput
                    name="holyDate"
                    type="date"
                    placeholder="ቀን"
                  />
                  <TextFormikInput name="description" placeholder="ገለጻ" />
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}> አስተካክል </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            holidayName: "",
            holyDate: "",
            description: "",
          }}
          validationSchema={HolidaySchema}
          onSubmit={editholidayName}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <TextFormikInput
                    name="holidayName"
                    defaultValue={holidayName}
                    placeholder="የበአል ስም"
                  />
                  <TextFormikInput
                    name="holyDate"
                    defaultValue={holyDate}
                    type="date"
                    placeholder="ቀን"
                  />
                  <TextFormikInput
                    name="description"
                    defaultValue={description}
                    placeholder="ገለጻ"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}> አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div> Name : {targetObj.holidayName} </div>
            <div> Date : {targetObj.holyDate} </div>
            <div> Name : {targetObj.description} </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteHoliday}>Delete</Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modals End  */}

      <Alert variant="info">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
          }}
        >
          <div>ክፍለ ከተማ ዝርዝር</div>
          <div>
            <Button
              variant="primary"
              className="btn-sm"
              onClick={() => setModalAdd(true)}
            >
              {t("officer.add")}
            </Button>
          </div>
        </div>
      </Alert>
      {holidays.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ቀን </th>
              <th> ስም </th>
              <th> ገለጻ </th>
              <th>{t("auditor.action")}</th>
            </tr>
          </thead>
          <tbody>
            {holidays.map((holy) => {
              return (
                <tr>
                  <td>{holy.id}</td>
                  <td>{holy.holyDate}</td>
                  <td>{holy.holidayName}</td>
                  <td>{holy.description}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <FaTrashAlt
                        color="red"
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(holy);
                        }}
                      />
                      &nbsp;
                      <FaPencilAlt
                        color="orange"
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(holy);
                          setDescription(holy.description);
                          setHolyDate(holy.holyDate);
                          setholidayName(holy.holidayName);
                        }}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}
export default Holidays;
